import {deDE, enUS, esES, frFR, itIT, nlNL, plPL, ptPT, hrHR} from "@mui/material/locale";

export const SUPPORTED_LOCALES = [
    { locale: "de-DE", language: "German",localizedLanguage: "Deutsch", muiLocale: deDE },
    { locale: "en-GB", language: "English",localizedLanguage: "English", muiLocale: enUS },
    { locale: "es-ES", language: "Spanish", localizedLanguage: "Español", muiLocale: esES  },
    { locale: "fr-FR", language: "French", localizedLanguage: "Français", muiLocale: frFR  },
    // { locale: "hr-HR", language: "Croatian", localizedLanguage: "Hrvatski",muiLocale: hrHR  },
    { locale: "hr-HR", language: "Bosnian", localizedLanguage: "Bosanski",muiLocale: hrHR  }, // only the hr-HR croatian locale works for bosnian
    { locale: "it-IT", language: "Italian", localizedLanguage: "Italiano",muiLocale: itIT  },
    { locale: "nl-NL", language: "Dutch", localizedLanguage: "Nederlands",muiLocale: nlNL  },
    { locale: "pl-PL", language: "Polish", localizedLanguage: "Polski",muiLocale: plPL  },
    { locale: "pt-PT", language: "Portuguese", localizedLanguage: "Português",muiLocale: ptPT  },
];

export const getSupportedLocale = (locale) => {
    return SUPPORTED_LOCALES.find((supportedLocale) => supportedLocale.locale === locale) ||
        SUPPORTED_LOCALES[1]; // default to English UK
}