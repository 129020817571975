import React, {useEffect, useState} from 'react';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Divider,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {StorageService} from "../../services/StorageService";
import {AuthService} from "../../services/AuthService";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import Avatar from "@mui/material/Avatar";
import {getAbbreviation} from "../../utils/abbreviateUserName";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import List from "@mui/material/List";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/DoneAll';
import {Skeleton} from "@mui/lab";
import {TeamService} from "../../services/TeamService";
import Swal from "sweetalert2";

const Accounts = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const user = StorageService.loadUser();
    const [userPlan, setUserPlan] = useState(StorageService.loadUserPlan());
    const [userPlanFeatures, setUserPlanFeatures] = useState(StorageService.loadUserPlanFeatures());

    const [accounts, setAccounts] = useState([]);
    const [invitations, setInvitations] = useState([]);

    const [userOwnedAccounts, setUserOwnedAccounts] = useState([]);
    const [userCollaborationAccounts, setUserCollaborationAccounts] = useState([]);

    const INVITE_ACCEPT = 1;
    const INVITE_DECLINE = 4;

    const invitationActionsMenu = [
        {
            icon: <DoneIcon size={20}/>,
            title: t('app.acceptInvite'),
            value: INVITE_ACCEPT
        },
        {
            icon: <CloseIcon size={20}/>,
            title: t('app.declineInvite'),
            value: INVITE_DECLINE
        }
    ];

    useEffect( () => {
        getAccounts();
    },[])


    const getAccounts = async () => {

        setIsLoading(true);
        const response =  await AuthService.fetchAccounts(user.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setAccounts(data.accounts);
                filterAccounts(data.accounts);
                setInvitations(data.accountInvitations);
                setUserPlan(data.userPlan);
                setUserPlanFeatures(data.userPlanFeatures);

                StorageService.saveAccounts(data.accounts);
                StorageService.saveAccountInvitations(data.accountInvitations);
                StorageService.saveHasSubscription(data.hasSubscription);
                StorageService.saveUserPlan(data.userPlan);
                StorageService.saveUserPlanFeatures(data.userPlanFeatures);
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const filterAccounts = (accounts) => {
        const userOwnedAccounts = accounts.filter(account => account.isAccountOwner);
        const userCollaborationAccounts = accounts.filter(account => !account.isAccountOwner);

        setUserOwnedAccounts(userOwnedAccounts);
        setUserCollaborationAccounts(userCollaborationAccounts);


    }

    const decideOnAllInvites = async (decision) => {
        setIsLoading(true);
        const response =  await TeamService.decisionOnAllInvites(decision, user.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                await getAccounts();
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const decideOnInvite = async (inviteUid, decision) => {
        setIsLoading(true);
        const response =  await TeamService.decisionOnInvite(inviteUid, decision, user.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                await getAccounts();
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }


    const promptToDecideOnInvite = async (inviteUid, decision) => {

        let invitePromptTextLabel = 'app.acceptInvitePromptText';
        let invitePromptConfirmButtonLabel = 'app.acceptInvite';

        if (decision === INVITE_DECLINE) {
            invitePromptTextLabel = 'app.declineInvitePromptText';
            invitePromptConfirmButtonLabel = 'app.declineInvite';
        }

        Swal.fire({
            text: t(invitePromptTextLabel),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t(invitePromptConfirmButtonLabel),
            cancelButtonText: t('app.cancelLabel'),
            reverseButtons: true,
            confirmButtonColor: 'error',
        }).then(async (result) => {
            if (result.value) {
                await decideOnInvite(inviteUid, decision);
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                // Do Nothing
            }
        });
    };

    const promptToDecideOnAllInvites = async (decision) => {

        let invitePromptTextLabel = 'app.acceptAllInvitesPromptText';
        let invitePromptConfirmButtonLabel = 'app.acceptAllButtonLabel';

        if (decision === INVITE_DECLINE) {
            invitePromptTextLabel = 'app.declineAllInvitesPromptText';
            invitePromptConfirmButtonLabel = 'app.declineAllButtonLabel';
        }

        Swal.fire({
            text: t(invitePromptTextLabel),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t(invitePromptConfirmButtonLabel),
            cancelButtonText: t('app.cancelLabel'),
            reverseButtons: true,
            confirmButtonColor: 'error',
        }).then(async (result) => {
            if (result.value) {
                await decideOnAllInvites(decision);
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                // Do Nothing
            }
        });
    };

    const onInvitationAction = async (action, invitation) => {
        await promptToDecideOnInvite(invitation.uid, action.value);
    }

    const selectAccount = (account) => {

        StorageService.saveSelectedAccount(account);
        StorageService.saveIsAccountOwner(account.isAccountOwner);
        StorageService.saveProjects(account.projects);
        StorageService.saveSelectedBusiness(account.business);
        StorageService.saveSelectedProject(account.selectedProject);
        StorageService.saveTeam(account.team);
        StorageService.saveSubscription(account.subscription);
        StorageService.savePlan(account.plan);
        StorageService.savePlanFeatures(account.planFeatures);

        navigate('/');


    }

    const promptToUpgradePlanToAddMoreBrands = async () => {
        Swal.fire({
            text: t('app.upgradePlanToAddMoreBrands'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: t('app.upgradePlanLabel'),
            cancelButtonText: t('app.cancelLabel'),
            reverseButtons: true,
            confirmButtonColor: 'warning',
        }).then(async (result) => {
            if (result.value) {
                upgradePlan();
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                // Do Nothing
            }
        });
    };

    const upgradePlan = () => {
        navigate('/plans', { state: userPlan })
    }

    const createBusiness = () => {
        let needsToUpgradeToAddBrand = false;

        if (userPlanFeatures &&
            (userOwnedAccounts.length >= userPlanFeatures?.numberOfBrandProfiles)) {

            needsToUpgradeToAddBrand = true;
        }


        if (needsToUpgradeToAddBrand) {
            promptToUpgradePlanToAddMoreBrands();
        }
        else {
            navigate('/welcome', { state: {business: null, mode: 'add-account'} })
        }
    }



    return (
        <React.Fragment>
            <Box mt={5}>
                <Box>
                    <Typography variant={'h1'} >
                        {t("app.brandsTitleLabel")}
                        <Button
                            variant={"outlined"}
                            sx={{marginLeft: 1}}
                            onClick={createBusiness}
                            size={'small'}
                        >
                            {
                                userPlan && t('app.addNewBrandButtonLabel')
                            }
                            {
                                !userPlan && t('app.createYourBrandButtonLabel')
                            }
                        </Button>
                    </Typography>
                    <Typography variant={'h6'} color={'text.secondary'}>
                        {t("app.selectBrandDescriptionLabel")}
                    </Typography>

                    <Divider/>

                    <Box mt={5}>

                        {
                            isLoading && (
                                <>
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                </>
                            )
                        }


                        {!isLoading && accounts?.length < 1 && (

                            <Card sx={{marginRight: 5, marginBottom: 5}}>
                                <CardContent>
                                    <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                        <Typography variant={'h4'}>
                                            {t('app.noBrandsLabel')}
                                        </Typography>



                                    </Box>
                                    <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>

                                        <Typography variant={'h5'}>
                                            {t('app.addNewBrandToGetStartedLabel')}
                                        </Typography>


                                    </Box>


                                    <Box mt={3} sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                        <Button
                                            onClick={createBusiness}
                                            size={'small'}
                                            variant={"outlined"}>{t('app.createYourBrandButtonLabel')}</Button>

                                    </Box>
                                </CardContent>
                            </Card>

                        )}

                        {!isLoading && accounts?.length > 0 && (
                            <Grid container spacing={3.75} mb={3}>

                                {accounts.map( (account, index) =>
                                    (
                                        <>
                                            <Grid key={account.teamUid} item xs={12} sm={6} lg={3}>

                                                <JumboCardQuick
                                                    sx={{
                                                        '& .MuiCardHeader-action': {
                                                            my: '-4px'
                                                        },
                                                    }}
                                                >
                                                    <Div sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexDirection: 'column',
                                                        textAlign: 'center',
                                                        height: 300
                                                    }}
                                                    >
                                                        <Div sx={{display: 'flex', mb: 2, mt: 5}}>
                                                            <Avatar
                                                                sx={{width: 56, height: 56}}
                                                            >
                                                                {getAbbreviation(account.name)}
                                                            </Avatar>
                                                        </Div>
                                                            <Typography variant={"h3"} mb={2}>{account.name}</Typography>

                                                        <Button onClick={() => {selectAccount(account)}} variant={"contained"} sx={{mb: 5, mt: 8}}>{t('app.selectBrand')}</Button>
                                                    </Div>
                                                </JumboCardQuick>
                                            </Grid>
                                        </>
                                    )
                                )}
                            </Grid>
                        )}

                    </Box>
                </Box>

                {!isLoading && invitations?.length > 0 && (
                    <Box mt={5}>
                        <Typography variant={'h3'} >
                            {t("app.accountInvitationsTitleLabel")}
                            <Button
                                variant={"outlined"}
                                sx={{marginLeft: 1}}
                                onClick={() => promptToDecideOnAllInvites(INVITE_ACCEPT)}
                                size={'small'}
                            >
                                {t('app.acceptAllButtonLabel')}
                            </Button>
                            <Button
                                variant={"outlined"}
                                sx={{marginLeft: 1}}
                                onClick={() => promptToDecideOnAllInvites(INVITE_DECLINE)}
                                size={'small'}
                                color={'error'}
                            >
                                {t('app.declineAllButtonLabel')}
                            </Button>
                        </Typography>

                        <Divider/>

                        <Box mt={5}>
                            <Grid container spacing={3.75} mb={3}>
                                <Grid item xs={12} md={6}>
                                    <JumboCardQuick
                                        title={t('app.newInvitations')}
                                        wrapperSx={{p: 0}}
                                    >
                                        <JumboScrollbar autoHeight autoHeightMin={250} autoHide autoHideDuration={200} autoHideTimeout={500}>
                                            <List disablePadding>

                                                {invitations.map( (invitation, index) =>
                                                    (
                                                        <>
                                                            <Divider component="li"/>
                                                            <ListItem sx={{px: 3, py: 1.25}}>
                                                                <ListItemAvatar>
                                                                    <Avatar>
                                                                        <EmailIcon />
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText sx={{ flexBasis: '40%' }} primary={<Typography variant='h5' mb={.5}>{invitation.accountName}</Typography>}
                                                                              secondary={t('app.invitedBy') + " " + invitation.inviter}
                                                                />

                                                                <ListItemText  sx={{flexGrow: 0}} primary={<JumboDdMenu onClickCallback={(action) => {
                                                                    onInvitationAction(action, invitation)
                                                                }} menuItems={invitationActionsMenu}/>}/>
                                                            </ListItem>

                                                        </>
                                                    ))}


                                            </List>
                                        </JumboScrollbar>
                                    </JumboCardQuick>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                )}
            </Box>



        </React.Fragment>
    );
}

export default Accounts;