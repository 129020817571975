import {APIService} from './APIService';

export class ProjectService extends APIService {
  static async createProject(
        name,
        description,
        teamUid,
        businessUid,
        accessToken,
    ) {
        return await this.makeRequest(
            `api/projects`,
            {
                name,
                description,
                teamUid,
                businessUid,
            },
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            }
        );
  }


    static async getTeamProjects(
        teamUid, accessToken
    ) {
        return await this.makeRequest(
            `api/teams/${teamUid}/projects`,
            null,
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
            APIService.METHOD_GET
        );
    }
}
