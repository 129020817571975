import {APIService} from './APIService';

export class AuthService extends APIService {
  static async login(email, password) {
    return await this.makeRequest(
      'api/users/login',
      {
        email,
        password,
      },
      {
        'Content-Type': 'application/json',
      },
    );
  }

  static async signUp(name, email, password,locale, language, localizedLanguage) {
    return await this.makeRequest(
      'api/users/signup',
      {
        name,
        email,
        password,locale, language, localizedLanguage
      },
      {
        'Content-Type': 'application/json',
      },
    );
  }

  static async changePassword(
    currentPassword,
    newPassword,
    accessToken,
  ) {
    return await this.makeRequest(
      'api/users/password/change',
      {
        currentPassword,
        newPassword,
      },
      {
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
    );
  }

    static async fetchAccounts(
        accessToken
    ) {
        return await this.makeRequest(
            'api/users/accounts',
            {},
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
        );
    }

  static async forgotPassword(email) {
    return await this.makeRequest(
      'api/users/password/forgot',
      {
        email,
      },
      {
        'Content-Type': 'application/json',
      },
    );
  }

  static async resetPassword(resetUid, newPassword) {
    return await this.makeRequest(
      'api/users/password/reset',
      {
          resetUid,
          newPassword,
      },
      {
        'Content-Type': 'application/json',
      },
    );
  }

    static async activateAccount(
        verificationCode,
        accessToken,
    ) {
        return await this.makeRequest(
            'api/users/activate-account',
            {
                verificationCode
            },
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
        );
    }

    static async updateUserLanguage(locale, language, localizedLanguage, accessToken) {
        return await this.makeRequest(
            'api/users/change-language',
            {
               locale, language, localizedLanguage
            },
            {
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
        );
    }
}
