import {APIService} from './APIService';

export class SubscriptionService extends APIService {
  static async createSubscription(
      planUid,
      skipPaymentDetails,
      accessToken,
  ) {
    return await this.makeRequest(
      'api/subscriptions/create',
      {
          planUid,
          skipPaymentDetails
      },
      {
        'x-access-token': accessToken,
        'Content-Type': 'application/json',
      },
    );
  }

  static async getPlans(accessToken) {
    return await this.makeRequest(
        'api/subscriptions/plans',
        {},
        {
          'x-access-token': accessToken,
          'Content-Type': 'application/json',
        },
    );
  }

    static async getUserSubscription(accessToken) {
        return await this.makeRequest(
            'api/subscriptions/user/retrieve',
            {},
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
        );
    }

    static async updateSubscription(planUid, teamUid, accessToken) {
        return await this.makeRequest(
            'api/subscriptions/user/update',
            {planUid, teamUid},
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
        );
    }

    static async cancelSubscription(accessToken) {
        return await this.makeRequest(
            'api/subscriptions/user/cancel',
            {},
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
        );
    }

    static async createSetupIntent(accessToken) {
        return await this.makeRequest(
            'api/payments/create-intent',
            {},
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
        );
    }

    static async updateUserPaymentMethod(paymentMethodId, accessToken) {
        return await this.makeRequest(
            'api/payment-method/update',
            {
                paymentMethodId
            },
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
        );
    }
}
