import React, {useEffect, useState} from 'react';
import {Box, Button, Card, CardActions, CardContent, Divider, Grid, ListItem, Typography} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {StorageService} from "../../services/StorageService";
import {BrandVoiceService} from "../../services/BrandVoiceService";
import {WelcomeService} from "../../services/WelcomeService";



const BusinessSettings = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const user = StorageService.loadUser();
    const [business, setBusiness] = useState(StorageService.loadSelectedBusiness());
    // const [business, setBusiness] = useState(undefined);
    const contentLanguages = StorageService.loadSupportedContentLanguages();
    const isAccountOwner = StorageService.loadIsAccountOwner();


    useEffect(async () => {

        if (!isAccountOwner) {
            navigate('/', {replace: true});
            return;
        }
        await getBrand();
    }, [])

    const getContentLanguage = (locale) => {
        if (locale) {
            const lang = contentLanguages.find((contentLanguage) => contentLanguage.locale === locale);
            return lang?.language || '-'
        }
        return null;
    }

    const getBrand = async () => {

        const response =  await WelcomeService.getBrand(business?.uid, user.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setBusiness(data.business);
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    return (
        <React.Fragment>
            <Box>
                {business && (
                    <Box>
                        <Card>
                            <CardHeader title={business.name} />
                            <Divider/>
                            <CardContent>
                                <Stack direction={"row"} spacing={10}>
                                    <Grid container>
                                        <Grid item lg={6} sm={12} xs={12}>
                                            <Typography
                                                variant={"h6"}
                                                color={"text.secondary"}
                                                mb={1}
                                            >
                                                {t('app.brandDescriptionLabel')}
                                            </Typography>
                                            <Typography
                                                component={"div"}
                                                variant={"body1"}
                                                mb={1}
                                            >
                                                {business.description || '-'}
                                            </Typography>

                                            <Typography
                                                variant={"h6"}
                                                color={"text.secondary"}
                                                mb={1}
                                            >
                                                {t('app.businessServicesOfferedLabel')}
                                            </Typography>
                                            <Typography
                                                component={"div"}
                                                variant={"body1"}
                                                mb={1}
                                            >
                                                {business.servicesOffered || '-'}
                                            </Typography>

                                            <Typography
                                                variant={"h6"}
                                                color={"text.secondary"}
                                                mb={1}
                                            >
                                                {t('app.businessWebsiteLabel')}
                                            </Typography>
                                            <Typography
                                                component={"div"}
                                                variant={"body1"}
                                                mb={1}
                                            >
                                                {business.website || '-'}
                                            </Typography>

                                            <Typography
                                                variant={"h6"}
                                                color={"text.secondary"}
                                                mb={1}
                                            >
                                                {t('app.businessIndustryLabel')}
                                            </Typography>
                                            <Typography
                                                component={"div"}
                                                variant={"body1"}
                                                mb={1}
                                            >
                                                {business.industry || '-'}
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={6} sm={12} xs={6}>
                                            <Typography
                                                variant={"h6"}
                                                color={"text.secondary"}
                                                mb={1}
                                            >
                                                {t('app.businessTargetAudienceLabel')}
                                            </Typography>
                                            <Typography
                                                component={"div"}
                                                variant={"body1"}
                                                mb={1}
                                            >
                                                {business.targetAudience || '-'}
                                            </Typography>

                                            <Typography
                                                variant={"h6"}
                                                color={"text.secondary"}
                                                mb={1}
                                            >
                                                {t('app.businessToneOfVoiceLabel')}
                                            </Typography>
                                            <Typography
                                                component={"div"}
                                                variant={"body1"}
                                                mb={1}
                                            >
                                                {business.tone || '-'}
                                            </Typography>

                                            <Typography
                                                variant={"h6"}
                                                color={"text.secondary"}
                                                mb={1}
                                            >
                                                {t('app.businessWritingStyleLabel')}
                                            </Typography>
                                            <Typography
                                                component={"div"}
                                                variant={"body1"}
                                                mb={1}
                                            >
                                                {business.writingStyle || '-'}
                                            </Typography>

                                            <Typography
                                                variant={"h6"}
                                                color={"text.secondary"}
                                                mb={1}
                                            >
                                                {t('app.businessPreferredContentLanguageLabel')}
                                            </Typography>
                                            <Typography
                                                component={"div"}
                                                variant={"body1"}
                                                mb={1}
                                            >
                                                {getContentLanguage(business.preferredContentLanguage) || '-'}
                                            </Typography>

                                        </Grid>


                                    </Grid>
                                </Stack>
                            </CardContent>
                            <CardActions>

                                <Button onClick={() => {
                                    navigate('/welcome', { state: {business, mode: 'edit'} })
                                }} variant={"outlined"}>{t('app.editLabel')}</Button>

                            </CardActions>
                        </Card>

                    </Box>

                )}

                {!business && (
                    <Box>
                        <Card>
                            <CardContent>
                                <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                    <Typography variant={'h4'}>
                                        {t('app.noBusinessesCreatedLabel')}
                                    </Typography>
                                </Box>


                                <Box mt={3} sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                    <Button
                                        onClick={() => {
                                            navigate('/welcome', { state: {business: null, mode: 'create'} })
                                        }}
                                    variant={"outlined"}>{t('app.createButtonLabel')}</Button>

                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                )}

            </Box>

        </React.Fragment>
    );
};

export default BusinessSettings;