import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Divider,
    Drawer,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {StorageService} from "../../services/StorageService";
import {useNavigate} from "react-router-dom";
import {BrandVoiceService} from "../../services/BrandVoiceService";
import {Skeleton} from "@mui/lab";
import Chip from "@mui/material/Chip";
import Div from "@jumbo/shared/Div";
import CloseIcon from "@mui/icons-material/Close";


const BrandVoices = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const user = StorageService.loadUser();
    const team = StorageService.loadTeam();
    const isAccountOwner = StorageService.loadIsAccountOwner();

    const [brandVoices, setBrandVoices] = useState(null);
    const [selectedBrandVoice, setSelectedBrandVoice] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isBrandVoiceDrawerOpen, setIsBrandVoiceDrawerOpen] = useState(false);

    useEffect( async () => {

        if (!user) {
            navigate('/login', {replace: true});
        }
        if (user?.status === 'unverified') {
            navigate('/welcome', {replace: true});
        }

        await getTeamBrandVoices();

    }, []);

    const getTeamBrandVoices = async () => {

        setIsLoading(true);
        const response =  await BrandVoiceService.getTeamBrandVoices(team?.uid, user.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setBrandVoices(data.brandVoices);
                StorageService.saveBrandVoices(data.brandVoices);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const createNewBrandVoice = () => {
        navigate('/brand-voices/create');
    };

    const viewBrandVoice = (brandVoice) => {
        setSelectedBrandVoice(brandVoice);
        setIsBrandVoiceDrawerOpen(true);

    }

    const setDefaultBrandVoice = async (brandVoiceUid) => {
        setIsLoading(true);
        const response =  await BrandVoiceService.markBrandVoiceAsDefault(brandVoiceUid, team?.uid, user.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        // setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                await getTeamBrandVoices();
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    return (
        <React.Fragment>
            <Typography variant={'h1'} >
                {t("app.brandVoicesTitleLabel")}
                <Button
                    variant={"outlined"}
                    sx={{marginLeft: 1}}
                    onClick={createNewBrandVoice}
                    size={'small'}
                >
                    {t('app.createBrandVoiceButtonLabel')}
                </Button>
            </Typography>
            <Typography variant={'h6'} color={'text.secondary'}>
                {t("app.brandVoiceDescriptionLabel")}
            </Typography>

            <Divider/>

            <Box mt={5}>

                <Box>
                    {
                        isLoading && (
                            <>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </>
                        )
                    }

                    {!isLoading && brandVoices?.length < 1 && (

                        <Card>
                            <CardContent>
                                <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                    <Typography variant={'h4'}>
                                        {t('app.noBrandVoicesCreatedLabel')}
                                    </Typography>
                                </Box>


                                <Box mt={3} sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                    <Button
                                        onClick={createNewBrandVoice}
                                        size={'small'}
                                        variant={"outlined"}>{t('app.createBrandVoiceButtonLabel')}</Button>

                                </Box>
                            </CardContent>
                        </Card>
                    )}

                    {!isLoading && brandVoices?.length > 0 && (
                        <Grid container spacing={3.75} mb={3}>

                            {brandVoices.map( (brandVoice, index) =>
                                (
                                    <>
                                        <Grid key={brandVoice.uid} item xs={12} sm={6} lg={3}>
                                            <Card>
                                                <CardContent>
                                                    <Typography variant={'h4'}>
                                                        {brandVoice.name}
                                                        {brandVoice.isDefault === 1 && (
                                                            <Chip
                                                                sx={{marginLeft: 0.1}}
                                                                size={'small'}
                                                                label={t('app.defaultBrandVoiceLabel')}
                                                                color={'primary'}
                                                            />
                                                        )}

                                                    </Typography>
                                                    <Typography variant={'subtitle1'} color={'text.secondary'}>
                                                        {brandVoice.voiceSummary}
                                                    </Typography>
                                                    <Typography variant={'p'} color={'text.secondary'}>
                                                        {brandVoice.voicePersonality}
                                                    </Typography>
                                                    <br/><br/>
                                                    <Typography variant={'p'} color={'text.secondary'}>
                                                        {brandVoice.tone}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    <Button sx={{marginLeft: -2}} size={"small"} onClick={() => {viewBrandVoice(brandVoice)}}>{t('app.viewButtonLabel')}</Button>
                                                    {isAccountOwner && (
                                                        <>
                                                            {brandVoice.isDefault === 0 && (
                                                                <Button color={'success'} size={"small"} onClick={() => {setDefaultBrandVoice(brandVoice.uid)}}>{t('app.setAsDefaultLabel')}</Button>
                                                            )}
                                                        </>
                                                    )}
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    </>
                                )
                            )}
                        </Grid>
                    )}
                </Box>
            </Box>


            <Drawer
                anchor={'right'}
                open={isBrandVoiceDrawerOpen}
                onClose={() => setIsBrandVoiceDrawerOpen(false)}
                variant="persistent"
                sx={{
                    zIndex: 1205,

                    '& .MuiDrawer-paper': {
                        width: 400
                    }
                }}
            >
                <Div
                    sx={{
                        display: 'flex',
                        minWidth: 0,
                        position: 'relative',
                        zIndex: 2,
                        p: theme => theme.spacing(1.5, 3),
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 25,
                    }}
                >
                    <Typography variant={"h3"} mb={0}>{selectedBrandVoice?.name}</Typography>
                    <IconButton aria-label="close" onClick={() => setIsBrandVoiceDrawerOpen(false)}>
                        <CloseIcon/>
                    </IconButton>


                </Div>
                <Box sx={{p: theme => theme.spacing(1.5, 3),}}>

                    <Box mb={2}>
                        <Typography sx={{fontSize: 12}} variant={'body1'} color={'text.secondary'}>
                            {t('app.brandVoiceSummaryLabel')}
                        </Typography>
                        <Typography variant={'p'}>
                            {selectedBrandVoice?.voiceSummary}
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography sx={{fontSize: 12}} variant={'body1'} color={'text.secondary'}>
                            {t('app.brandVoicePersonalityLabel')}
                        </Typography>
                        <Typography variant={'p'}>
                            {selectedBrandVoice?.voicePersonality}
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography sx={{fontSize: 12}} variant={'body1'} color={'text.secondary'}>
                            {t('app.businessToneOfVoiceLabel')}
                        </Typography>
                        <Typography variant={'p'}>
                            {selectedBrandVoice?.tone}
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography sx={{fontSize: 12}} variant={'body1'} color={'text.secondary'}>
                            {t('app.businessWritingStyleLabel')}
                        </Typography>
                        <Typography variant={'p'}>
                            {selectedBrandVoice?.writingStyle}
                        </Typography>
                    </Box>

                </Box>
            </Drawer>

        </React.Fragment>
    );
};

export default BrandVoices;