import {APIService} from './APIService';

export class CampaignService extends APIService {
  static async getCampaignTemplates(
      accessToken,
  ) {
    return await this.makeRequest(
      `api/campaigns/templates`,
      null,
      {
        'x-access-token': accessToken,
        'Content-Type': 'application/json',
      },
        APIService.METHOD_GET
    );
  }

  static async createCampaign(
        campaignUid,
        title,
        tone,
        writingStyle,
        brandVoiceUid,
        campaignDetails,
        locale,
        campaignTemplateId,
        campaignContentFeatures,
        teamUid,
        businessUid,
        projectUid,
        accessToken,
    ) {
        return await this.makeRequest(
            `api/campaigns`,
            {
                campaignUid,
                title,
                tone,
                writingStyle,
                brandVoiceUid,
                campaignDetails,
                locale,
                campaignTemplateId,
                campaignContentFeatures,
                teamUid,
                businessUid,
                projectUid,
            },
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            }
        );
  }

    static async getCampaign(
        campaignUid, accessToken
    ) {
        return await this.makeRequest(
            `api/campaigns/${campaignUid}`,
            null,
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
            APIService.METHOD_GET
        );
    }

    static async getTeamCampaigns(
        teamUid, accessToken
    ) {
        return await this.makeRequest(
            `api/teams/${teamUid}/campaigns`,
            null,
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
            APIService.METHOD_GET
        );
    }

    static async deleteCampaign(
        campaignUid, accessToken
    ) {
        return await this.makeRequest(
            `api/campaigns/${campaignUid}`,
            null,
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
            APIService.METHOD_DELETE
        );
    }
}
