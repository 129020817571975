import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent, Dialog, DialogActions, DialogContent,
    Grid,
    InputLabel, Select, Snackbar,
    TextField,
    Typography
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import {StorageService} from "../../services/StorageService";
import {CreatorService} from "../../services/CreatorService";
import {Skeleton} from "@mui/lab";
import Chip from "@mui/material/Chip";
import DialogTitle from "@mui/material/DialogTitle";
import {TranslationService} from "../../services/TranslationService";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TranslateIcon from '@mui/icons-material/Translate';
import SaveIcon from '@mui/icons-material/Save';
import Alert from '@mui/material/Alert';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const EditContent = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const content = location.state;
    const Swal = useSwalWrapper();
    const navigate = useNavigate();

    const user = StorageService.loadUser();
    const selectedBusiness = StorageService.loadSelectedBusiness();
    const team = StorageService.loadTeam();
    const contentLanguages =  StorageService.loadSupportedContentLanguages();

    const [selectedSavedContent, setSelectedSavedContent] = useState(content);
    // const preferredContentLanguageLocale =  selectedBusiness ? selectedBusiness.preferredContentLanguage : user.locale;
    const preferredContentLanguageLocale =  content.locale;

    const [selectedLanguage, setSelectedLanguage] = useState(preferredContentLanguageLocale);

    const [projects, setProjects] = useState(StorageService.loadProjects());
    const [selectedProject, setSelectedProject] = useState(StorageService.loadSelectedProject());



    const [generatedCopy, setGeneratedCopy] = useState();
    const [translatedResult, setTranslatedResult] = useState('');
    const [generatedTranslatedCopy, setGeneratedTranslatedCopy] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSavingTranslation, setIsSavingTranslation] = useState(false);

    const [isTranslationLoading, setIsTranslationLoading] = useState(false);

    const [isTranslationDialogOpen, setIsTranslationDialogOpen] =  useState(false);


    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [shouldShowSaveTranslationButton, setShouldShowSaveTranslationButton] = useState(false);

    const translationTypes = [
        {
            type: 'direct',
            label: 'app.directTranslationLabel',
            value: 'direct',
            description: 'app.directTranslationDescription'
        },
        {
            type: 'contextual',
            label: 'app.contextualTranslationLabel',
            value: 'contextual',
            description: 'app.contextualTranslationDescription'
        },
    ];

    let contentTranslationType = translationTypes[0];
    if (content?.translationType && content?.translationType === 2 ) {
        contentTranslationType = translationTypes[1];
    }

    const [selectedTranslationType, setSelectedTranslationType] =
        useState(contentTranslationType);

    const handleSelectTranslationType = (event) => {
         setSelectedTranslationType(translationTypes.find(type => type.value === event.target.value));
    }



    const getTranslationLanguageOptions = () => {
        const translationLanguages = contentLanguages;
        const currentLocale = generatedCopy?.locale || selectedLanguage;

        return translationLanguages.filter(language => language.locale !== currentLocale);
    }


    const contentTranslatedLocales = content?.translatedLocales ?
        content?.translatedLocales.split(',') : [];

    const [selectedTranslationLanguages, setSelectedTranslationLanguages] =
        useState(contentTranslatedLocales);

    const [translationLanguages, setTranslationLanguages] =
        useState(getTranslationLanguageOptions());

    const openTranslationDialog = () => {
        setIsTranslationDialogOpen(true);
    }

    const handleTranslationDrawerClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setIsTranslationDialogOpen(false);
        }
    }


    const getLanguageFromLocale = (locale) => {
        return (contentLanguages.find(langauge => langauge.locale === locale));
    }

    const handleTranslationLanguageChange = (event) => {


        const {
            target: { value },
        } = event;

        if (value.length > 2){
            alert('Maximum of 2 languages allowed');
            return;
        }

        setSelectedTranslationLanguages(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    }


    const copyContent = async () => {
        if(content) {
            if (content.translatedResult) {
                await navigator.clipboard.writeText(content?.result + '\n\n' +
                    content?.translatedResult);

            }
            else {
                await navigator.clipboard.writeText(content?.result);

            }
            showSnackBarAlert('app.copiedLabel', 'success');
        }
    };

    const showSnackBarAlert = (messageKey, alertSeverity) => {
        setSnackBarMessage(t(messageKey));
        setAlertSeverity(alertSeverity);
        setIsSnackBarOpen(true);
    }


    const saveCopy = async () => {
        setIsSaving(true);

        const response = await CreatorService.editCopyContent(selectedSavedContent.uid, selectedSavedContent.result, user.token);

        // @ts-ignore
        const resJson = await response.json();
        // alert(JSON.stringify(resJson));


        const {data} = resJson;
        setIsSaving(false);
        // @ts-ignore
        if (response.ok) {
            if (data.status === 'success') {
                // alert('Content Saved!');
                showSnackBarAlert('app.contentSavedLabel', 'success');
                if(selectedSavedContent?.translatedResult) {
                    promptToUpdateTranslations();
                }

            } else {
                alert(data.message);
            }
        } else {
            alert('An error occurred, Please try again');
        }
    }

    const promptToUpdateTranslations = async () => {
        Swal.fire({
            title: t('app.updateTranslationsPromptTitle'),
            text: t('app.updateTranslationsPromptMessage'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t('app.updateLabel'),
            cancelButtonText: t('app.cancelLabel'),
            reverseButtons: true,
        }).then(async (result) => {
            if (result.value) {
                await translateContent();
                setTimeout(async() => {
                    await saveTranslation()
                }, 1000)
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                // Do Nothing
            }
        });
    };

    const saveTranslation = async () => {
        setIsSavingTranslation(true);

        const response = await CreatorService.updateCopyTranslations(selectedSavedContent.uid,
            selectedSavedContent.translatedResult, generatedTranslatedCopy?.translationType,
            generatedTranslatedCopy?.translatedLocales, user.token);

        // @ts-ignore
        const resJson = await response.json();
        // alert(JSON.stringify(resJson));


        const {data} = resJson;
        setIsSavingTranslation(false);
        // @ts-ignore
        if (response.ok) {
            if (data.status === 'success') {
                // alert('Content Saved!');
                showSnackBarAlert('app.translationSavedLabel', 'success');

            } else {
                alert(data.message);
            }
        } else {
            alert('An error occurred, Please try again');
        }
    }

    const translateContent = async () => {
        setIsTranslationDialogOpen(false);
        setIsTranslationLoading(true);

        if (selectedSavedContent && selectedSavedContent.result) {
            const response = await TranslationService.translateContent(
                selectedSavedContent.result,
                selectedTranslationLanguages,
                selectedTranslationType.value,
                user.token
            );

            // @ts-ignore
            const resJson = await response.json();
            // alert(JSON.stringify(resJson));


            const {data, message} = resJson;
            setIsTranslationLoading(false);
            // @ts-ignore
            if (response.ok) {
                if (data.translation) {
                    setGeneratedTranslatedCopy(data.translation);
                    setTranslatedResult(data.translation.translatedResult);
                    setSelectedSavedContent({
                        ...selectedSavedContent,
                        translatedResult:data.translation.translatedResult,
                    })
                    setShouldShowSaveTranslationButton(true);


            } else {
                    alert(data.message);
                }
            } else {
                alert(message || 'An error occurred, Please try again');
            }
        }
        else {
            alert ('A copy needs to be generated first');
        }

    }


    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsSnackBarOpen(false);
    }


    return (
        <React.Fragment>
            <Typography variant={'h2'}>
                <Button
                    onClick={() => navigate(-1)}
                    size={"small"}
                    startIcon={<ArrowBackIcon />}
                >
                    {t("app.backButtonLabel")}
                </Button>
                {t('app.editContentLabel')}
            </Typography>
            <Grid container spacing={3.75} mt={-3} mb={3}>
                <Grid item xs={12} sm={6} lg={4}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Box>
                                <Box mb={2}>
                                    <Typography
                                        fontSize={"12px"}
                                        variant={"h6"}
                                        color={"text.secondary"}
                                        mb={.25}
                                    >
                                        {t('app.descriptionLabel')}
                                    </Typography>
                                    <Typography variant={"body1"}>{content.text}</Typography>
                                </Box>

                                {content?.brandVoice && (
                                    <>

                                        <Box mb={2}>
                                            <Typography
                                                fontSize={"12px"}
                                                variant={"h6"}
                                                color={"text.secondary"}
                                                mb={.25}
                                            >
                                                {t('app.brandVoiceLabel')}
                                            </Typography>
                                            <Typography variant={"body1"}>{content?.brandVoice?.name}</Typography>
                                        </Box>

                                        <Box mb={2}>
                                            <Typography
                                                fontSize={"12px"}
                                                variant={"h6"}
                                                color={"text.secondary"}
                                                mb={.25}
                                            >
                                                {t('app.brandVoicePersonalityLabel')}
                                            </Typography>
                                            <Typography variant={"body1"}>{content?.brandVoice?.voicePersonality}</Typography>
                                        </Box>
                                    </>
                                )}

                                {!content?.brandVoice && (
                                    <>

                                        <Box mb={2}>
                                            <Typography
                                                fontSize={"12px"}
                                                variant={"h6"}
                                                color={"text.secondary"}
                                                mb={.25}
                                            >
                                                {t('app.businessToneOfVoiceLabel')}
                                            </Typography>
                                            <Typography variant={"body1"}>{content.tone}</Typography>
                                        </Box>

                                        <Box mb={2}>
                                            <Typography
                                                fontSize={"12px"}
                                                variant={"h6"}
                                                color={"text.secondary"}
                                                mb={.25}
                                            >
                                                {t('app.businessWritingStyleLabel')}
                                            </Typography>
                                            <Typography variant={"body1"}>{content.writingStyle}</Typography>
                                        </Box>
                                    </>
                                )}

                                <Box mb={2}>
                                    <Typography
                                        fontSize={"12px"}
                                        variant={"h6"}
                                        color={"text.secondary"}
                                        mb={.25}
                                    >
                                        {t('app.keyInfoLabel')}
                                    </Typography>
                                    <Typography variant={"body1"}>{content.keyInfo || '-'}</Typography>
                                </Box>
                                <Box mb={2}>
                                    <Typography
                                        fontSize={"12px"}
                                        variant={"h6"}
                                        color={"text.secondary"}
                                        mb={.25}
                                    >
                                        {t('app.targetAudienceLabel')}
                                    </Typography>
                                    <Typography variant={"body1"}>{content.targetAudience || '-'}</Typography>
                                </Box>

                                {/*<Box mb={2}>*/}
                                {/*    <Typography*/}
                                {/*        fontSize={"12px"}*/}
                                {/*        variant={"h6"}*/}
                                {/*        color={"text.secondary"}*/}
                                {/*        mb={.25}*/}
                                {/*    >*/}
                                {/*        {t('app.translationsLabel')}*/}
                                {/*    </Typography>*/}
                                {/*    <Typography style={{whiteSpace: "pre-wrap"}} variant={"body1"}>{content?.translatedResult || '-'}</Typography>*/}
                                {/*</Box>*/}

                            </Box>
                        </CardContent>
                        <CardActions>

                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} lg={8}>

                    <Card sx={{ minWidth: 275, marginBottom: 3}}>

                        <CardContent>
                            <Typography variant="h5" component="div">
                                {t('app.contentLabel')}
                            </Typography>

                            {content?.result && (
                                <Box sx={{marginLeft: -0.5, marginBottom: 2}}>
                                    <Button
                                        onClick={copyContent}
                                        color={'secondary'}
                                        size={"small"}
                                        startIcon={<ContentCopyIcon />}
                                    >
                                        {t("app.copyContentButtonLabel")}
                                    </Button>

                                    <LoadingButton
                                        loading={isTranslationLoading}
                                        onClick={openTranslationDialog}
                                        color={'success'}
                                        size={"small"}
                                        startIcon={<TranslateIcon />}
                                    >
                                        {t("app.translateContentButtonLabel")}
                                    </LoadingButton>
                                    <LoadingButton
                                        loading={isSaving}
                                        onClick={saveCopy}
                                        color={'primary'}
                                        size={"small"}
                                        startIcon={<SaveIcon />}
                                    >
                                        {t("app.saveContentButtonLabel")}
                                    </LoadingButton>
                                </Box>

                            )}
                            <Box>
                                <TextField
                                    label={'Content'}
                                    value={selectedSavedContent?.result}
                                    multiline
                                    minRows={2}
                                    fullWidth
                                    onChange={(e) =>
                                        setSelectedSavedContent({...selectedSavedContent, result: e.target.value})}
                                    sx={{ marginBottom: 2 }}
                                />
                            </Box>
                        </CardContent>

                    </Card>

                    {selectedSavedContent?.translatedResult && (

                        <Card sx={{ minWidth: 275}}>

                        <CardContent>
                            <Typography variant="h5" component="div">
                                {t('app.translationsLabel')}
                            </Typography>

                                <Box sx={{marginLeft: -0.5, marginBottom: 2}}>
                                    {/*<Button*/}
                                    {/*    onClick={copyContent}*/}
                                    {/*    color={'secondary'}*/}
                                    {/*    size={"small"}*/}
                                    {/*    startIcon={<ContentCopyIcon />}*/}
                                    {/*>*/}
                                    {/*    {t("app.copyContentButtonLabel")}*/}
                                    {/*</Button>*/}

                                    {shouldShowSaveTranslationButton && (
                                        <LoadingButton
                                            loading={isSavingTranslation}
                                            onClick={saveTranslation}
                                            color={'primary'}
                                            size={"small"}
                                            startIcon={<SaveIcon />}
                                        >
                                            {t("app.saveContentButtonLabel")}
                                        </LoadingButton>
                                    )}

                                </Box>

                            <Box sx={{marginTop: 3}}>
                                <Typography variant="body2" style={{whiteSpace: "pre-wrap"}}>
                                    {
                                        isTranslationLoading ?
                                            <>
                                                <Skeleton />
                                                <br/>
                                                <Skeleton />
                                                <Skeleton />
                                                <Skeleton />
                                                <br/>
                                                <Skeleton />
                                                <Skeleton />
                                                <Skeleton />
                                                <br/>
                                                <Skeleton />
                                                <Skeleton />
                                                <Skeleton />
                                            </>

                                            :  selectedSavedContent.translatedResult
                                    }
                                </Typography>
                            </Box>


                        </CardContent>

                    </Card>

                        )
                    }

                </Grid>

            </Grid>


            <Dialog disableEscapeKeyDown open={isTranslationDialogOpen} onClose={handleTranslationDrawerClose}>
                <DialogTitle>{t('app.chooseTranslationOptions')}</DialogTitle>
                <DialogContent>
                    <Box>
                        <Box sx={{ marginBottom: 2 }}>
                            <InputLabel id="selectTranslationTypeLabel">{t('app.selectTranslationTypeLabel')}</InputLabel>
                            <Select
                                labelId="selectTranslationTypeLabel"
                                value={selectedTranslationType.value}
                                onChange={handleSelectTranslationType}
                                fullWidth
                                label={t('app.selectTranslationTypeLabel')}
                            >
                                {translationTypes.map( type => (
                                    <MenuItem value={type.value}>{t(type.label)}</MenuItem>
                                ))}
                            </Select>
                            <Typography variant={'caption'} color="text.secondary" gutterBottom>
                                {t(selectedTranslationType.description)}
                            </Typography>
                        </Box>
                        <Box sx={{ m: 1 }}>
                            <InputLabel id="selectLanguagesLabel">{t('app.selectLanguagesLabel')}</InputLabel>
                            <Select
                                labelId="selectLanguagesLabel"
                                multiple
                                value={selectedTranslationLanguages}
                                onChange={handleTranslationLanguageChange}
                                fullWidth
                                label={t('app.selectLanguagesLabel')}
                                sx={{ marginBottom: 2 }}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((locale) => (
                                            <Chip key={locale} label={getLanguageFromLocale(locale)?.language} />
                                        ))}
                                    </Box>
                                )}
                            >
                                {translationLanguages.map( translationLanguage => (
                                    <MenuItem value={translationLanguage.locale}>{translationLanguage.language}</MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleTranslationDrawerClose}>{t('app.cancelButtonLabel')}</Button>
                    <Button onClick={translateContent}>{t('app.translateContentButtonLabel')}</Button>
                </DialogActions>
            </Dialog>

            <Snackbar anchorOrigin={{vertical: 'top', horizontal:'center' }}
                      open={isSnackBarOpen} autoHideDuration={5000} onClose={handleSnackBarClose}
            >
                <Alert severity={alertSeverity} sx={{ width: '100%' }}>
                    {snackBarMessage}
                </Alert>
            </Snackbar>

        </React.Fragment>
    );
};

export default EditContent;
