import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    CardActions,
    CardContent, Dialog, DialogActions, DialogContent,
    Divider,
    Drawer,
    Grid,
    IconButton, TextField,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {StorageService} from "../../services/StorageService";
import {useNavigate} from "react-router-dom";
import {Skeleton} from "@mui/lab";
import {ProjectService} from "../../services/ProjectService";
import DialogTitle from "@mui/material/DialogTitle";
import {TeamService} from "../../services/TeamService";


const Projects = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const user = StorageService.loadUser();
    const team = StorageService.loadTeam();
    const business = StorageService.loadSelectedBusiness();

    const [projects, setProjects] = useState(StorageService.loadProjects());

    const [isLoading, setIsLoading] = useState(false);

    const [projectName, setProjectName] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [isCreateProjectDialogOpen, setIsCreateProjectDialogOpen] = useState(false);


    useEffect( async () => {

        if (!user) {
            navigate('/login', {replace: true});
        }
        if (user?.status === 'unverified') {
            navigate('/welcome', {replace: true});
        }

        await getTeamProjects();

    }, []);

    const handleNewProjectNameChange = (e) => {
        setProjectName(e.target.value);
    }

    const handleNewProjectDescriptionChange = (e) => {
        setProjectDescription(e.target.value);
    }

    const getTeamProjects = async () => {

        setIsLoading(true);
        const response =  await ProjectService.getTeamProjects(team?.uid, user.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setProjects(data.projects);
                StorageService.saveProjects(data.projects);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const handleProjectDialogClose = () => {
        setIsCreateProjectDialogOpen(false);
    }

    const createNewProject = async () => {

        if (projectName === '') {
            return;
        }

        setIsLoading(true);

        handleProjectDialogClose();
        const response =  await ProjectService.createProject(
            projectName,
            projectDescription,
            team?.uid,
            business?.uid,
            user.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setProjectName('');
                setProjectDescription('')
                await getTeamProjects();

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }

    };

    return (
        <React.Fragment>
            <Typography variant={'h1'} >
                {t("app.projectsTitleLabel")}
                <Button
                    variant={"outlined"}
                    sx={{marginLeft: 1}}
                    onClick={() => setIsCreateProjectDialogOpen(true)}
                    size={'small'}
                >
                    {t('app.createProjectButtonLabel')}
                </Button>
            </Typography>
            {/*<Typography variant={'h6'} color={'text.secondary'}>*/}
            {/*    {t("app.projectDescriptionLabel")}*/}
            {/*</Typography>*/}

            <Divider/>

            <Box mt={5}>

                <Box>
                    {
                        isLoading && (
                            <>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </>
                        )
                    }

                    {!isLoading && projects?.length < 1 && (

                        <Card>
                            <CardContent>
                                <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                    <Typography variant={'h4'}>
                                        {t('app.noProjectsCreatedLabel')}
                                    </Typography>
                                </Box>


                                <Box mt={3} sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                    <Button
                                        onClick={() => setIsCreateProjectDialogOpen(true)}
                                        size={'small'}
                                        variant={"outlined"}>{t('app.createProjectButtonLabel')}</Button>

                                </Box>
                            </CardContent>
                        </Card>
                    )}

                    {!isLoading && projects?.length > 0 && (
                        <Grid container spacing={3.75} mb={3}>

                            {projects.map( (project, index) =>
                                (
                                    <>
                                        <Grid key={project.uid} item xs={12} sm={6} lg={3}>
                                            <Card>
                                                <CardContent sx={{minHeight: 120}}>
                                                    <Typography variant={'h4'}>
                                                        {project.name}
                                                    </Typography>

                                                    <Typography variant={'p'} color={'text.secondary'}>
                                                        {project.description}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions>

                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    </>
                                )
                            )}
                        </Grid>
                    )}
                </Box>
            </Box>

            <Dialog fullWidth={true} maxWidth={'sm'} disableEscapeKeyDown open={isCreateProjectDialogOpen} onClose={handleProjectDialogClose}>
                <DialogTitle>{t('app.newProjectLabel')}</DialogTitle>
                <DialogContent>
                    <Box>
                        <TextField
                            label={t('app.projectName')}
                            fullWidth
                            onChange={handleNewProjectNameChange}
                            sx={{ marginBottom: 2, marginTop: 2 }}
                        />
                    </Box>

                    <Box>
                        <TextField
                            label={t('app.projectDescription')}
                            fullWidth
                            multiline
                            minRows={3}
                            onChange={handleNewProjectDescriptionChange}
                            sx={{ marginBottom: 2 }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleProjectDialogClose}>{t('app.cancelButtonLabel')}</Button>
                    <Button onClick={createNewProject}>{t('app.createProjectButtonLabel')}</Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    );
};

export default Projects;