import React, {useEffect, useState} from 'react';
import {Box, Card, CardContent, Divider, Typography} from "@mui/material";
import SavedContentItemCard from "./SavedContentItemCard";
import {useTranslation} from "react-i18next";
import {StorageService} from "../../services/StorageService";
import {useNavigate} from "react-router-dom";
import {CreatorService} from "../../services/CreatorService";
import {Skeleton} from "@mui/lab";
import Button from "@mui/material/Button";


const SavedContent = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const user = StorageService.loadUser();
    const team = StorageService.loadTeam();
    const isAccountOwner = StorageService.loadIsAccountOwner();



    const [savedContent, setSavedContent] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedSavedContent, setSelectedSavedContent] = useState(null);

    const onSavedContentClick = (content) => {
        setSelectedSavedContent(content);
    }

    const onEditContentClick = () => {
        navigate('/edit-content', { state: selectedSavedContent })
    }

    const removeContentFromList = () => {

        const contentList = savedContent;
        const selectedContentIndex = contentList.findIndex((content) => {
            return content.uid === selectedSavedContent.uid;
        });


        contentList.splice(selectedContentIndex, 1);
        setSavedContent(contentList);
        setSelectedSavedContent(null);

        setIsLoading(true);
        setTimeout(() => setIsLoading(false), 300);

    }

    const deleteCopy = async () => {
        const response =  await CreatorService.deleteCopy(selectedSavedContent.uid, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;

        // @ts-ignore
        if (response.ok) {
            if (data && data.status === 'success') {
                removeContentFromList();
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    };


    useEffect( async () => {

        if (!user) {
            navigate('/login', {replace: true});
        }
        if (user?.status === 'unverified') {
            navigate('/welcome', {replace: true});
        }

        setIsLoading(true);
        const response =  await CreatorService.fetchTeamCopies(team?.uid, user.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setSavedContent(data.copies);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }

    }, []);


    return (
        <React.Fragment>
            <Typography variant={'h1'} mb={2}>{t("app.savedContentLabel")}</Typography>
            <Divider/>

            <Box mt={5}>
                {
                    isLoading && (
                        <>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </>
                    )
                }

                {(!isLoading && savedContent?.length > 0) && (
                    <>
                        {savedContent.map((content, i) => (
                            <Box onClick={() => {onSavedContentClick(content)}}>
                                <SavedContentItemCard
                                    content={content}
                                    contentList={savedContent}
                                    onEdit={onEditContentClick}
                                    onDelete={deleteCopy}
                                    canDelete={isAccountOwner}
                                />
                            </Box>
                        ))}
                    </>
                )}

                {savedContent?.length < 1 && !isLoading && (
                    <Box>
                        <Card>
                            <CardContent>
                                <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                    <Typography variant={'h4'}>
                                        {t('app.noSavedContentLabel')}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                )}
            </Box>
        </React.Fragment>
    );
};

export default SavedContent;