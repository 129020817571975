import {APIService} from './APIService';

export class TranslationService extends APIService {
  static async translateContent(
      content,
      locales,
      translationType,
    accessToken,
  ) {
    return await this.makeRequest(
      'api/translate-content',
      {
          content,
          locales,
          translationType,
      },
      {
        'x-access-token': accessToken,
        'Content-Type': 'application/json',
      },
    );
  }
}
