import React from "react";

import Page from "@jumbo/shared/Page";
import Home from "../pages/home";
import Create from "../pages/create";
import Welcome from "../pages/welcome";
import SavedContent from "../pages/saved-content";
import EditContent from "../pages/edit-content";
import Plans from "../pages/plans";
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js";
import {StorageService} from "../services/StorageService";
import Subscription from "../pages/subscription";
import Profile from "../pages/profile";
import BusinessSettings from "../pages/business-settings";
import Team from "../pages/team";
import Campaigns from "../pages/campaigns";
import NewCampaign from "../pages/new-campaign";
import Campaign from "../pages/campaign";
import BrandVoices from "../pages/brand-voices";
import NewBrandVoice from "../pages/new-brand-voice";
import Projects from "../pages/projects";
import Accounts from "../pages/accounts";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const user = StorageService.loadUser();
const options = {
    mode: 'subscription',
    currency: 'eur',
    amount: 1099,
    // paymentMethodCreation: 'manual',
    automatic_payment_methods: {
        enabled: true
    },
    locale: user ? user?.locale : 'en-GB'
};

const manualPaymentMethodCreationOptions = {
    mode: 'subscription',
    currency: 'eur',
    amount: 1099,
    paymentMethodCreation: 'manual',
    locale: user ? user?.locale : 'en-GB'
};
const PlansComponent = () => {
    return (
        <Elements stripe={stripePromise} options={options}>
            <Plans/>
        </Elements>
        );
};

const SubscriptionComponent = () => {
    return (
        <Elements stripe={stripePromise} options={options}>
            <Subscription/>
        </Elements>
    );
};

const dashboardRoutes = [
    {
        path: "/",
        element: <Page component={Home} />,
    },
    {
        path: "/welcome",
        element: <Page component={Welcome} layout={"solo-page"} />,
    },
    {
        path: "/brands",
        element: <Page component={Accounts} layout={"solo-page"} />,
    },
    {
        path: "/plans",
        element: <Page component={PlansComponent} layout={"solo-page"} />,
    },
    {
        path:"/create",
        element: <Page component={Create} />,
    },
    {
        path:"/edit-content",
        element: <Page component={EditContent} />,
    },
    {
        path: "/saved-content",
        element: <Page component={SavedContent}/>,
    },
    {
        path: "/team",
        element: <Page component={Team}/>,
    },
    {
        path: "/profile",
        element: <Page component={Profile} />,
    },
    {
        path: "/subscription",
        element: <Page component={SubscriptionComponent}/>,
    },
    {
        path: "/brand-profile",
        element: <Page component={BusinessSettings}/>,
    },

    {
        path: "/campaigns",
        element: <Page component={Campaigns}/>,
    },
    {
        path: "/campaigns/create",
        element: <Page component={NewCampaign}/>,
    },
    {
        path: "/campaigns/:id",
        element: <Page component={Campaign}/>,
    },
    {
        path: "/brand-voices",
        element: <Page component={BrandVoices}/>,
    },
    {
        path: "/brand-voices/create",
        element: <Page component={NewBrandVoice}/>,
    },
    {
        path: "/projects",
        element: <Page component={Projects}/>,
    },
];

export default dashboardRoutes;
