import React, {useCallback, useEffect, useState} from "react";
import {
    Box,
    Button,
    Card, CardActions,
    CardContent,
    Checkbox,
    FormControlLabel,
    IconButton, InputAdornment, Select, Step, StepLabel,
    Stepper, TextField,
    Typography
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import {useTranslation} from "react-i18next";
import CardHeader from "@mui/material/CardHeader";
import LoadingButton from "@mui/lab/LoadingButton";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import {StorageService} from "../../services/StorageService";
import {AuthService} from "../../services/AuthService";
import {SuggestionService} from "../../services/SuggestionService";
import {WelcomeService} from "../../services/WelcomeService";
import {useLocation, useNavigate} from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {ASSET_IMAGES} from "../../utils/constants/paths";


const Welcome = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const {setAuthToken} = useJumboAuth();

    // edit or create a new business through the business settings page
    const businessSettings = location.state;

    const [existingBusiness, setExistingBusiness] = useState(null);
    const [mode, setMode] = useState('setup');


    const [isStepOneVisible, setIsStepOneVisible] = useState(true);
    const [isStepTwoVisible, setIsStepTwoVisible] = useState(false);
    const [isStepThreeVisible, setIsStepThreeVisible] = useState(false);
    const [accountActivationCode, setAccountActivationCode] = useState('400800');

    const [isLoadingToneOfVoices, setIsLoadingToneOfVoices] = useState(false);
    const [isLoadingWritingStyles, setIsLoadingWritingStyles] = useState(false);
    const [isLoadingSetupBusiness, setIsLoadingSetupBusiness] = useState(false);

    const [isLoadingSkipSetupBusiness, setIsLoadingSkipSetupBusiness] = useState(false);
    const [isLoadingActivateAccount, setIsLoadingActivateAccount] = useState(false);



    const [toneOfVoices, setToneOfVoices] = useState([]);
    const [writingStyles, setWritingStyles] = useState([]);

    const [businessName, setBusinessName] = useState('');
    const [businessDescription, setBusinessDescription] = useState('');
    const [servicesOffered, setServicesOffered] = useState('');
    const [website, setWebsite] = useState('');
    const [industry, setIndustry] = useState('');
    const [targetAudience, setTargetAudience] = useState('');

    const [tone, setTone] = useState('');
    const [writingStyle, setWritingStyle] = useState('');


    const [selectedSuggestedTone, setSelectedSuggestedTone] = useState();

    const [selectedSuggestedWritingStyle, setSelectedSuggestedWritingStyle] = useState();

    const preferredContentLanguages = StorageService.loadSupportedContentLanguages();
    const user = StorageService.loadUser();
    const [selectedLanguage, setSelectedLanguage] = useState(user.locale);


    // hasSubscription = false for new signups and team invitees who want to a setup their own business profile
    const hasSubscription = StorageService.loadHasSubscription();


    useEffect( () => {
        // TODO
        // rethink restriction logic of this page
        // if (user.status === 'active' && !businessSettings) {
        //     navigate('/', {replace: true});
        // }

        if (businessSettings) {
            setMode(businessSettings.mode);
            setExistingBusiness(businessSettings?.business);

            setupExistingBusinessFields(businessSettings?.business);
        }
    }, []);

    const setupExistingBusinessFields = (existingBusiness) => {

        if (existingBusiness){

            setBusinessName(existingBusiness.name);
            setBusinessDescription(existingBusiness.description);
            setServicesOffered(existingBusiness.servicesOffered);
            setWebsite(existingBusiness.website);
            setIndustry(existingBusiness.industry);
            setTargetAudience(existingBusiness.targetAudience);

            setTone(existingBusiness.tone);
            setWritingStyle(existingBusiness.writingStyle);
            setSelectedLanguage(existingBusiness.preferredContentLanguage);


        }
    }
    const handleNext = () => {

        if (mode !== 'edit') {
            handleSuggestToneOfVoices();
            handleSuggestWritingStyles()
        }

      setIsStepOneVisible(false);
      setIsStepTwoVisible(true);
      setIsStepThreeVisible(false);
    }

    const handleBack = () => {
        setIsStepOneVisible(true);
        setIsStepTwoVisible(false);
        setIsStepThreeVisible(false);
    }

    const handleFinish = async () => {
        setIsLoadingSetupBusiness(true);

        const toneDescription = selectedSuggestedTone ? selectedSuggestedTone.toneDescription : '';
        const toneExample= selectedSuggestedTone ? selectedSuggestedTone.exampleTagLine : '';

        const writingStyleDescription = selectedSuggestedWritingStyle ? selectedSuggestedWritingStyle.styleDescription : '';
        const writingStyleExample= selectedSuggestedWritingStyle ? selectedSuggestedWritingStyle.exampleStyleCopy : '';

        const response = await WelcomeService.setupBusiness(
            businessName, businessDescription,servicesOffered,
            website, industry, targetAudience,
            tone,toneDescription, toneExample, writingStyle,
            writingStyleDescription, writingStyleExample,
            selectedLanguage, mode, user.token);

        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoadingSetupBusiness(false);
        // @ts-ignore
        if (response.ok) {
            if (data) {

                if (mode === 'setup') {
                    // StorageService.saveBusinesses(data.businesses);
                    StorageService.saveAccounts(data.accounts);
                    StorageService.saveSelectedAccount(data.selectedAccount);
                    StorageService.saveAccountInvitations(data.accountInvitations);
                    StorageService.saveHasSubscription(data.hasSubscription);

                    setIsStepOneVisible(false);
                    setIsStepTwoVisible(false);
                    setIsStepThreeVisible(true);
                }
                else if (mode === 'create') {
                    // StorageService.saveBusinesses(data.businesses);
                    StorageService.saveAccounts(data.accounts);
                    StorageService.saveSelectedAccount(data.selectedAccount);
                    StorageService.saveAccountInvitations(data.accountInvitations);
                    StorageService.saveHasSubscription(data.hasSubscription);

                    navigate('/brand-profile', {replace: true})
                }

                else if (mode === 'edit') {
                    StorageService.saveAccounts(data.accounts);
                    navigate('/brand-profile', {replace: true})
                }

                else if(mode === 'add-account') {
                    StorageService.saveAccounts(data.accounts);
                    StorageService.saveSelectedAccount(data.selectedAccount);
                    StorageService.saveAccountInvitations(data.accountInvitations);
                    StorageService.saveHasSubscription(data.hasSubscription);

                    // if hasSub navigate to brands

                    if (hasSubscription) {
                        navigate('/brands', {replace: true})
                    }
                    // else navigate to /plans
                    else {
                        navigate('/plans', {replace: true})
                    }

                }


            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }


    }

    const handleSkip = async () => {

        setIsLoadingSkipSetupBusiness(true);

        const response = await WelcomeService.skipSetupBusiness(user.token);

        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoadingSkipSetupBusiness(false);
        // @ts-ignore
        if (response.ok) {
            if (data) {

                // StorageService.saveBusinesses(data.business);
                // StorageService.saveProjects(data.projects);
                // StorageService.saveTeam(data.team);

                StorageService.saveAccounts(data.accounts);
                StorageService.saveSelectedAccount(data.selectedAccount);
                StorageService.saveAccountInvitations(data.accountInvitations);
                StorageService.saveHasSubscription(data.hasSubscription);


                setIsStepOneVisible(false);
                setIsStepTwoVisible(false);
                setIsStepThreeVisible(true);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const handleSelectSuggestedTone = (tone) => {
        setSelectedSuggestedTone(tone);
        setTone(tone.toneName);
    };

    const handleSelectSuggestedWritingStyle = (writingStyle) => {
        setSelectedSuggestedWritingStyle(writingStyle);
        setWritingStyle(writingStyle.styleName);
    };

    const handleSuggestToneOfVoices = async () => {
        setIsLoadingToneOfVoices(true);
        setToneOfVoices([]);
        setTone('');
        setSelectedSuggestedTone(null);

        const response = await SuggestionService.suggestToneOfVoices(user.language, businessName, businessDescription,
            industry, targetAudience, user.token);

        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoadingToneOfVoices(false);
        // @ts-ignore
        if (response.ok) {
            if (data && data.result) {
                setToneOfVoices(data.result.tones);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }

    };

    const handleSuggestWritingStyles = async () => {
        setIsLoadingWritingStyles(true);
        setWritingStyles([]);
        setWritingStyle('');
        setSelectedSuggestedWritingStyle(null);

        const response = await SuggestionService.suggestWritingStyles(user.language, businessName, businessDescription,
            industry, targetAudience, tone, user.token);

        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoadingWritingStyles(false);
        // @ts-ignore
        if (response.ok) {
            if (data && data.result) {
                setWritingStyles(data.result.styles);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    };

    const handleFieldChange = useCallback(
        (callback, e) => {
            const value = e.target.value;
            callback(value);

        },
        [setBusinessName, setBusinessDescription, setServicesOffered,
            setWebsite, setIndustry, setTargetAudience, setTone, setWritingStyle],
    );



    const handleLanguageChange = useCallback(
        (e) => {
            const value = e.target.value;
            setSelectedLanguage(value);
        },
        [setSelectedLanguage],
    );

    const activateAccount = async () => {

        setIsLoadingActivateAccount(true);

        const response = await AuthService.activateAccount(accountActivationCode, user.token);

        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoadingActivateAccount(false);
        // @ts-ignore
        if (response.ok) {
            if (data) {

                setAuthToken(data.user.token)
                StorageService.saveUser(data.user);
                navigate('/plans', {replace: true});


            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    };

    return (
        <React.Fragment>

            <Div sx={{
                flex: 1,
                flexWrap: 'wrap',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: theme => theme.spacing(4),
            }}>
                <Box sx={{
                    width: 720,
                    maxWidth: '100%',
                    margin: 'auto',
                    marginBottom: '20px',
                    p: 4}}>
                    <Box align={'center'}>
                        <img src={`${ASSET_IMAGES}/grayscript_logo_192.png`} alt="Grayscript" />
                    </Box>
                    <Card>
                        <CardContent>
                            { mode === 'setup' && (
                                <>
                                    <Typography variant="h3">
                                        {t('app.welcomeMessage')} {t('app.name')}
                                    </Typography>
                                    <Typography variant={'body1'} gutterBottom>
                                        {t('app.getStartedMessage')}
                                    </Typography>
                                </>
                            )}

                            { mode === 'create' && (
                                <>
                                    <Typography variant="h3">
                                        {t('app.createBrandLabel')}
                                    </Typography>
                                    <Typography variant={'body1'} gutterBottom>
                                        {t('app.getStartedMessage')}
                                    </Typography>
                                </>
                            )}

                            { mode === 'add-account' && (
                                <>
                                    <Typography variant="h3">
                                        {t('app.createBrandLabel')}
                                    </Typography>
                                    <Typography variant={'body1'} gutterBottom>
                                        {t('app.getStartedMessage')}
                                    </Typography>
                                </>
                            )}

                            { mode === 'edit' && (
                                <>
                                    <Typography variant="h3">
                                        {t('app.editBrandLabel')}
                                    </Typography>
                                </>
                            )}


                    <Box mt={5}>
                        {isStepOneVisible && (
                            <Box>
                                <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                    {t('app.enterBrandInfo')}
                                </Typography>
                                <Box mt={2}>
                                    <TextField
                                        label={t('app.brandNameLabel')}
                                        value={businessName}
                                        fullWidth
                                        onChange={(e) =>
                                            {handleFieldChange(setBusinessName, e)}}
                                        sx={{ marginBottom: 2 }}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        label={t('app.brandDescriptionLabel')}
                                        value={businessDescription}
                                        multiline
                                        fullWidth
                                        minRows={2}
                                        onChange={(e) =>
                                        {handleFieldChange(setBusinessDescription, e)}}
                                        sx={{ marginBottom: 2  }}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        label={t('app.businessServicesOfferedLabel')}
                                        value={servicesOffered}
                                        multiline
                                        minRows={2}
                                        fullWidth
                                        onChange={(e) =>
                                        {handleFieldChange(setServicesOffered, e)}}
                                        sx={{ marginBottom: 2 }}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        label={t('app.businessWebsiteLabel')}
                                        value={website}
                                        onChange={(e) =>
                                        {handleFieldChange(setWebsite, e)}}
                                        fullWidth
                                        sx={{ marginBottom: 2 }}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        label={t('app.businessIndustryLabel')}
                                        value={industry}
                                        onChange={(e) =>
                                        {handleFieldChange(setIndustry, e)}}
                                        fullWidth
                                        sx={{ marginBottom: 2 }}
                                    />
                                </Box>

                                <Div>
                                    <Button variant={'outlined'} onClick={handleNext}>
                                        {t('app.nextButtonLabel')}
                                    </Button>
                                </Div>
                            </Box>
                        )}

                        {isStepTwoVisible && (
                            <Box>
                                <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                    {t('app.enterBrandIdentity')}
                                </Typography>
                                <Box mt={2}>
                                    <TextField
                                        label={t('app.businessTargetAudienceLabel')}
                                        value={targetAudience}
                                        multiline
                                        minRows={2}
                                        fullWidth
                                        onChange={(e) =>
                                        {handleFieldChange(setTargetAudience, e)}}
                                        sx={{ marginBottom: 2  }}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        label={t('app.businessToneOfVoiceLabel')}
                                        placeholder={t('app.businessToneOfVoicePlaceholder')}
                                        fullWidth
                                        value={tone}
                                        onChange={(e) =>
                                        {handleFieldChange(setTone, e)}}
                                        sx={{ marginBottom: 1 }}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <LoadingButton onClick={handleSuggestToneOfVoices} loading={isLoadingToneOfVoices} size={'small'}>
                                                        {t('app.suggestButtonLabel')}
                                                    </LoadingButton>
                                                </InputAdornment>,
                                        }}
                                    />
                                    <Box sx={{marginBottom: 1}}>
                                        {selectedSuggestedTone && (
                                            <>
                                                <Box>
                                                    <Typography variant={'caption'} color="text.secondary" gutterBottom>
                                                        {selectedSuggestedTone.toneDescription}
                                                    </Typography>
                                                </Box>
                                                <Typography variant={'caption'} color="text.secondary" gutterBottom>
                                                    {t('app.exampleLabel')}: {selectedSuggestedTone.exampleTagLine}
                                                </Typography>
                                            </>
                                        )}

                                    </Box>
                                    {toneOfVoices && (
                                        <Box sx={{marginBottom: 2}}>
                                            {toneOfVoices.map( tone => (
                                                <Chip sx={{marginRight: 1, marginBottom:1}}
                                                      label={tone.toneName}
                                                      variant="outlined"
                                                      color={selectedSuggestedTone && selectedSuggestedTone.toneName === tone.toneName ? 'primary': 'default'}
                                                      onClick={()=> {handleSelectSuggestedTone(tone)}}
                                                />
                                            ))}
                                        </Box>
                                    )}


                                </Box>
                                <Box>
                                    <TextField
                                        label={t('app.businessWritingStyleLabel')}
                                        placeholder={t('app.businessWritingStylePlaceholder')}
                                        value={writingStyle}
                                        fullWidth
                                        onChange={(e) =>
                                        {handleFieldChange(setWritingStyle, e)}}
                                        sx={{ marginBottom: 1 }}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <LoadingButton onClick={handleSuggestWritingStyles} loading={isLoadingWritingStyles} size={'small'}>
                                                        {t('app.suggestButtonLabel')}
                                                    </LoadingButton>
                                                </InputAdornment>,
                                        }}
                                    />
                                    <Box sx={{marginBottom: 1}}>
                                        {selectedSuggestedWritingStyle && (
                                            <>
                                                <Box>
                                                    <Typography variant={'caption'} color="text.secondary" gutterBottom>
                                                        {selectedSuggestedWritingStyle.styleDescription}
                                                    </Typography>
                                                </Box>
                                                <Typography variant={'caption'} color="text.secondary" gutterBottom>
                                                    {t('app.exampleLabel')}: {selectedSuggestedWritingStyle.exampleStyleCopy}
                                                </Typography>
                                            </>
                                        )}

                                    </Box>
                                    {writingStyles && (
                                        <Box sx={{marginBottom: 2}}>
                                            {writingStyles.map( style => (
                                                <Chip sx={{marginRight: 1, marginBottom:1}}
                                                      label={style.styleName}
                                                      variant="outlined"
                                                      color={selectedSuggestedWritingStyle && selectedSuggestedWritingStyle.styleName === style.styleName ? 'primary': 'default'}
                                                      onClick={()=> {handleSelectSuggestedWritingStyle(style)}}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                </Box>
                                <Box>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        {t('app.businessPreferredContentLanguageLabel')}
                                    </Typography>
                                    <Select
                                        labelId="tone-label"
                                        value={selectedLanguage}
                                        onChange={handleLanguageChange}
                                        fullWidth
                                        label={t('app.businessPreferredContentLanguageLabel')}
                                        sx={{ marginBottom: 2 }}
                                    >
                                        {preferredContentLanguages.map( contentLanguage => (
                                            <MenuItem value={contentLanguage.locale}>{contentLanguage.language}</MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <Div>
                                    <Button variant={'outlined'} onClick={handleBack}
                                            sx={{marginRight:1}}>
                                        {t('app.backButtonLabel')}
                                    </Button>
                                    <LoadingButton loading={isLoadingSetupBusiness} variant={'contained'} onClick={handleFinish}>
                                        {t('app.finishButtonLabel')}
                                    </LoadingButton>
                                </Div>
                            </Box>
                        )}

                        {isStepThreeVisible && (
                            <Box>
                                <Box sx={{minHeight: 300, height: 'auto'}}>
                                    <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                        {t('app.enterAccountActivationCode')}
                                    </Typography>
                                    <Box mt={2}>
                                        <TextField
                                            label={t('app.accountActivationCodeLabel')}
                                            value={accountActivationCode}
                                            fullWidth
                                            onChange={(e) =>
                                            {handleFieldChange(setAccountActivationCode, e)}}
                                            sx={{ marginBottom: 2  }}
                                        />
                                    </Box>
                                </Box>
                                <Div>
                                    <LoadingButton loading={isLoadingActivateAccount}  variant={'contained'} onClick={activateAccount}>
                                        {t('app.activateAccountButtonLabel')}
                                    </LoadingButton>
                                </Div>
                            </Box>
                        )}
                    </Box>
                        </CardContent>
                    </Card>
                </Box>
                {(!isStepThreeVisible && mode === 'setup') &&(
                    <Box>
                        <LoadingButton loading={isLoadingSkipSetupBusiness}  variant="text" onClick={handleSkip}>
                            {t('app.skipBusinessSetupButtonLabel')}
                        </LoadingButton>
                    </Box>
                )}

                {(mode === 'edit' || mode === 'create') &&(
                    <Box>
                        <Button  variant="text" onClick={() => {navigate('/brand-profile', {replace: true})}}>
                            {t('app.cancelLabel')}
                        </Button>
                    </Box>
                )}

                {(mode === 'add-account') &&(
                    <Box>
                        <Button  variant="text" onClick={() => {navigate('/brands', {replace: true})}}>
                            {t('app.cancelLabel')}
                        </Button>
                    </Box>
                )}
            </Div>
        </React.Fragment>
    );
}

export default Welcome;