import {APIService} from './APIService';

export class BrandVoiceService extends APIService {
  static async analyzeBrandVoice(
      exampleText, locale, accessToken
  ) {
    return await this.makeRequest(
      `api/brand-voices/analyze`,
        {
            exampleText,
            locale
        },
      {
        'x-access-token': accessToken,
        'Content-Type': 'application/json',
      },
    );
  }

  static async createBrandVoice(
        name,
        exampleText,
        voiceSummary,
        voicePersonality,
        tone,
        writingStyle,
        isDefault,
        locale,
        teamUid,
        businessUid,
        accessToken,
    ) {
        return await this.makeRequest(
            `api/brand-voices`,
            {
                name,
                exampleText,
                voiceSummary,
                voicePersonality,
                tone,
                writingStyle,
                isDefault,
                locale,
                teamUid,
                businessUid,
            },
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            }
        );
  }

    static async updateBrandVoice(
        uid,
        name,
        voiceSummary,
        voicePersonality,
        tone,
        writingStyle,
        accessToken,
    ) {
        return await this.makeRequest(
            `api/brand-voices`,
            {
                name,
                voiceSummary,
                voicePersonality,
                tone,
                writingStyle
            },
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
            this.METHOD_PATCH
        );
    }

    static async getBrandVoice(
        brandVoiceUid, accessToken
    ) {
        return await this.makeRequest(
            `api/brand-voices/${brandVoiceUid}`,
            null,
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
            APIService.METHOD_GET
        );
    }

    static async getTeamBrandVoices(
        teamUid, accessToken
    ) {
        return await this.makeRequest(
            `api/teams/${teamUid}/brand-voices`,
            null,
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
            APIService.METHOD_GET
        );
    }

    static async deleteBrandVoice(
        brandVoiceUid, accessToken
    ) {
        return await this.makeRequest(
            `api/brand-voices/${brandVoiceUid}`,
            null,
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
            APIService.METHOD_DELETE
        );
    }

    static async markBrandVoiceAsDefault(
        uid,
        teamUid,
        accessToken,
    ) {
        return await this.makeRequest(
            `api/brand-voices/mark-default`,
            {
                uid,
                teamUid,
            },
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
        );
    }
}
