import {APIService} from './APIService';

export class TeamService extends APIService {
  static async getTeamMembers(
      teamUid,
      accessToken,
  ) {
    return await this.makeRequest(
      `api/teams/${teamUid}/members`,
      null,
      {
        'x-access-token': accessToken,
        'Content-Type': 'application/json',
      },
        APIService.METHOD_GET
    );
  }

  static async inviteTeamMember(
        inviteEmail,
        teamUid,
        businessUid,
        accessToken,
    ) {
        return await this.makeRequest(
            `api/teams/invite-member`,
            {
                inviteEmail,
                teamUid,
                businessUid
            },
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            }
        );
  }

    static async deleteInvite(
        inviteUid, accessToken
    ) {
        return await this.makeRequest(
            `api/teams/invites/${inviteUid}`,
            null,
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
            APIService.METHOD_DELETE
        );
    }

    static async acceptInvite(
        inviteUid,
        inviteCode,
        name,
        email,
        password,
        locale,
        language,
        localizedLanguage
    ) {
        return await this.makeRequest(
            `api/teams/accept-invite`,
            {
                inviteUid,
                inviteCode,
                name,
                email,
                password,
                locale,
                language,
                localizedLanguage
            },
            {
                'Content-Type': 'application/json',
            }
        );
    }

    static async removeTeamMember(
        memberUserUid,
        teamUid,
        accessToken
    ) {
        return await this.makeRequest(
            `api/teams/remove-member`,
            {
                memberUserUid,
                teamUid,
            },
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            }
        );
    }

    static async decisionOnInvite(
        uid,
        decision,
        accessToken
    ) {
        return await this.makeRequest(
            `api/teams/invites/decision`,
            {
                uid,
                decision,
            },
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            }
        );
    }

    static async decisionOnAllInvites(
        decision,
        accessToken
    ) {
        return await this.makeRequest(
            `api/teams/invites/decision/all`,
            {
                decision,
            },
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            }
        );
    }

    static async leaveTeam(
        uid,
        accessToken
    ) {
        return await this.makeRequest(
            `api/teams/leave`,
            {
                uid
            },
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            }
        );
    }
}
