import React, {useEffect, useState} from 'react';
import {Box, Card, CardContent, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {AuthService} from "../../../services/AuthService";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {useParams} from "react-router-dom";
import {SUPPORTED_LOCALES} from "../../../utils/locales";
import {StorageService} from "../../../services/StorageService";
import useJumboTheme from "../../../../@jumbo/hooks/useJumboTheme";



const ForgotPassword = () => {
    const {t, i18n} = useTranslation();
    const { locale } = useParams();

    const defaultLanguage = SUPPORTED_LOCALES[1]

    const [languages, setLanguages] = useState(SUPPORTED_LOCALES);
    const [selectedLanguage, setSelectedLanguage] = useState(StorageService.loadAppLocale()?.locale || defaultLanguage.locale);
    const [selectedLanguageObject, setSelectedLanguageObject] = useState(defaultLanguage);
    const {setMuiLocale} = useJumboTheme();
    const [activeLocale, setActiveLocale] = React.useState(languages[1]);

    useEffect(() => {

        changeLanguage(locale || selectedLanguage);

    }, []);

    const changeLanguage = (value) => {
        setSelectedLanguage(value);

        const localeIndex = languages.findIndex(language => language.locale === value);
        if (localeIndex !== -1) {
            i18n.changeLanguage(languages[localeIndex].locale).then(() => {
                setActiveLocale(languages[localeIndex]);
                setMuiLocale(languages[localeIndex].muiLocale);

                StorageService.saveAppLocale(languages[localeIndex]);

                setSelectedLanguageObject(languages[localeIndex]);
            });
        }
    }

    const validationSchema = yup.object({
        email: yup
            .string(t('app.emailFieldFormValidationLabel'))
            .email(t('app.emailFieldFormValidationMessage'))
            .required(t('app.emailFieldFormValidationError')),
    });

    const onForgotPassword = async (email) => {

        const response = await AuthService.forgotPassword(email);

        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        // @ts-ignore
        if (response.ok) {
            if (data) {
                Swal.fire({
                text: t('app.forgotPasswordSuccessMessage'),
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: t('app.dismissButtonLabel'),
                confirmButtonColor: 'error',
            }).then(async (result) => {
                if (result.value) {
                } else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                }
            });

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    };

    return (
        <Div sx={{
            width: 720,
            maxWidth: '100%',
            margin: 'auto',
            p: 4
        }}>
            <Box align={'center'}>
                <img src={`${ASSET_IMAGES}/grayscript_logo_192.png`} alt="Grayscript" />
            </Box>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: {xs: 'column', md: 'row'}
                }}
            >

                <CardContent sx={{flex: 1, p: 4}}>

                    <Typography variant={"body1"} color={'text.secondary'}>
                        {t('app.forgotPasswordMessage')}

                    </Typography>
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            email: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => {
                            setSubmitting(true);
                            onForgotPassword(data.email);
                            setSubmitting(false);
                        }}
                    >
                        {({isSubmitting}) => (
                            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>
                                <Div sx={{mt: 1, mb: 3}}>
                                    <JumboTextField
                                        fullWidth
                                        name="email"
                                        label= {t('app.emailLabel')}
                                        type="email"
                                    />
                                </Div>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    loading={isSubmitting}
                                >{t('app.resetPasswordButtonLabel')}</LoadingButton>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        </Div>
    );
};

export default ForgotPassword;
