import {APIService} from './APIService';

export class WelcomeService extends APIService {
  static async setupBusiness(
      businessName,
      businessDescription,
      servicesOffered,
      website,
      industry,
      targetAudience,
      tone,
      toneDescription,
      toneExample,
      writingStyle,
      writingStyleDescription,
      writingStyleExample,
      preferredContentLanguage,
      mode,
      accessToken,
  ) {
    return await this.makeRequest(
      'api/welcome/setup-business',
      {
          businessName,
          businessDescription,
          servicesOffered,
          website,
          industry,
          targetAudience,
          tone,
          toneDescription,
          toneExample,
          writingStyle,
          writingStyleDescription,
          writingStyleExample,
          preferredContentLanguage,
          mode
      },
      {
        'x-access-token': accessToken,
        'Content-Type': 'application/json',
      },
    );
  }

    static async skipSetupBusiness(accessToken) {
        return await this.makeRequest(
            'api/welcome/skip-setup-business',
            {},
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
        );
    }

    static async getBrand(
        businessUid, accessToken
    ) {
        return await this.makeRequest(
            `api/brands/${businessUid}`,
            null,
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
            APIService.METHOD_GET
        );
    }

}
