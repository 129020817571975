export const getAbbreviation = (name) => {

    if(!name) {
        return '';
    }

    const words = name.trim().split(' ');
    let abbreviation = '';

    for (const word of words) {
        abbreviation += word[0].toUpperCase();
    }

    return abbreviation;
}