import React from 'react';
import Button from "@mui/material/Button";
import { Card, CardActions, CardContent, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const CopyItemCard = ({copyType}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
            <Card>
                <CardContent sx={{height: 200}}>
                    <Typography variant="h5" component="div">
                        {t(copyType.titleKey)}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {t(copyType.descriptionKey)}
                    </Typography>
                </CardContent>
                <CardActions sx={{marginTop:5}}>
                    <Button sx={{marginLeft: -1}} size={"small"} onClick={() => {
                        navigate('/create', { state: copyType })
                    }}>{t('app.createButtonLabel')}</Button>
                </CardActions>
            </Card>
    );
};

export default CopyItemCard;
