import React, { useEffect, useState} from "react";
import Div from "@jumbo/shared/Div";
import {Box, Button, Card, CardContent, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Span from "@jumbo/shared/Span";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {AddressElement, PaymentElement} from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import LoadingButton from "@mui/lab/LoadingButton";
import {StorageService} from "../../services/StorageService";
import {SubscriptionService} from "../../services/SubscriptionService";
import {Skeleton, ToggleButton, ToggleButtonGroup} from "@mui/lab";
import {useLocation, useNavigate} from "react-router-dom";
import Chip from "@mui/material/Chip";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {SUPPORTED_LOCALES} from "../../utils/locales";
import useJumboTheme from "../../../@jumbo/hooks/useJumboTheme";



const Plans = () => {

    const stripe = useStripe();
    const elements = useElements();

    const {t, i18n} = useTranslation();
    const Swal = useSwalWrapper();

    const navigate = useNavigate();
    const location = useLocation();
    const [currentPlan, setCurrentPlan] = useState(location.state);
    const autoSubscribeTestMode = true;


    const STEP_SELECT_PLAN = 'select-plan';
    const STEP_SUBSCRIBE= 'subscribe';

    const [step, setStep] = useState(STEP_SELECT_PLAN);
    const [monthlyPlans, setMonthlyPlans] = useState(null)
    const [yearlyPlans, setYearlyPlans] = useState(null)
    const [plans, setPlans] = useState(monthlyPlans);
    const [trialEndDate, setTrialEndDate] = useState(null);
    const [initialBillingDate, setInitialBillingDate] = useState(null);

    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isLoadingSkipPayment, setIsLoadingSkipPayment] = useState(false);
    const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);
    const [isPlansLoading, setIsPlansLoading] = useState(false);
    const [billingCycleCheck, setBillingCycleCheck] = useState(false);
    const [billingCycleInterval, setBillingCycleInterval] = useState('Monthly');


    const user = StorageService.loadUser();
    const team = StorageService.loadTeam();


    const defaultLanguage = SUPPORTED_LOCALES[1]

    const [languages, setLanguages] = useState(SUPPORTED_LOCALES);
    const [selectedLanguage, setSelectedLanguage] = useState(StorageService.loadAppLocale()?.locale || defaultLanguage.locale);
    const [selectedLanguageObject, setSelectedLanguageObject] = useState(defaultLanguage);
    const {setMuiLocale} = useJumboTheme();
    const [activeLocale, setActiveLocale] = React.useState(languages[1]);


    useEffect(async () => {
        changeLanguage(selectedLanguage);

        await getPlans();
    }, [])

    const changeLanguage = (value) => {
        setSelectedLanguage(value);

        const localeIndex = languages.findIndex(language => language.locale === value);
        if (localeIndex !== -1) {
            i18n.changeLanguage(languages[localeIndex].locale).then(() => {
                setActiveLocale(languages[localeIndex]);
                setMuiLocale(languages[localeIndex].muiLocale);

                StorageService.saveAppLocale(languages[localeIndex]);

                setSelectedLanguageObject(languages[localeIndex]);
            });
        }
    }

    const getPlans = async () => {
        setIsPlansLoading(true);
        const response = await SubscriptionService.getPlans(
            user?.token
        );

        // @ts-ignore
        const resJson = await response.json();


        const {data} = resJson;
        setIsPlansLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                const monthlyPlans = data.plans.monthlyPlans;
                const yearlyPlans = data.plans.yearlyPlans;

                monthlyPlans.map( (plan) => {
                    // plan.features = plan.features.split(',');
                    plan.featuresKey = plan.featuresKey.split(',');

                })

                yearlyPlans.map( (plan) => {
                    // plan.features = plan.features.split(',');
                    plan.featuresKey = plan.featuresKey.split(',');
                })

                setMonthlyPlans(monthlyPlans);
                setYearlyPlans(yearlyPlans);
                setPlans(monthlyPlans);
                setTrialEndDate(data.plans.trialEndDate);
                setInitialBillingDate(data.plans.initialBillingDate);

                if (currentPlan) {
                    if (currentPlan.billingCycle === 'Yearly') {
                        setBillingCycleInterval('Yearly');
                        setPlans(yearlyPlans);
                        setBillingCycleCheck(true);
                    }
                }
                else {
                    if (autoSubscribeTestMode) {
                        // auto subscribe to the Agency Plan
                        console.log('yearlyPlans[3]', yearlyPlans[3])
                        setSelectedPlan(yearlyPlans[3]);
                        await handleSkip(yearlyPlans[3]);
                    }
                }


            } else {
                alert(data.message);
            }
        } else {
            alert('An error occurred, Please try again');
        }
    }

    const onSelectPlan = (plan) => {
        setSelectedPlan(plan);
        setStep(STEP_SUBSCRIBE);
    }

    const changePlan =async (plan) => {

        setIsPlansLoading(true);
        const response = await SubscriptionService.updateSubscription(
            plan.uid,
            team?.uid,
            user?.token
        );

        // @ts-ignore
        const resJson = await response.json();


        const {data, message} = resJson;
        setIsPlansLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setCurrentPlan(plan);
                StorageService.savePlan(plan);
                StorageService.saveSubscription(data.subscription);
                StorageService.savePlanFeatures(data.planFeatures);
                // navigate('/subscription', {replace: true});
                navigate(-1);

            } else {
                alert(data.message);
            }
        } else {
            alert(message || 'An error occurred. Please try again');
        }
    }

    const promptToChangePlan = (plan) => {
        Swal.fire({
            title: t('app.changePlanPromptTitle'),
            text: `${t('app.changePlanPromptMessage')} ${t(plan.nameKey)} ${t(plan.billingCycleKey)}? ${t('app.newPlanStartsImmediately')}`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t('app.subscribeLabel'),
            cancelButtonText: t('app.cancelLabel'),
            reverseButtons: true,
            confirmButtonColor: 'error',
        }).then(async (result) => {
            if (result.value) {
                await changePlan(plan);
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                // Do Nothing
            }
        });
    }

    const back = () => {
        setSelectedPlan(null);
        setStep(STEP_SELECT_PLAN);
    }

    const handleSkip = async (autoSelectedTestPlan) => {

        setIsLoadingSkipPayment(true);

        const response = await SubscriptionService.createSubscription(
            autoSelectedTestPlan? autoSelectedTestPlan.uid : selectedPlan.uid,
            true,
            user?.token
        );

        // @ts-ignore
        const resJson = await response.json();
        // alert(JSON.stringify(resJson));


        const {data} = resJson;
        setIsLoadingSkipPayment(false);
        // @ts-ignore
        if (response.ok) {
            if (data) {

                StorageService.saveAccounts(data.accounts);
                StorageService.saveSelectedAccount(data.selectedAccount);
                StorageService.saveAccountInvitations(data.accountInvitations);
                StorageService.saveHasSubscription(data.hasSubscription);

                // Save the new account as the selected account

                StorageService.saveIsAccountOwner(data.selectedAccount.isAccountOwner);
                StorageService.saveProjects(data.selectedAccount.projects);
                StorageService.saveSelectedBusiness(data.selectedAccount.business);
                StorageService.saveSelectedProject(data.selectedAccount.selectedProject);
                StorageService.saveTeam(data.selectedAccount.team);
                StorageService.saveSubscription(data.selectedAccount.subscription);
                StorageService.savePlan(data.selectedAccount.plan);
                StorageService.savePlanFeatures(data.selectedAccount.planFeatures);
                navigate('/', {replace: true});

            } else {
                alert(data.message);
            }
        } else {
            alert('An error occurred, Please try again');
        }
    }

    const onBillingIntervalChange = (e) => {
        const billingInterval = e.target.checked === true ? 'Yearly' : 'Monthly';
        if (billingInterval === 'Yearly') {
            setPlans(yearlyPlans);
            setBillingCycleCheck(true);
        }
        else if (billingInterval === 'Monthly') {
            setPlans(monthlyPlans);
            setBillingCycleCheck(false);
        }
    }

    const handleSubscription = async (event) => {
        event.preventDefault();
        if (!stripe) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            alert('Payment gateway not ready. Please try again');
            return;
        }

        setIsSubscriptionLoading(true);
        // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit();
        if (submitError) {
            alert(submitError.message);
            return;
        }
        // Create a payment method using the card element
        /*
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            elements
        });

        if (error) {
            alert(error.message);
            return;
        }


        console.log('paymentMethod', paymentMethod);

        */


        const response = await SubscriptionService.createSubscription(
            selectedPlan.uid,
            false,
            user?.token
        );

        // @ts-ignore
        const resJson = await response.json();
        // alert(JSON.stringify(resJson));


        const {data} = resJson;
        setIsSubscriptionLoading(false);
        // @ts-ignore
        if (response.ok) {
            if (data) {

                StorageService.saveAccounts(data.accounts);
                StorageService.saveSelectedAccount(data.selectedAccount);
                StorageService.saveAccountInvitations(data.accountInvitations);
                StorageService.saveHasSubscription(data.hasSubscription);

                // Save the new account as the selected account

                StorageService.saveIsAccountOwner(data.selectedAccount.isAccountOwner);
                StorageService.saveProjects(data.selectedAccount.projects);
                StorageService.saveSelectedBusiness(data.selectedAccount.business);
                StorageService.saveSelectedProject(data.selectedAccount.selectedProject);
                StorageService.saveTeam(data.selectedAccount.team);
                StorageService.saveSubscription(data.selectedAccount.subscription);
                StorageService.savePlan(data.selectedAccount.plan);
                StorageService.savePlanFeatures(data.selectedAccount.planFeatures);


                const {error} = await stripe.confirmSetup({
                    elements, clientSecret: data?.stripeClientSecret,
                    confirmParams: {
                        return_url: `${window.location.origin}` ,
                        //window.location : localhost://3000/plans , current page
                        //window.location.origin : localhost://3000 , root page
                    }
                });

                if (error) {
                    // This point is only reached if there's an immediate error when
                    // confirming the payment. Show the error to your customer (for example, payment details incomplete)
                    alert("An error occurred: " + error.message);
                } else {
                    // Your customer is redirected to your `return_url`. For some payment
                    // methods like iDEAL, your customer is redirected to an intermediate
                    // site first to authorize the payment, then redirected to the `return_url`.
                    // alert('Payment successful');
                }
            } else {
                alert(data.message);
            }
        } else {
            alert('An error occurred, Please try again');
        }

    };


    const handleBillingCycleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setBillingCycleInterval(newAlignment);

            if (newAlignment === 'Yearly') {
                setPlans(yearlyPlans);
                setBillingCycleCheck(true);
            }
            else if (newAlignment === 'Monthly') {
                setPlans(monthlyPlans);
                setBillingCycleCheck(false);
            }
        }
    };

    return (
        <React.Fragment>

            <Div sx={{
                margin:10,
                // flex: 1,
                // flexWrap: 'wrap',
                // display: 'flex',
                // flexDirection: 'column',
                alignItems: 'center',
                // justifyContent: 'center',
                // p: theme => theme.spacing(4),
            }}>
                {step === STEP_SELECT_PLAN && (
                    <>
                        {isPlansLoading && (
                            <Box>
                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

                                <Grid container spacing={3.75} mt={-3} mb={3}>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <Skeleton variant="rectangular" width={210} height={600} />

                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <Skeleton variant="rectangular" width={210} height={600} />

                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <Skeleton variant="rectangular" width={210} height={600} />

                                    </Grid>

                                </Grid>

                            </Box>
                        )}
                        {(plans && !isPlansLoading) && (
                            <Box>
                                <Typography variant={'h1'} mb={2}>{t("app.selectAPlanLabel")}</Typography>


                                <ToggleButtonGroup
                                    color="primary"
                                    value={billingCycleInterval}
                                    exclusive
                                    onChange={handleBillingCycleChange}
                                    aria-label="Platform"
                                >

                                    <ToggleButton value="Monthly">{t('app.monthlyBillingLabel')}</ToggleButton>
                                    <ToggleButton value="Yearly">{t('app.yearlyBillingLabel')}</ToggleButton>
                                </ToggleButtonGroup>

                                <Grid container spacing={3.75} mt={1} mb={3}>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <Card>
                                            <CardContent sx={{height:700}}>
                                                <Typography
                                                    variant={'h1'}
                                                    fontSize={42}
                                                    fontWeight={500}
                                                    color={'primary'}
                                                    gutterBottom>
                                                    {t(plans[0].nameKey)}

                                                    {(currentPlan && currentPlan.uid === plans[0].uid) && (
                                                        <Chip
                                                            sx={{marginLeft: 1}}
                                                            size={'small'}
                                                            label={t('app.currentPlanLabel')}
                                                            color={'primary'}
                                                        />
                                                    )}

                                                </Typography>
                                                <Typography variant="h5" component="div">
                                                    {t(plans[0].descriptionKey)}
                                                </Typography>
                                                <Typography
                                                    variant="h1"
                                                    sx={{ fontSize:36 }}
                                                    fontWeight={500}
                                                    color="primary">
                                                    <Span sx={{
                                                        fontSize: '50%',
                                                        verticalAlign: 'super',
                                                        fontWeight: '500',
                                                    }}
                                                    >€</Span>
                                                    {plans[0].pricePerMonth}
                                                    <Span sx={{
                                                        fontSize: '50%',
                                                        fontWeight: '500',
                                                        mr: .5
                                                    }}
                                                    >
                                                        /mo
                                                    </Span>
                                                </Typography>
                                                <Typography variant="body2">
                                                    {t(plans[0].billingCycleDescriptionKey)}
                                                </Typography>
                                                <Typography variant="body1" color={'text.success'}>
                                                    {t(plans[0].amountSavedDescriptionKey)}
                                                </Typography>

                                                <Box sx={{marginTop:3}}>


                                                    {(currentPlan && currentPlan.uid !== plans[0].uid) && (
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant="outlined"
                                                            onClick={() => promptToChangePlan(plans[0])}
                                                        >
                                                            {t('app.selectPlanLabel')}
                                                        </Button>
                                                    )}

                                                    {!currentPlan && (
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant="outlined"
                                                            onClick={() => onSelectPlan(plans[0])}
                                                        >
                                                            {t('app.start14DayFreeTrial')}
                                                        </Button>
                                                    )}
                                                </Box>

                                                <Box sx={{marginTop:3}}>
                                                    <List
                                                        sx={{ width: '100%', maxWidth: 360, marginLeft: -1.5 }}
                                                        subheader={<Typography sx={{marginLeft: 2}} variant="subtitle2">{t(plans[0].includesDescriptionKey)}</Typography>}
                                                    >

                                                        {plans[0].featuresKey.map(featureKey => (
                                                            <ListItem>
                                                                <ListItemIcon>
                                                                    <CheckIcon />
                                                                </ListItemIcon>
                                                                <ListItemText id="switch-list-label-wifi" primary={t(featureKey.trim())} />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <Card>
                                            <CardContent sx={{height:700}}>
                                                <Typography
                                                    variant={'h1'}
                                                    fontSize={42}
                                                    fontWeight={500}
                                                    color={'secondary'}
                                                    gutterBottom>
                                                    {t(plans[1].nameKey)}
                                                    {(currentPlan && currentPlan.uid === plans[1].uid) && (
                                                        <Chip
                                                            sx={{marginLeft: 1}}
                                                            size={'small'}
                                                            label={t('app.currentPlanLabel')}
                                                            color={'primary'}
                                                        />
                                                    )}
                                                </Typography>
                                                <Typography variant="h5" component="div">
                                                    {t(plans[1].descriptionKey)}
                                                </Typography>
                                                <Typography
                                                    variant="h1"
                                                    sx={{ fontSize:36 }}
                                                    fontWeight={500}
                                                    color="secondary">
                                                    <Span sx={{
                                                        fontSize: '50%',
                                                        verticalAlign: 'super',
                                                        fontWeight: '500',
                                                    }}
                                                    >€</Span>
                                                    {plans[1].pricePerMonth}
                                                    <Span sx={{
                                                        fontSize: '50%',
                                                        fontWeight: '500',
                                                        mr: .5
                                                    }}
                                                    >
                                                        /mo
                                                    </Span>
                                                </Typography>
                                                <Typography variant="body2">
                                                    {t(plans[1].billingCycleDescriptionKey)}
                                                </Typography>
                                                <Typography variant="body1" color={'text.success'}>
                                                    {t(plans[1].amountSavedDescriptionKey)}
                                                </Typography>

                                                <Box sx={{marginTop:3}}>


                                                    {(currentPlan && currentPlan.uid !== plans[1].uid) && (
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant="outlined"
                                                            color={'secondary'}
                                                            onClick={() => promptToChangePlan(plans[1])}
                                                        >
                                                            {t('app.selectPlanLabel')}
                                                        </Button>
                                                    )}

                                                    {!currentPlan && (
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant="outlined"
                                                            color={'secondary'}
                                                            onClick={() => onSelectPlan(plans[1])}
                                                        >
                                                            {t('app.start14DayFreeTrial')}
                                                        </Button>
                                                    )}

                                                </Box>

                                                <Box sx={{marginTop:3}}>
                                                    <List
                                                        sx={{ width: '100%', maxWidth: 360, marginLeft: -1.5 }}
                                                        subheader={<Typography sx={{marginLeft: 2}} variant="subtitle2">{t(plans[1].includesDescriptionKey)}</Typography>}
                                                    >

                                                        {plans[1].featuresKey.map(featureKey => (
                                                            <ListItem>
                                                                <ListItemIcon>
                                                                    <CheckIcon />
                                                                </ListItemIcon>
                                                                <ListItemText id="switch-list-label-wifi" primary={t(featureKey.trim())} />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Box>
                                            </CardContent>

                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <Card>
                                            <CardContent sx={{height:700}}>
                                                <Typography
                                                    variant={'h1'}
                                                    fontSize={42}
                                                    fontWeight={500}
                                                    sx={{color: '#2EB5C9'}}
                                                    gutterBottom>
                                                    {t(plans[2].nameKey)}
                                                    {(currentPlan && currentPlan.uid === plans[2].uid) && (
                                                        <Chip
                                                            sx={{marginLeft: 1}}
                                                            size={'small'}
                                                            label={t('app.currentPlanLabel')}
                                                            color={'primary'}
                                                        />
                                                    )}
                                                </Typography>
                                                <Typography variant="h5" component="div">
                                                    {t(plans[2].descriptionKey)}
                                                </Typography>
                                                <Typography
                                                    variant="h1"
                                                    sx={{ fontSize:36, color: '#2EB5C9' }}
                                                    fontWeight={500}
                                                >
                                                    <Span sx={{
                                                        fontSize: '50%',
                                                        verticalAlign: 'super',
                                                        fontWeight: '500',
                                                    }}
                                                    >€</Span>
                                                    {plans[2].pricePerMonth}
                                                    <Span sx={{
                                                        fontSize: '50%',
                                                        fontWeight: '500',
                                                        mr: .5
                                                    }}
                                                    >
                                                        /mo
                                                    </Span>
                                                </Typography>
                                                <Typography variant="body2">
                                                    {t(plans[2].billingCycleDescriptionKey)}
                                                </Typography>
                                                <Typography variant="body1" color={'text.success'}>
                                                    {t(plans[2].amountSavedDescriptionKey)}
                                                </Typography>

                                                <Box sx={{marginTop:3}}>

                                                    {(currentPlan && currentPlan.uid !== plans[2].uid) && (
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant="outlined"
                                                            color={'info'}
                                                            onClick={() => promptToChangePlan(plans[2])}
                                                        >
                                                            {t('app.selectPlanLabel')}
                                                        </Button>
                                                    )}

                                                    {!currentPlan && (
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant="outlined"
                                                            color={'info'}
                                                            onClick={() => onSelectPlan(plans[2])}
                                                        >
                                                            {t('app.start14DayFreeTrial')}
                                                        </Button>
                                                    )}

                                                </Box>

                                                <Box sx={{marginTop:3}}>
                                                    <List
                                                        sx={{ width: '100%', maxWidth: 360, marginLeft: -1.5 }}
                                                        subheader={<Typography sx={{marginLeft: 2}} variant="subtitle2">{t(plans[2].includesDescriptionKey)}</Typography>}
                                                    >

                                                        {plans[2].featuresKey.map(featureKey => (
                                                            <ListItem>
                                                                <ListItemIcon>
                                                                    <CheckIcon />
                                                                </ListItemIcon>
                                                                <ListItemText id="switch-list-label-wifi" primary={t(featureKey.trim())} />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Box>
                                            </CardContent>

                                        </Card>
                                    </Grid>

                                    <Grid item xs={12} sm={6} lg={4}>
                                        <Card>
                                            <CardContent sx={{height:700}}>
                                                <Typography
                                                    variant={'h1'}
                                                    fontSize={42}
                                                    fontWeight={500}
                                                    color={'primary'}
                                                    gutterBottom>
                                                    {t(plans[3].nameKey)}

                                                    {(currentPlan && currentPlan.uid === plans[3].uid) && (
                                                        <Chip
                                                            sx={{marginLeft: 1}}
                                                            size={'small'}
                                                            label={t('app.currentPlanLabel')}
                                                            color={'primary'}
                                                        />
                                                    )}

                                                </Typography>
                                                <Typography variant="h5" component="div">
                                                    {t(plans[3].descriptionKey)}
                                                </Typography>
                                                <Typography
                                                    variant="h1"
                                                    sx={{ fontSize:36 }}
                                                    fontWeight={500}
                                                    color="primary">
                                                    <Span sx={{
                                                        fontSize: '50%',
                                                        verticalAlign: 'super',
                                                        fontWeight: '500',
                                                    }}
                                                    >€</Span>
                                                    {plans[3].pricePerMonth}
                                                    <Span sx={{
                                                        fontSize: '50%',
                                                        fontWeight: '500',
                                                        mr: .5
                                                    }}
                                                    >
                                                        /mo
                                                    </Span>
                                                </Typography>
                                                <Typography variant="body2">
                                                    {t(plans[3].billingCycleDescriptionKey)}
                                                </Typography>
                                                <Typography variant="body1" color={'text.success'}>
                                                    {t(plans[3].amountSavedDescriptionKey)}
                                                </Typography>

                                                <Box sx={{marginTop:3}}>


                                                    {(currentPlan && currentPlan.uid !== plans[3].uid) && (
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant="outlined"
                                                            onClick={() => promptToChangePlan(plans[3])}
                                                        >
                                                            {t('app.selectPlanLabel')}
                                                        </Button>
                                                    )}

                                                    {!currentPlan && (
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant="outlined"
                                                            onClick={() => onSelectPlan(plans[3])}
                                                        >
                                                            {t('app.start14DayFreeTrial')}
                                                        </Button>
                                                    )}
                                                </Box>

                                                <Box sx={{marginTop:3}}>
                                                    <List
                                                        sx={{ width: '100%', maxWidth: 360, marginLeft: -1.5 }}
                                                        subheader={<Typography sx={{marginLeft: 2}} variant="subtitle2">{t(plans[3].includesDescriptionKey)}</Typography>}
                                                    >

                                                        {plans[3].featuresKey.map(featureKey => (
                                                            <ListItem>
                                                                <ListItemIcon>
                                                                    <CheckIcon />
                                                                </ListItemIcon>
                                                                <ListItemText id="switch-list-label-wifi" primary={t(featureKey.trim())} />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <Card>
                                            <CardContent sx={{height:700}}>
                                                <Typography
                                                    variant={'h1'}
                                                    fontSize={42}
                                                    fontWeight={500}
                                                    color={'secondary'}
                                                    gutterBottom>
                                                    {t(plans[4].nameKey)}
                                                    {(currentPlan && currentPlan.uid === plans[4].uid) && (
                                                        <Chip
                                                            sx={{marginLeft: 1}}
                                                            size={'small'}
                                                            label={t('app.currentPlanLabel')}
                                                            color={'primary'}
                                                        />
                                                    )}
                                                </Typography>
                                                <Typography variant="h5" component="div">
                                                    {t(plans[4].descriptionKey)}
                                                </Typography>
                                                <Typography
                                                    variant="h1"
                                                    sx={{ fontSize:36 }}
                                                    fontWeight={500}
                                                    color="secondary">
                                                    <Span sx={{
                                                        fontSize: '50%',
                                                        verticalAlign: 'super',
                                                        fontWeight: '500',
                                                    }}
                                                    >€</Span>
                                                    {plans[4].pricePerMonth}
                                                    <Span sx={{
                                                        fontSize: '50%',
                                                        fontWeight: '500',
                                                        mr: .5
                                                    }}
                                                    >
                                                        /mo
                                                    </Span>
                                                </Typography>
                                                <Typography variant="body2">
                                                    {t(plans[4].billingCycleDescriptionKey)}
                                                </Typography>
                                                <Typography variant="body1" color={'text.success'}>
                                                    {t(plans[4].amountSavedDescriptionKey)}
                                                </Typography>

                                                <Box sx={{marginTop:3}}>


                                                    {(currentPlan && currentPlan.uid !== plans[4].uid) && (
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant="outlined"
                                                            color={'secondary'}
                                                            onClick={() => promptToChangePlan(plans[4])}
                                                        >
                                                            {t('app.selectPlanLabel')}
                                                        </Button>
                                                    )}

                                                    {!currentPlan && (
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant="outlined"
                                                            color={'secondary'}
                                                            onClick={() => onSelectPlan(plans[4])}
                                                        >
                                                            {t('app.start14DayFreeTrial')}
                                                        </Button>
                                                    )}

                                                </Box>

                                                <Box sx={{marginTop:3}}>
                                                    <List
                                                        sx={{ width: '100%', maxWidth: 360, marginLeft: -1.5 }}
                                                        subheader={<Typography sx={{marginLeft: 2}} variant="subtitle2">{t(plans[4].includesDescriptionKey)}</Typography>}
                                                    >

                                                        {plans[4].featuresKey.map(featureKey => (
                                                            <ListItem>
                                                                <ListItemIcon>
                                                                    <CheckIcon />
                                                                </ListItemIcon>
                                                                <ListItemText id="switch-list-label-wifi" primary={t(featureKey.trim())} />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Box>
                                            </CardContent>

                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <Card>
                                            <CardContent sx={{height:700}}>
                                                <Typography
                                                    variant={'h1'}
                                                    fontSize={42}
                                                    fontWeight={500}
                                                    sx={{color: '#2EB5C9'}}
                                                    gutterBottom>
                                                    {t(plans[5].nameKey)}
                                                    {(currentPlan && currentPlan.uid === plans[5].uid) && (
                                                        <Chip
                                                            sx={{marginLeft: 1}}
                                                            size={'small'}
                                                            label={t('app.currentPlanLabel')}
                                                            color={'primary'}
                                                        />
                                                    )}
                                                </Typography>
                                                <Typography variant="h5" component="div">
                                                    {t(plans[5].descriptionKey)}
                                                </Typography>
                                                <Typography
                                                    variant="h1"
                                                    sx={{ fontSize:36, color: '#2EB5C9' }}
                                                    fontWeight={500}
                                                >
                                                    <Span sx={{
                                                        fontSize: '50%',
                                                        verticalAlign: 'super',
                                                        fontWeight: '500',
                                                    }}
                                                    >€</Span>
                                                    {plans[5].pricePerMonth}
                                                    <Span sx={{
                                                        fontSize: '50%',
                                                        fontWeight: '500',
                                                        mr: .5
                                                    }}
                                                    >
                                                        /mo
                                                    </Span>
                                                </Typography>
                                                <Typography variant="body2">
                                                    {t(plans[5].billingCycleDescriptionKey)}
                                                </Typography>
                                                <Typography variant="body1" color={'text.success'}>
                                                    {t(plans[5].amountSavedDescriptionKey)}
                                                </Typography>

                                                <Box sx={{marginTop:3}}>

                                                    {(currentPlan && currentPlan.uid !== plans[5].uid) && (
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant="outlined"
                                                            color={'info'}
                                                            onClick={() => promptToChangePlan(plans[5])}
                                                        >
                                                            {t('app.selectPlanLabel')}
                                                        </Button>
                                                    )}

                                                    {!currentPlan && (
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant="outlined"
                                                            color={'info'}
                                                            onClick={() => onSelectPlan(plans[5])}
                                                        >
                                                            {t('app.start14DayFreeTrial')}
                                                        </Button>
                                                    )}

                                                </Box>

                                                <Box sx={{marginTop:3}}>
                                                    <List
                                                        sx={{ width: '100%', maxWidth: 360, marginLeft: -1.5 }}
                                                        subheader={<Typography sx={{marginLeft: 2}} variant="subtitle2">{t(plans[5].includesDescriptionKey)}</Typography>}
                                                    >

                                                        {plans[5].featuresKey.map(featureKey => (
                                                            <ListItem>
                                                                <ListItemIcon>
                                                                    <CheckIcon />
                                                                </ListItemIcon>
                                                                <ListItemText id="switch-list-label-wifi" primary={t(featureKey.trim())} />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Box>
                                            </CardContent>

                                        </Card>
                                    </Grid>


                                </Grid>

                            </Box>

                        )}

                    </>
                )}

                {step === STEP_SUBSCRIBE && (
                    <Box>
                        <Grid container spacing={3.75} mt={-3} mb={3}>
                            <Grid item xs={12} sm={6} lg={4}>
                                <Card>
                                    <CardContent>
                                        <Button
                                            onClick={() => back()}
                                            size={"small"}
                                            sx={{marginBottom: 3}}
                                            startIcon={<ArrowBackIcon />}
                                        >
                                            {t("app.backButtonLabel")}
                                        </Button>
                                        <Typography
                                            variant={'subtitle2'}
                                        >
                                            {t("app.subscribeToLabel")} {t('app.name')} {t(selectedPlan.nameKey)} - {t(selectedPlan.billingCycleKey)}
                                        </Typography>
                                        <Typography
                                            variant={'subtitle1'}
                                            mb={2}
                                            sx={{fontSize:14}}
                                            color={'text.secondary'}
                                        >
                                            {t('app.freeTrialEndsOnLabel')} {trialEndDate}
                                        </Typography>
                                        <Typography
                                            variant="h1"
                                            sx={{ fontSize:36 }}
                                            fontWeight={500}
                                            mb={0}
                                        >
                                            <Span sx={{
                                                fontSize: '50%',
                                                verticalAlign: 'super',
                                                fontWeight: '500',
                                            }}
                                            >€</Span>{selectedPlan.billingPrice}<Span sx={{
                                            fontSize: '50%',
                                            fontWeight: '500',
                                            mr: .5
                                        }}
                                        >/{selectedPlan.billingCycleAbbreviation}</Span>
                                        </Typography>
                                        <Typography
                                            variant={'subtitle1'}
                                            sx={{fontSize:14}}
                                            color={'text.success'}
                                        >
                                            {t(selectedPlan.amountSavedDescriptionKey)}
                                        </Typography>
                                        <Typography
                                            variant={'subtitle1'}
                                            mb={2}
                                            sx={{fontSize:14}}
                                            color={'text.secondary'}
                                        >
                                            {t('app.initialBillingLabel')} {initialBillingDate}
                                        </Typography>

                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={8}>
                                <Card>
                                    <CardContent>
                                            <form onSubmit={handleSubscription}>
                                                <PaymentElement />
                                                <AddressElement options={{mode: "billing"}} />
                                                <Box sx={{marginTop:3}}>
                                                    <LoadingButton
                                                        size="small"
                                                        fullWidth
                                                        variant="outlined"
                                                        type="submit"
                                                        loading={isSubscriptionLoading}
                                                    >
                                                        {t('app.subscribeLabel')}
                                                    </LoadingButton>
                                                </Box>

                                            </form>
                                    </CardContent>
                                </Card>


                                <Div sx={{
                                    flex: 1,
                                    flexWrap: 'wrap',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    p: theme => theme.spacing(4),
                                }}>
                                    <LoadingButton loading={isLoadingSkipPayment}  variant="text" onClick={handleSkip}>
                                        {t('app.skipSubscriptionPayment')}
                                    </LoadingButton>
                                </Div>

                            </Grid>
                        </Grid>

                    </Box>
                )}



            </Div>
        </React.Fragment>
        );
}

export default Plans;