import React from "react";
import Login2 from "../pages/auth-pages/login2";
import Signup2 from "../pages/auth-pages/signup2";
import ForgotPassword from "../pages/auth-pages/forgot-password";
import ResetPassword from "../pages/auth-pages/reset-password";
import Page from "@jumbo/shared/Page";
import AcceptInvite from "../pages/accept-invite";

const authRoutes = [
    {
        path: "/login",
        element: <Page component={Login2} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/login/:locale",
        element: <Page component={Login2} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/signup",
        element: <Page component={Signup2} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/signup/:locale",
        element: <Page component={Signup2} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/forgot-password",
        element: <Page component={ForgotPassword} layout={"solo-page"} />
    },
    {
        path: "/reset-password/:resetUid/:locale",
        element: <Page component={ResetPassword} layout={"solo-page"} />
    },
    // {
    //     path: "/invites",
    //     element: <Page component={AcceptInvite} layout={"solo-page"} />
    // },
    // {
    //     path: "/invites/:uid",
    //     element: <Page component={AcceptInvite} layout={"solo-page"} />
    // },
    {
        path: "/invites/:inviteUid/:inviteLocale",
        element: <Page component={AcceptInvite} layout={"solo-page"} />
    }
];

export default authRoutes;
