import {APIService} from './APIService';

export class SuggestionService extends APIService {
  static async suggestToneOfVoices(
      resultLanguage,
      businessName,
      businessDescription,
      industry,
      targetAudience,
    accessToken,
  ) {
    return await this.makeRequest(
      'api/suggest/tone-of-voices',
      {
          resultLanguage,
          businessName,
          businessDescription,
          industry,
          targetAudience,
          accessToken,
      },
      {
        'x-access-token': accessToken,
        'Content-Type': 'application/json',
      },
    );
  }

    static async suggestWritingStyles(
        resultLanguage,
        businessName,
        businessDescription,
        industry,
        targetAudience,
        tone,
        accessToken,
    ) {
        return await this.makeRequest(
            'api/suggest/writing-styles',
            {
                resultLanguage,
                businessName,
                businessDescription,
                industry,
                targetAudience,
                tone,
                accessToken,
            },
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
        );
    }

    static async suggestGeneric(
        resultLanguage,
        basePrompt,
        businessUid,
        targetAudience,
        accessToken,
    ) {
        return await this.makeRequest(
            'api/suggest',
            {
                resultLanguage,
                basePrompt,
                businessUid,
                targetAudience,
                accessToken,
            },
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
        );
    }

}
