import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    Box, Card, CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    InputLabel,
    Select,
    Typography
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {CampaignService} from "../../services/CampaignService";
import {StorageService} from "../../services/StorageService";
import {Skeleton} from "@mui/lab";
import SavedContentItemCard from "../saved-content/SavedContentItemCard";
import {CreatorService} from "../../services/CreatorService";
import LoadingButton from "@mui/lab/LoadingButton";
import PostAddIcon from '@mui/icons-material/PostAdd';
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

const Campaign = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams();
    const user = StorageService.loadUser();
    const team = StorageService.loadTeam();
    const plan = StorageService.loadPlan();
    const planFeatures = StorageService.loadPlanFeatures();
    const business = StorageService.loadSelectedBusiness();
    const project = StorageService.loadSelectedProject();
    const isAccountOwner = StorageService.loadIsAccountOwner();

    const [isLoading, setIsLoading] = useState(false);
    const [campaign, setCampaign] = useState();
    const [campaignCopies, setCampaignCopies] = useState();

    const [selectedCampaignContent, setSelectedCampaignContent] = useState(null);
    const [contentCategories, setContentCategories] = useState(StorageService.loadContentCategories());
    const [isAddContentDialogOpen, setIsAddContentDialogOpen] = useState(false);
    const [isAddingContent, setIsAddingContent] = useState(false);
    const [selectedContentFeatureToAdd, setSelectedContentFeatureToAdd] = useState('');
    const [isCampaignDetailsDialogOpen, setIsCampaignDetailsDialogOpen] = useState(false);

    const handleCampaignDetailsDialogClose = () => {
        setIsCampaignDetailsDialogOpen(false);
    }

    const handleAddContentDialogClose = () => {
        setIsAddContentDialogOpen(false);
    }

    const openAddContentDialog = () => {
        setIsAddContentDialogOpen(true);
    };

    const addContent = async () => {

        if(!selectedContentFeatureToAdd) {
            alert('Select a content to add');
            return;
        }

        setIsAddingContent(true);
        handleAddContentDialogClose();

        const templateId = campaign?.campaignTemplate;
        const locale = campaign?.locale;

        const response = await CampaignService.createCampaign(
            campaign?.uid, campaign?.title, campaign?.tone, campaign?.writingStyle,
            campaign?.brandVoiceUid, campaign?.campaignDetails,
            locale, templateId, [selectedContentFeatureToAdd],
            team.uid,business?.uid, project.uid,
            user?.token);

        const resJson = await response.json();



        const {data, message} = resJson;
        setIsAddingContent(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
               await getCampaign();

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const upgradePlan = () => {
        navigate('/plans', { state: plan })
    }

    useEffect( async () => {
        await getCampaign();
    }, []);

    const getCampaign = async () => {
        setIsLoading(true);

        const response =  await CampaignService.getCampaign(id, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setCampaign(data.campaign);
                setCampaignCopies(data.copies);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    };

    const onCampaignCopyClick = (content) => {
        setSelectedCampaignContent(content);
    }

    const onEditContentClick = () => {
        navigate('/edit-content', { state: selectedCampaignContent })
    }

    const removeContentFromList = () => {

        const contentList = campaignCopies;
        const selectedContentIndex = contentList.findIndex((content) => {
            return content.uid === selectedCampaignContent.uid;
        });


        contentList.splice(selectedContentIndex, 1);
        setCampaignCopies(contentList);
        setSelectedCampaignContent(null);

        setIsLoading(true);
        setTimeout(() => setIsLoading(false), 300);

    }

    const deleteCopy = async () => {
        const response =  await CreatorService.deleteCopy(selectedCampaignContent.uid, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;

        // @ts-ignore
        if (response.ok) {
            if (data && data.status === 'success') {
                removeContentFromList();
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    };

    return (
        <React.Fragment>
            <Typography variant={'h6'} color={'text.secondary'} >
                {t('app.campaignsTitleLabel')} /
            </Typography>
            <Typography variant={'h1'} >
                {campaign?.title}
                <Button sx={{marginLeft: 1}}
                        variant={'outlined'}
                        size={'small'}
                        onClick={() => setIsCampaignDetailsDialogOpen(true)}
                >
                    {t('app.viewCampaignDetailsButtonLabel')}
                </Button>
            </Typography>

            <Divider/>

            <Box mt={5}>
                <Box mb={1} sx={{ justifyContent: 'right',  display: 'flex'  }}>
                    <LoadingButton
                        loading={isAddingContent}
                        onClick={openAddContentDialog}
                        color={'primary'}
                        size={"small"}
                        variant={'outlined'}
                        startIcon={<PostAddIcon />}
                    >
                        {t("app.addContentButtonLabel")}
                    </LoadingButton>
                </Box>

                {
                    isLoading && (
                        <>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </>
                    )
                }

                {(!isLoading && campaignCopies) && (
                    <>
                        {campaignCopies.map((content, i) => (
                            <Box onClick={() => {onCampaignCopyClick(content)}}>
                                <SavedContentItemCard
                                    content={content}
                                    contentList={campaignCopies}
                                    onEdit={onEditContentClick}
                                    onDelete={deleteCopy}
                                    canDelete={isAccountOwner}
                                />
                            </Box>
                        ))}

                        {
                            isAddingContent && (
                                <>
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                </>
                            )
                        }

                        {campaignCopies?.length < 1 && !isAddingContent && (
                            <Box>
                                <Card>
                                    <CardContent>
                                        <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                            <Typography variant={'h4'}>
                                                {t('app.noCampaignContentLabel')}
                                            </Typography>
                                        </Box>


                                        <Box mt={3} sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                            <Button
                                                onClick={openAddContentDialog}
                                                size={'small'}
                                                variant={"outlined"}>{t('app.addContentButtonLabel')}</Button>

                                        </Box>
                                    </CardContent>
                                </Card>
                            </Box>
                        )}
                    </>
                )}
            </Box>


            <Dialog fullWidth={true} maxWidth={'sm'} disableEscapeKeyDown open={isAddContentDialogOpen} onClose={handleAddContentDialogClose}>
                <DialogTitle>{t('app.addContentButtonLabel')}</DialogTitle>
                <DialogContent>
                    <Box>

                        <InputLabel htmlFor="select-content">Select Content</InputLabel>
                        <Select
                            labelId="select-content"
                            fullWidth
                            value={selectedContentFeatureToAdd}
                            label={'Select Content'}
                            onChange={(e) => {
                                setSelectedContentFeatureToAdd(e.target.value);
                            }}
                        >
                            {contentCategories.map((content, index) => (
                                    content.hasCategories ? (
                                        // Render MenuItems for categories if they exist
                                        content.categories.map((category, i) => (
                                            <MenuItem key={i} value={category.pageConfig.feature}>
                                                {t(category.titleKey)}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        // Render a MenuItem if no categories
                                        <MenuItem value={content.pageConfig.feature}>{t(content.titleKey)}</MenuItem>
                                    )
                            ))}
                        </Select>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddContentDialogClose}>{t('app.cancelButtonLabel')}</Button>
                    {!planFeatures?.canAccessCampaigns && (
                        <Button color={'error'} onClick={upgradePlan}>{t('app.upgradePlanToAddContentLabel')}</Button>

                    )}
                    {planFeatures?.canAccessCampaigns && (
                        <LoadingButton loading={isAddingContent} onClick={addContent}>{t('app.addContentButtonLabel')}</LoadingButton>
                    )}
                </DialogActions>
            </Dialog>

            <Dialog fullWidth={true} maxWidth={'sm'} disableEscapeKeyDown open={isCampaignDetailsDialogOpen} onClose={handleCampaignDetailsDialogClose}>
                <DialogTitle>{campaign?.title}</DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography variant={'body1'}>
                            {campaign?.campaignDetails}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCampaignDetailsDialogClose}>{t('app.closeButtonLabel')}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Campaign;