import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent, Dialog, DialogActions, DialogContent,
    Drawer,
    Grid, IconButton, InputAdornment,
    InputLabel, Select, Snackbar,
    TextField,
    Typography
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import {StorageService} from "../../services/StorageService";
import {CreatorService} from "../../services/CreatorService";
import {Skeleton} from "@mui/lab";
import Div from "@jumbo/shared/Div";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import {SuggestionService} from "../../services/SuggestionService";
import DialogTitle from "@mui/material/DialogTitle";
import {TranslationService} from "../../services/TranslationService";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TranslateIcon from '@mui/icons-material/Translate';
import EditIcon from '@mui/icons-material/Edit';
import ReplayIcon from '@mui/icons-material/Replay';
import SaveIcon from '@mui/icons-material/Save';
import Alert from '@mui/material/Alert';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";


const Create = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const copyType = location.state;

    const user = StorageService.loadUser();
    const selectedBusiness = StorageService.loadSelectedBusiness();
    const team = StorageService.loadTeam();
    const contentLanguages =  StorageService.loadSupportedContentLanguages();
    const plan = StorageService.loadPlan();
    const planFeatures = StorageService.loadPlanFeatures();
    const brandVoices = StorageService.loadBrandVoices();

    const preferredContentLanguageLocale =  selectedBusiness ? selectedBusiness.preferredContentLanguage : user.locale;

    const [isCustomTone, setIsCustomTone] = useState(false);
    const [customTone, setCustomTone] = useState('');
    const [targetAudience, setTargetAudience] = useState(
        selectedBusiness ? selectedBusiness.targetAudience : '');
    const [selectedLanguage, setSelectedLanguage] = useState(preferredContentLanguageLocale);
    const [selectedOptimizeFor, setSelectedOptimizeFor] = useState(
        copyType.pageConfig.optimizeForOptions ? copyType.pageConfig.optimizeForOptions[0].label: '',
    );


    const showBrandVoicesDropDown = brandVoices?.length > 0;

    const [showToneOfVoiceInput, setShowToneOfVoiceInput] = useState(!showBrandVoicesDropDown);

    const [toneOfVoices, setToneOfVoices] = useState([]);
    const [writingStyles, setWritingStyles] = useState([]);
    const [suggestedTitles, setSuggestedTitles] = useState([]);

    const [selectedSuggestedTone, setSelectedSuggestedTone] = useState();
    const [selectedSuggestedWritingStyle, setSelectedSuggestedWritingStyle] = useState();
    const [selectedSuggestedTitle, setSelectedSuggestedTitle] = useState();
    const [selectedBrandVoice, setSelectedBrandVoice] = useState(brandVoices ? brandVoices[0]: null);

    const [projects, setProjects] = useState(StorageService.loadProjects());
    const [selectedProject, setSelectedProject] = useState(StorageService.loadSelectedProject());



    const [query, setQuery] = useState('');
    const [keyInfo, setKeyInfo] = useState('');
    const [result, setResult] = useState('');
    const [generatedCopy, setGeneratedCopy] = useState();
    const [translatedResult, setTranslatedResult] = useState('');
    const [generatedTranslatedCopy, setGeneratedTranslatedCopy] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isTranslationLoading, setIsTranslationLoading] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const [seletectedTone, setSeletectedTone] = useState(
        copyType.pageConfig.toneOptions[0].label,
    );

    const [tone, setTone] = useState(selectedBusiness?.tone || '');
    const [writingStyle, setWritingStyle] = useState(selectedBusiness?.writingStyle || '');
    const [isLoadingToneOfVoices, setIsLoadingToneOfVoices] = useState(false);
    const [isLoadingWritingStyles, setIsLoadingWritingStyles] = useState(false);
    const [isLoadingSuggestedTitles, setIsLoadingSuggestedTitles] = useState(false);

    const [isTranslationDialogOpen, setIsTranslationDialogOpen] =  useState(false);


    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [snackBarMessage, setSnackBarMessage] = useState('');

    const translationTypes = [
        {
            type: 'direct',
            label: 'app.directTranslationLabel',
            value: 'direct',
            description: 'app.directTranslationDescription'
        },
        {
            type: 'contextual',
            label: 'app.contextualTranslationLabel',
            value: 'contextual',
            description: 'app.contextualTranslationDescription'
        },
    ];
    const [selectedTranslationType, setSelectedTranslationType] =
        useState(translationTypes[0]);


    useEffect( async () => {

        if (!user) {
            navigate('/login', {replace: true});
        }
        if (user?.status === 'unverified') {
            navigate('/welcome', {replace: true});
        }

    }, []);

    const handleSelectTranslationType = (event) => {
         setSelectedTranslationType(translationTypes.find(type => type.value === event.target.value));
    }

    const getTranslationLanguageOptions = () => {
        const translationLanguages = contentLanguages;
        const currentLocale = generatedCopy?.locale || selectedLanguage;

        return translationLanguages?.filter(language => language.locale !== currentLocale);
    }

    const [selectedTranslationLanguages, setSelectedTranslationLanguages] =
        useState([]);

    const [translationLanguages, setTranslationLanguages] =
        useState(getTranslationLanguageOptions());

    const openTranslationDialog = () => {
        setIsTranslationDialogOpen(true);
    }

    const handleTranslationDrawerClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setIsTranslationDialogOpen(false);
        }
    }


    const getLanguageFromLocale = (locale) => {
        return (contentLanguages.find(langauge => langauge.locale === locale));
    }

    const handleTranslationLanguageChange = (event) => {


        const {
            target: { value },
        } = event;

        if (value.length > 2){
            alert('Maximum of 2 languages allowed');
            return;
        }

        setSelectedTranslationLanguages(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleSelectSuggestedTone = (tone) => {
        setSelectedSuggestedTone(tone);
        setTone(tone.toneName);
    };

    const handleSelectSuggestedWritingStyle = (writingStyle) => {
        setSelectedSuggestedWritingStyle(writingStyle);
        setWritingStyle(writingStyle.styleName);
    };

    const handleSelectSuggestedTitle = (suggestedTitle) => {
        setSelectedSuggestedTitle(suggestedTitle);
        setQuery(suggestedTitle.suggestionName);
    };

    const upgradePlan = () => {
        navigate('/plans', { state: plan })
    }

    const promptToUpgradePlanToAccessCopySuggestions = async () => {
        Swal.fire({
            text: t('app.upgradePlanToAccessCopySuggestionsMessage'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: t('app.upgradePlanLabel'),
            cancelButtonText: t('app.cancelLabel'),
            reverseButtons: true,
            confirmButtonColor: 'warning',
        }).then(async (result) => {
            if (result.value) {
                upgradePlan();
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                // Do Nothing
            }
        });
    };

    const hasAccessToCopySuggestions = () => {
        return planFeatures.canAccessCopySuggestions;
    }
    const handleSuggestTitles = async () => {

        if(!hasAccessToCopySuggestions()) {
            promptToUpgradePlanToAccessCopySuggestions();
            return;
        }

        setIsLoadingSuggestedTitles(true);
        setSuggestedTitles([]);
        setQuery('');
        setSelectedSuggestedTitle(null);

        const response = await SuggestionService.suggestGeneric(selectedLanguage,
            copyType.pageConfig.suggestionPrompt, selectedBusiness?.uid,
            targetAudience, user.token);

        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoadingSuggestedTitles(false);
        // @ts-ignore
        if (response.ok) {
            if (data && data.result) {
                setSuggestedTitles(data.result.suggestions);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }

    };

    const handleSuggestToneOfVoices = async () => {
        setIsLoadingToneOfVoices(true);
        setToneOfVoices([]);
        setTone('');
        setSelectedSuggestedTone(null);

        const response = await SuggestionService.suggestToneOfVoices(user.language,
            selectedBusiness?.name, selectedBusiness?.description,
            selectedBusiness?.industry, targetAudience, user.token);

        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoadingToneOfVoices(false);
        // @ts-ignore
        if (response.ok) {
            if (data && data.result) {
                setToneOfVoices(data.result.tones);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }

    };

    const handleSuggestWritingStyles = async () => {
        setIsLoadingWritingStyles(true);
        setWritingStyles([]);
        setWritingStyle('');
        setSelectedSuggestedWritingStyle(null);

        const response = await SuggestionService.suggestWritingStyles(user.language,
            selectedBusiness?.name, selectedBusiness?.description,
            selectedBusiness?.industry, targetAudience, tone, user.token);

        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoadingWritingStyles(false);
        // @ts-ignore
        if (response.ok) {
            if (data && data.result) {
                setWritingStyles(data.result.styles);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    };

    const handleFieldChange = useCallback(
        (callback, e) => {
            const value = e.target.value;
            callback(value);

        },
        [ setTone, setWritingStyle],
    );

    const handleQueryChange = useCallback(
        (e) => {
            const value = e.target.value;
            setQuery(value);
            setSelectedSuggestedTitle(null);
        },
        [setQuery, setSelectedSuggestedTitle],
    );

    const handleKeyInfoChange = useCallback(
        (e) => {
            const value = e.target.value;
            setKeyInfo(value);
        },
        [setKeyInfo],
    );

    const handleTargetAudienceChange = useCallback(
        (e) => {
            const value = e.target.value;
            setTargetAudience(value);
        },
        [setTargetAudience],
    );



    const handleCustomToneChange = useCallback(
        (e) => {
            const value = e.target.value;
            setCustomTone(value);
        },
        [setCustomTone],
    );

    const handleToneChange = useCallback(
        (e) => {
            const value = e.target.value;
            setSeletectedTone(value);
            if (value === 'Custom') {
                setIsCustomTone(true);
            }
            else {
                setIsCustomTone(false);
                setCustomTone('');
            }
        },
        [setSeletectedTone, setCustomTone, setIsCustomTone],
    );

    const handleOptimizeForChange = useCallback(
        (e) => {
            const value = e.target.value;
            setSelectedOptimizeFor(value);
        },
        [setSelectedOptimizeFor],
    );

    const handleLanguageChange = useCallback(
        (e) => {
            const value = e.target.value;
            setSelectedLanguage(value);
        },
        [setSelectedLanguage],
    );

    const handleBrandVoiceChange = useCallback(
        (e) => {
            const value = e.target.value;

            if(value === 'use-tone') {
                setShowToneOfVoiceInput(true);
                setSelectedBrandVoice(null);
                return
            }
            setShowToneOfVoiceInput(false);

            const brandVoice = brandVoices.find(brandVoice => brandVoice.uid === value);
            setSelectedBrandVoice(brandVoice);
        },
        [setSelectedBrandVoice],
    );

    const handleProjectChange = useCallback(
        (e) => {
            const projectUid = e.target.value;
            const project = projects.find(proj => proj.uid === projectUid);
            setSelectedProject(project);
        },
        [setSelectedProject],
    );

    const copyContent = async () => {
        if(generatedCopy) {
            if (generatedTranslatedCopy) {
                await navigator.clipboard.writeText(generatedCopy?.result + '\n\n' +
                    generatedTranslatedCopy?.translatedResult);

            }
            else {
                await navigator.clipboard.writeText(generatedCopy?.result);

            }
            showSnackBarAlert('app.copiedLabel', 'success');
        }
    };

    const showSnackBarAlert = (messageKey, alertSeverity) => {
        setSnackBarMessage(t(messageKey));
        setAlertSeverity(alertSeverity);
        setIsSnackBarOpen(true);
    }

    const createCopy = async () => {
        setIsLoading(true);
        setGeneratedTranslatedCopy(null);
        setTranslatedResult("");


        const brandVoiceUid = selectedBrandVoice ? selectedBrandVoice?.uid : null;
        const response = await CreatorService.create(
            query,
            tone,
            copyType.pageConfig.feature,
            selectedLanguage,
            selectedProject.uid,
            selectedBusiness ? selectedBusiness.uid: null,
            keyInfo,
            writingStyle,
            targetAudience,
            selectedOptimizeFor,
selectedSuggestedTitle ? selectedSuggestedTitle.suggestionSummary : '',
            selectedSuggestedTitle ? selectedSuggestedTitle.suggestionImpact : '',
            brandVoiceUid,
                user.token
        );

        // @ts-ignore
        const resJson = await response.json();
        // alert(JSON.stringify(resJson));


        const {data, message} = resJson;
        setIsLoading(false);
        // @ts-ignore
        if (response.ok) {
            if (data.copy) {
                setGeneratedCopy(data.copy);
                setResult(data.copy.result);
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const saveCopy = async () => {

        setIsSaving(true);


        const params = {
            teamUid: team?.uid,
            accessToken: user.token,
            brandVoiceUid: generatedCopy?.brandVoice ? generatedCopy?.brandVoice?.uid: null,
            ...generatedCopy,
            ...generatedTranslatedCopy
        };

        const response = await CreatorService.save(params);

        // @ts-ignore
        const resJson = await response.json();
        // alert(JSON.stringify(resJson));


        const {data, message} = resJson;
        setIsSaving(false);
        // @ts-ignore
        if (response.ok) {
            if (data.status === 'success') {
                showSnackBarAlert('app.contentSavedLabel', 'success');

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const translateContent = async () => {
        setIsTranslationDialogOpen(false);
        setIsTranslationLoading(true);

        if (generatedCopy && generatedCopy.result) {
            const response = await TranslationService.translateContent(
                generatedCopy.result,
                selectedTranslationLanguages,
                selectedTranslationType.value,
                user.token
            );

            // @ts-ignore
            const resJson = await response.json();

            const {data, message} = resJson;
            setIsTranslationLoading(false);
            // @ts-ignore
            if (response.ok) {
                if (data.translation) {
                    setGeneratedTranslatedCopy(data.translation);
                    setTranslatedResult(data.translation.result);
                } else {
                    alert(data.message);
                }
            } else {
                alert(message);
            }
        }
        else {
            alert ('A copy needs to be generated first');
        }
    }


    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsSnackBarOpen(false);
    }


    return (
        <React.Fragment>
            <Typography variant={'h2'}>
                <Button
                    onClick={() => navigate(-1)}
                    size={"small"}
                    startIcon={<ArrowBackIcon />}
                >
                    {t("app.backButtonLabel")}
                </Button>
                {t(copyType.titleKey)}
            </Typography>
            <Typography sx={{ fontSize: 14, width:350 }} color="text.secondary" gutterBottom>
                {t(copyType.descriptionKey)}
            </Typography>

            <Grid container spacing={3.75} mt={-3} mb={3}>
                <Grid item xs={12} sm={6} lg={4}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <TextField
                                label={t(copyType.pageConfig.inputFieldPlaceHolderKey)}
                                multiline
                                minRows={2}
                                fullWidth
                                value={query}
                                onChange={handleQueryChange}
                                sx={{ marginBottom: 2 }}
                                InputProps={copyType.pageConfig.shouldSuggestTitle && {
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <LoadingButton onClick={handleSuggestTitles} loading={isLoadingSuggestedTitles} size={'small'}>
                                                {t('app.suggestButtonLabel')}
                                            </LoadingButton>
                                        </InputAdornment>,
                                }}
                            />
                            <Box sx={{marginBottom: 1}}>
                                {selectedSuggestedTitle && (
                                    <>
                                        <Box>
                                            <Typography variant={'caption'} color="text.secondary" gutterBottom>
                                                {selectedSuggestedTitle.suggestionSummary}
                                            </Typography>
                                        </Box>
                                        <Typography variant={'caption'} color="text.secondary" gutterBottom>
                                            {selectedSuggestedTitle.suggestionImpact}
                                        </Typography>
                                    </>
                                )}

                            </Box>
                            {suggestedTitles && (
                                <Box sx={{marginBottom: 2}}>
                                    {suggestedTitles.map( suggestedTitle => (
                                        <Chip sx={{marginRight: 1, marginBottom:1}}
                                              label={suggestedTitle.suggestionName}
                                              variant="outlined"
                                              color={selectedSuggestedTitle && selectedSuggestedTitle.suggestionName === suggestedTitle.suggestionName ? 'primary': 'default'}
                                              onClick={()=> {handleSelectSuggestedTitle(suggestedTitle)}}
                                        />
                                    ))}
                                </Box>
                            )}

                            {showBrandVoicesDropDown && (
                                <>
                                    <InputLabel id="tone-label">{t('app.selectBrandVoiceLabel')}</InputLabel>
                                    <Select
                                        labelId="tone-label"
                                        value={selectedBrandVoice?.uid || 'use-tone'}
                                        onChange={handleBrandVoiceChange}
                                        fullWidth
                                        label={t('app.selectBrandVoiceLabel')}
                                        sx={{ marginBottom: 2 }}
                                    >
                                        {brandVoices.map( brandVoice => (
                                            <MenuItem value={brandVoice.uid}>{brandVoice.name}</MenuItem>
                                        ))}
                                        <MenuItem value={'use-tone'}>{t('app.useToneInsteadLabel')}</MenuItem>

                                    </Select>
                                </>
                            )}

                            {(!showBrandVoicesDropDown || showToneOfVoiceInput) && (
                                <>
                                    <Box mb={1}>
                                        <TextField
                                            label={t('app.businessToneOfVoiceLabel')}
                                            placeholder={t('app.businessToneOfVoicePlaceholder')}
                                            fullWidth
                                            value={tone}
                                            onChange={(e) =>
                                            {handleFieldChange(setTone, e)}}
                                            sx={{ marginBottom: 1 }}
                                        />
                                    </Box>
                                    <Box>
                                        <TextField
                                            label={t('app.businessWritingStyleLabel')}
                                            placeholder={t('app.businessWritingStylePlaceholder')}
                                            value={writingStyle}
                                            fullWidth
                                            onChange={(e) =>
                                            {handleFieldChange(setWritingStyle, e)}}
                                            sx={{ marginBottom: 1 }}
                                        />
                                    </Box>
                                </>
                            )}




                            {isCustomTone && (
                                <TextField
                                    label={t('app.specifyCustomToneLabel')}
                                    fullWidth
                                    onChange={handleCustomToneChange}
                                    sx={{ marginBottom: 2 }}
                                />
                            )}


                            <TextField
                                label={t('app.keyInfoLabel')}
                                multiline
                                minRows={2}
                                fullWidth
                                onChange={handleKeyInfoChange}
                                sx={{ marginBottom: 2 }}
                            />

                            <TextField
                                label={t('app.targetAudienceLabel')}
                                multiline
                                minRows={2}
                                fullWidth
                                value={targetAudience}
                                onChange={handleTargetAudienceChange}
                                sx={{ marginBottom: 2 }}
                            />

                            {copyType.pageConfig.optimizeForOptions && (
                                <>
                                <InputLabel id="tone-label">{t('app.selectOptimizeForLabel')}</InputLabel>
                                <Select
                                    labelId="tone-label"
                                    value={selectedOptimizeFor}
                                    onChange={handleOptimizeForChange}
                                    fullWidth
                                    label={'app.optimizeForLabel'}
                                    sx={{ marginBottom: 2 }}
                                >
                                    {copyType.pageConfig.optimizeForOptions.map( optimizeFor => (
                                        <MenuItem value={optimizeFor.label}>{optimizeFor.label}</MenuItem>
                                    ))}
                                </Select>
                                </>
                            )}


                            <InputLabel id="tone-label">{t('app.selectLanguageLabel')}</InputLabel>
                            <Select
                                labelId="tone-label"
                                value={selectedLanguage}
                                onChange={handleLanguageChange}
                                fullWidth
                                label={t('app.selectLanguageLabel')}
                                sx={{ marginBottom: 2 }}
                            >
                                {contentLanguages?.map( contentLanguage => (
                                    <MenuItem value={contentLanguage.locale}>{contentLanguage.language}</MenuItem>
                                ))}
                            </Select>

                            <InputLabel id="tone-label">{t('app.selectProjectLabel')}</InputLabel>
                            <Select
                                labelId="tone-label"
                                value={selectedProject?.uid}
                                onChange={handleProjectChange}
                                fullWidth
                                label={t('app.selectProjectLabel')}
                                sx={{ marginBottom: 2 }}
                            >
                                {projects.map( project => (
                                    <MenuItem value={project.uid}>{project.name}</MenuItem>
                                ))}
                            </Select>

                            <LoadingButton onClick={createCopy} loading={isLoading} variant={'contained'} size={"small"}>
                                {t(copyType.pageConfig.buttonLabelKey).toUpperCase()}
                            </LoadingButton>
                        </CardContent>
                        <CardActions>

                        </CardActions>
                    </Card>
                </Grid>

                {(isLoading || generatedCopy) && (
                    <Grid item xs={12} sm={6} lg={8}>

                        <Card sx={{ minWidth: 275}}>

                            <CardContent>
                                <Typography variant="h5" component="div">
                                    {t(copyType.pageConfig.modalResultLabelKey)}
                                </Typography>

                                {generatedCopy?.result && (
                                    <Box sx={{marginLeft: -0.5, marginBottom: 2}}>
                                        <Button
                                            onClick={createCopy}
                                            color={'info'}
                                            size={"small"}
                                            startIcon={<ReplayIcon />}
                                        >
                                            {t("app.regenerateContentButtonLabel")}
                                        </Button>
                                        <Button
                                            onClick={copyContent}
                                            color={'secondary'}
                                            size={"small"}
                                            startIcon={<ContentCopyIcon />}
                                        >
                                            {t("app.copyContentButtonLabel")}
                                        </Button>
                                        <Button
                                            onClick={() => setDrawerOpen(true)}
                                            color={'warning'}
                                            size={"small"}
                                            startIcon={<EditIcon />}
                                        >
                                            {t("app.editContentButtonLabel")}
                                        </Button>

                                        <LoadingButton
                                            loading={isTranslationLoading}
                                            onClick={openTranslationDialog}
                                            color={'success'}
                                            size={"small"}
                                            startIcon={<TranslateIcon />}
                                        >
                                            {t("app.translateContentButtonLabel")}
                                        </LoadingButton>
                                        <LoadingButton
                                            loading={isSaving}
                                            onClick={saveCopy}
                                            color={'primary'}
                                            size={"small"}
                                            startIcon={<SaveIcon />}
                                        >
                                            {t("app.saveContentButtonLabel")}
                                        </LoadingButton>
                                    </Box>

                                )}
                                <Typography variant="body2" style={{whiteSpace: "pre-wrap"}}>
                                    {
                                        isLoading ?
                                            <>
                                                <Skeleton />
                                                <br/>
                                                <Skeleton />
                                                <Skeleton />
                                                <Skeleton />
                                                <br/>
                                                <Skeleton />
                                                <Skeleton />
                                                <Skeleton />
                                                <br/>
                                                <Skeleton />
                                                <Skeleton />
                                                <Skeleton />
                                            </>

                                            : generatedCopy?.result || ''}
                                </Typography>
                                <Box sx={{marginTop: 3}}>
                                    <Typography variant="body2" style={{whiteSpace: "pre-wrap"}}>
                                        {
                                            isTranslationLoading ?
                                                <>
                                                    <Skeleton />
                                                    <br/>
                                                    <Skeleton />
                                                    <Skeleton />
                                                    <Skeleton />
                                                    <br/>
                                                    <Skeleton />
                                                    <Skeleton />
                                                    <Skeleton />
                                                    <br/>
                                                    <Skeleton />
                                                    <Skeleton />
                                                    <Skeleton />
                                                </>

                                                : generatedTranslatedCopy?.translatedResult || ''}
                                    </Typography>
                                </Box>


                            </CardContent>

                        </Card>

                    </Grid>

                )}

            </Grid>


            <Drawer
                anchor={'right'}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                variant="persistent"
                sx={{
                    zIndex: 1205,

                    '& .MuiDrawer-paper': {
                        width: 400
                    }
                }}
            >
                <Div
                    sx={{
                        display: 'flex',
                        minWidth: 0,
                        position: 'relative',
                        zIndex: 2,
                        p: theme => theme.spacing(1.5, 3),
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 25,
                    }}
                >
                    <Typography variant={"h3"} mb={0}>Content Editor</Typography>
                    <IconButton aria-label="close" onClick={() => setDrawerOpen(false)}>
                        <CloseIcon/>
                    </IconButton>


                </Div>
                <Box sx={{p: theme => theme.spacing(1.5, 3),}}>

                    <TextField
                        label={'Content'}
                        value={generatedCopy?.result}
                        multiline
                        minRows={2}
                        fullWidth
                        onChange={(e) =>{
                            setGeneratedCopy({
                                ...generatedCopy,
                                result: e.target.value,
                            })
                        }}
                        sx={{ marginBottom: 2 }}
                    />
                </Box>
            </Drawer>

            <Dialog disableEscapeKeyDown open={isTranslationDialogOpen} onClose={handleTranslationDrawerClose}>
                <DialogTitle>{t('app.chooseTranslationOptions')}</DialogTitle>
                <DialogContent>
                    <Box>
                        <Box sx={{ marginBottom: 2 }}>
                            <InputLabel id="selectTranslationTypeLabel">{t('app.selectTranslationTypeLabel')}</InputLabel>
                            <Select
                                labelId="selectTranslationTypeLabel"
                                value={selectedTranslationType.value}
                                onChange={handleSelectTranslationType}
                                fullWidth
                                label={t('app.selectTranslationTypeLabel')}
                            >
                                {translationTypes.map( type => (
                                    <MenuItem value={type.value}>{t(type.label)}</MenuItem>
                                ))}
                            </Select>
                            <Typography variant={'caption'} color="text.secondary" gutterBottom>
                                {t(selectedTranslationType.description)}
                            </Typography>
                        </Box>
                        <Box sx={{ m: 1 }}>
                            <InputLabel id="selectLanguagesLabel">{t('app.selectLanguagesLabel')}</InputLabel>
                            <Select
                                labelId="selectLanguagesLabel"
                                multiple
                                value={selectedTranslationLanguages}
                                onChange={handleTranslationLanguageChange}
                                fullWidth
                                label={t('app.selectLanguagesLabel')}
                                sx={{ marginBottom: 2 }}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((locale) => (
                                            <Chip key={locale} label={getLanguageFromLocale(locale)?.language} />
                                        ))}
                                    </Box>
                                )}
                            >
                                {translationLanguages.map( translationLanguage => (
                                    <MenuItem value={translationLanguage.locale}>{translationLanguage.language}</MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleTranslationDrawerClose}>{t('app.cancelButtonLabel')}</Button>
                    {planFeatures.canAccessTranslations && (
                        <Button onClick={translateContent}>{t('app.translateContentButtonLabel')}</Button>
                    )}

                    {!planFeatures.canAccessTranslations && (
                        <Button color={'error'} onClick={upgradePlan}>{t('app.upgradePlanToTranslateContentButtonLabel')}</Button>
                    )}
                </DialogActions>
            </Dialog>

            <Snackbar anchorOrigin={{vertical: 'top', horizontal:'center' }}
                      open={isSnackBarOpen} autoHideDuration={5000} onClose={handleSnackBarClose}
            >
                <Alert severity={alertSeverity} sx={{ width: '100%' }}>
                    {snackBarMessage}
                </Alert>
            </Snackbar>

        </React.Fragment>
    );
};

export default Create;
