import React, {useEffect, useState} from "react";
import {
    Box,
    Card, CardActions,
    CardContent,
    Divider, Grid,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {StorageService} from "../../services/StorageService";
import {CampaignService} from "../../services/CampaignService";
import {Skeleton} from "@mui/lab";
import Swal from "sweetalert2";

const Campaigns = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const user = StorageService.loadUser();
    const team = StorageService.loadTeam();
    const isAccountOwner = StorageService.loadIsAccountOwner();


    const [campaigns, setCampaigns] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect( async () => {

        if (!user) {
            navigate('/login', {replace: true});
        }
        if (user?.status === 'unverified') {
            navigate('/welcome', {replace: true});
        }

        await getTeamCampaigns();

    }, []);

    const getTeamCampaigns = async () => {

        setIsLoading(true);
        const response =  await CampaignService.getTeamCampaigns(team?.uid, user.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setCampaigns(data.campaigns);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const createNewCampaign = () => {
        navigate('/campaigns/create');
    }

    const viewCampaign = (campaignUid) => {
        navigate(`/campaigns/${campaignUid}`);
    }


    const promptToDeleteCampaign = async (campaignUid) => {
        Swal.fire({
            text: t('app.deleteCampaignPromptTitle'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t('app.deleteLabel'),
            cancelButtonText: t('app.cancelLabel'),
            reverseButtons: true,
            confirmButtonColor: 'error',
        }).then(async (result) => {
            if (result.value) {
                await deleteCampaign(campaignUid);
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                // Do Nothing
            }
        });
    };

    const deleteCampaign = async (campaignUid) => {
        const response =  await CampaignService.deleteCampaign(campaignUid, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;

        // @ts-ignore
        if (response.ok) {
            if (data && data.status === 'success') {
                await getTeamCampaigns();
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }


    return (
        <React.Fragment>
            <Typography variant={'h1'} >
                {t("app.campaignsTitleLabel")}
                <Button
                    variant={"outlined"}
                    sx={{marginLeft: 1}}
                    onClick={createNewCampaign}
                    size={'small'}
                >
                    {t('app.createCampaignButtonLabel')}
                </Button>
            </Typography>
            <Typography variant={'h6'} color={'text.secondary'}>
                {t("app.campaignsDescriptionLabel")}
            </Typography>

            <Divider/>

            <Box mt={5}>

                <Box>
                    {
                        isLoading && (
                            <>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </>
                        )
                    }

                    {!isLoading && campaigns?.length < 1 && (

                        <Card>
                            <CardContent>
                                <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                    <Typography variant={'h4'}>
                                        {t('app.noCampaignsCreatedLabel')}
                                    </Typography>
                                </Box>


                                <Box mt={3} sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                    <Button
                                        onClick={createNewCampaign}
                                        size={'small'}
                                        variant={"outlined"}>{t('app.createCampaignButtonLabel')}</Button>

                                </Box>
                            </CardContent>
                        </Card>
                    )}

                    {!isLoading && campaigns?.length > 0 && (
                        <Grid container spacing={3.75} mb={3}>

                            {campaigns.map( (campaign, index) =>
                                (
                                    <>
                                        <Grid item key={index} xs={12} sm={6} lg={3}>
                                            <Card>
                                                <CardContent>
                                                    <Typography variant={'h4'}>
                                                        {campaign.title}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    <Button sx={{marginLeft: -2}} size={"small"} onClick={() => {viewCampaign(campaign.uid)}}>{t('app.viewButtonLabel')}</Button>
                                                    {isAccountOwner && (
                                                        <Button color={'error'} size={"small"} onClick={() => {promptToDeleteCampaign(campaign.uid)}}>{t('app.deleteButtonLabel')}</Button>
                                                    )}
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    </>
                                )
                            )}
                        </Grid>
                    )}
                </Box>

            </Box>

        </React.Fragment>
    )

};

export default Campaigns;