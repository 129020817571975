import {StorageService} from "./StorageService";

export class APIService {
  static baseUrl = process.env.REACT_APP_API_ENDPOINT;
  static METHOD_GET = 'GET';
  static METHOD_POST = 'POST';
  static METHOD_DELETE = 'DELETE';
  static METHOD_PATCH = 'PATCH';



  static async makeRequest(
    apiEndpoint,
    body,
    headers= {},
    method = APIService.METHOD_POST,
    url = this.baseUrl,
  ) {

    const account = StorageService.loadSelectedAccount();
    headers['x-owner'] = account?.ownerUid || null;
    if (method === APIService.METHOD_GET || method === APIService.METHOD_DELETE) {
      const response = await fetch(`${url}${apiEndpoint}`, {
        headers: headers,
        method: method,
      });
      return response;
    }

    const response = await fetch(`${url}${apiEndpoint}`, {
      headers: headers,
      method: method,
      body: JSON.stringify(body),
    });
    return response;
  }
}
