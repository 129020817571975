import React, {useEffect, useState, Suspense} from 'react';
import Stack from "@mui/material/Stack";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import AuthUserDropdown from "../../../../shared/widgets/AuthUserDropdown";
import {Box, Divider, IconButton, Typography, useMediaQuery} from "@mui/material";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logo from "../../../../shared/Logo";
import {SIDEBAR_STYLES, SIDEBAR_VARIANTS} from "@jumbo/utils/constants";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import {StorageService} from "../../../../services/StorageService";
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import useJumboTheme from "../../../../../@jumbo/hooks/useJumboTheme";
import useJumboSidebarTheme from "../../../../../@jumbo/hooks/useJumboSidebarTheme";
import useJumboFooterTheme from "../../../../../@jumbo/hooks/useJumboFooterTheme";
import {mainTheme as mainThemeDark} from "../../../../themes/main/dark";
import {headerTheme as headerThemeDark} from "../../../../themes/header/dark";
import {sidebarTheme as sidebarThemeDark} from "../../../../themes/sidebar/dark";
import {footerTheme as footerThemeDark} from "../../../../themes/footer/dark";
import {mainTheme as mainThemeDefault} from "../../../../themes/main/default";
import {headerTheme as headerThemeDefault} from "../../../../themes/header/default";
import {sidebarTheme as sidebarThemeDefault} from "../../../../themes/sidebar/default";
import {footerTheme as footerThemeDefault} from "../../../../themes/footer/default";
import {useNavigate} from "react-router-dom";
import {getSupportedLocale} from "../../../../utils/locales";
import {useTranslation} from "react-i18next";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import {purple, red} from "@mui/material/colors";
import {BrandVoiceService} from "../../../../services/BrandVoiceService";

const Header = () => {
    const {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar();
    const {theme, setTheme, setMuiLocale} = useJumboTheme();
    const {headerTheme, setHeaderTheme} = useJumboHeaderTheme();
    const {setSidebarTheme} = useJumboSidebarTheme();
    const {setFooterTheme} = useJumboFooterTheme();
    const navigate = useNavigate();


    const user = StorageService.loadUser();
    const team = StorageService.loadTeam();

    // const [businesses, setBusinesses] = useState(StorageService.loadBusinesses() || []);
    const [selectedBusiness, setSelectedBusiness] = useState(StorageService.loadSelectedBusiness());

    const {i18n} = useTranslation();
    const themeColor = StorageService.loadAppThemeColor() || 'light';

    useEffect(async () => {
        if (!user) {
            navigate('/login', {replace: true});
        }

        if(themeColor === 'dark') {
            await setTheme({mode: "dark", ...mainThemeDark});
            setHeaderTheme({...theme, ...headerThemeDark});
            setSidebarTheme({...theme, ...sidebarThemeDark});
            setFooterTheme({...theme, ...footerThemeDark});
        }
        else {
            await setTheme({mode: "light", ...mainThemeDefault});
            setHeaderTheme({...theme, ...headerThemeDefault});
            setSidebarTheme({...theme, ...sidebarThemeDefault});
            setFooterTheme({...theme, ...footerThemeDefault});
        }


        const supportedLocale = getSupportedLocale(user?.locale)

        await i18n.changeLanguage(user?.locale);

        setMuiLocale(supportedLocale.muiLocale);

        await getTeamBrandVoices();

    }, []);



    const handleThemeChange = React.useCallback(async (theme) => {
        StorageService.saveAppThemeColor(theme);
        if(theme === 'dark') {
            await setTheme({mode: "dark", ...mainThemeDark});
            setHeaderTheme({...theme, ...headerThemeDark});
            setSidebarTheme({...theme, ...sidebarThemeDark});
            setFooterTheme({...theme, ...footerThemeDark});
            return;
        }
        await setTheme({mode: "light", ...mainThemeDefault});
        setHeaderTheme({...theme, ...headerThemeDefault});
        setSidebarTheme({...theme, ...sidebarThemeDefault});
        setFooterTheme({...theme, ...footerThemeDefault});
    }, []);

    const getTeamBrandVoices = async () => {

        const response =  await BrandVoiceService.getTeamBrandVoices(team?.uid, user.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data} = resJson;

        // @ts-ignore
        if (response.ok) {
            if (data) {
                StorageService.saveBrandVoices(data.brandVoices);
            } else {
            }
        } else {
        }
    }

    return (
        <React.Fragment>
            {
                (
                    sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                    || sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY
                    || (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT && !sidebarOptions.open)
                ) &&
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{
                            ml: sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ? -2 : 0,
                            mr: 3,
                        }}
                        onClick={() => setSidebarOptions({open: !sidebarOptions.open, anchor: 'left', hide: false})}
                    >
                        {
                            sidebarOptions?.open ? <MenuOpenIcon/> : <MenuIcon/>
                        }
                    </IconButton>
            }
            {
                sidebarOptions?.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
                <Logo sx={{mr: 3}} mode={headerTheme.type ?? "light"}/>
            }

            {/*{*/}
            {/*    showDropdownSearch &&*/}
            {/*    <Slide in={dropdownSearchVisibility}>*/}
            {/*        <Div*/}
            {/*            sx={{*/}
            {/*                zIndex: 5,*/}
            {/*                left: 0,*/}
            {/*                right: 0,*/}
            {/*                position: 'absolute',*/}
            {/*                height: '100%',*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            <Select*/}
            {/*                labelId="tone-label"*/}
            {/*                value={selectedBusiness?.uid}*/}
            {/*                onChange={() => {}}*/}
            {/*                sx={{*/}
            {/*                    maxWidth: 'none',*/}
            {/*                    height: '100%',*/}
            {/*                    display: 'flex',*/}

            {/*                    '& .MuiInputBase-root': {*/}
            {/*                        flex: 1,*/}
            {/*                        borderRadius: 0,*/}
            {/*                        background: theme => theme.palette.background.default,*/}
            {/*                    },*/}
            {/*                    '& .MuiInputBase-input': {*/}
            {/*                        pr: 6,*/}
            {/*                    }*/}
            {/*                }}>*/}
            {/*                {businesses?.map( business => (*/}
            {/*                    <MenuItem value={business.uid}>{business.name}</MenuItem>*/}
            {/*                ))}*/}
            {/*            </Select>*/}
            {/*            <IconButton*/}
            {/*                sx={{*/}
            {/*                    position: 'absolute',*/}
            {/*                    right: 15,*/}
            {/*                    top: '50%',*/}
            {/*                    color: 'inherit',*/}
            {/*                    transform: 'translateY(-50%)',*/}
            {/*                }}*/}
            {/*                onClick={() => setDropdownSearchVisibility(false)}*/}
            {/*            >*/}
            {/*                <CloseIcon/>*/}
            {/*            </IconButton>*/}
            {/*        </Div>*/}
            {/*    </Slide>*/}
            {/*}*/}


            <Stack direction="row" alignItems="center" spacing={1.25} sx={{ml: "auto"}}>

                {(theme?.mode === 'light'|| theme?.mode === undefined) && (
                    <JumboIconButton elevation={25} onClick={() => handleThemeChange('dark')}>
                        <LightModeIcon  sx={{fontSize: '1.25rem', cursor: 'pointer'}}/>
                    </JumboIconButton>
                )}

                {theme?.mode === 'dark' && (
                    <JumboIconButton elevation={25} onClick={() => handleThemeChange('light')}>
                        <DarkModeIcon  sx={{fontSize: '1.25rem', cursor: 'pointer'}}/>
                    </JumboIconButton>
                )}

                <Suspense fallback={<Box/>}>
                    <AuthUserDropdown/>

                    {selectedBusiness && (
                        <>
                            <Divider orientation="vertical" flexItem />

                            <Box mt={1}>
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ bgcolor: purple[500], color:'white' }} aria-label="recipe">
                                            {selectedBusiness?.name[0]}
                                        </Avatar>
                                    }
                                    title={<Typography sx={{fontWeight: 'bold'}}>{selectedBusiness?.name.toUpperCase()}</Typography>}
                                />
                            </Box>
                        </>

                    )}


                </Suspense>
            </Stack>
        </React.Fragment>
    );
};

export default Header;
