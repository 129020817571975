import React, {useCallback, useState} from 'react';
import {Box, Card, CardContent, Checkbox, Divider, FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import {Skeleton} from "@mui/lab";
import {StorageService} from "../../services/StorageService";
import {BrandVoiceService} from "../../services/BrandVoiceService";
import Swal from "sweetalert2";
import Button from "@mui/material/Button";

const NewBrandVoice = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const user = StorageService.loadUser();
    const team = StorageService.loadTeam();
    const plan = StorageService.loadPlan();
    const planFeatures = StorageService.loadPlanFeatures();
    const business = StorageService.loadSelectedBusiness();
    const brandVoices = StorageService.loadBrandVoices();

    const locale = business ? business?.preferredContentLanguage : user?.locale;

    const [exampleText, setExampleText] = useState('');
    const [isAnalyzingBrandVoice, setIsAnalyzingBrandVoice] = useState(false);

    const [brandVoiceName, setBrandVoiceName] = useState('');
    const [brandVoiceSummary, setBrandVoiceSummary] = useState('');
    const [brandVoicePersonality, setBrandVoicePersonality] = useState('');
    const [brandToneOfVoice, setBrandToneOfVoice] = useState('');
    const [brandWritingStyle, setBrandWritingStyle] = useState('');
    const [isDefault, setIsDefault] = useState(false);

    const [isSavingBrandVoice, setIsSavingBrandVoice] = useState(false);


    const [brandVoice, setBrandVoice] = useState();


    const analyzeBrandVoice = async () => {
        if (!exampleText) {
            alert(t('app.brandVoiceEmptyExampleTextErrorMessage'));
            return;
        }

        setBrandVoice(null);
        setIsAnalyzingBrandVoice(true);

        const response =  await BrandVoiceService.analyzeBrandVoice(
            exampleText,
            locale,
            user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsAnalyzingBrandVoice(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setBrandVoice(data?.brandVoice);

                setBrandVoiceSummary(data?.brandVoice?.voiceName);
                setBrandVoicePersonality(data?.brandVoice?.voicePersonality);
                setBrandToneOfVoice(data?.brandVoice?.toneOfVoiceDescription);
                setBrandWritingStyle(data?.brandVoice?.writingStyleDescription);


            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const saveBrandVoice = async () => {
        if (!brandVoiceName) {
            alert(t('app.brandVoiceEmptyBrandVoiceNameErrorMessage'));
            return;
        }

        setIsSavingBrandVoice(true);

        const response =  await BrandVoiceService.createBrandVoice(
            brandVoiceName,
            exampleText,
            brandVoiceSummary,
            brandVoicePersonality,
            brandToneOfVoice,
            brandWritingStyle,
            isDefault,
            locale,
            team?.uid,
            business?.uid,
            user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsSavingBrandVoice(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {

                Swal.fire({
                    text: t('app.brandVoiceSuccessfullyCreated'),
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: t('app.dismissButtonLabel'),
                    confirmButtonColor: 'error',
                }).then(async (result) => {
                    if (result.value) {
                        navigate('/brand-voices');
                    } else if (
                        result.dismiss === Swal.DismissReason.cancel
                    ) {
                        navigate('/brand-voices');
                    }
                });

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const handleFieldChange = useCallback((
        callback, e) => {
            const value = e.target.value;
            callback(value);
        }, [setExampleText, setBrandVoiceName, setBrandVoiceSummary,
        setBrandVoicePersonality, setBrandToneOfVoice,
        setBrandWritingStyle, setIsSavingBrandVoice])

    const handleIsDefaultChange = (event) => {
        setIsDefault(event.target.checked);
    }

    const upgradePlan = () => {
        navigate('/plans', { state: plan })
    }

    return (
        <React.Fragment>
            <Typography variant={'h1'} >
                {t("app.newBrandVoiceTitleLabel")}
            </Typography>
            <Typography variant={'h6'} color={'text.secondary'}>
                {t("app.newBrandVoiceDescriptionLabel")}
            </Typography>

            <Divider/>

            <Box mt={5}>
                <Grid container spacing={3.75} mt={-3} mb={3}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <TextField
                                    label={t('app.brandVoiceExampleTextLabel')}
                                    placeholder={t('app.brandVoiceExampleTextPlaceholderLabel')}
                                    multiline
                                    minRows={10}
                                    fullWidth
                                    value={exampleText}
                                    onChange={(e) =>
                                    {handleFieldChange(setExampleText, e)}}
                                    sx={{ marginBottom: 2 }}
                                />

                                <LoadingButton
                                    loading={isAnalyzingBrandVoice}
                                    size={'small'}
                                    onClick={analyzeBrandVoice}
                                    variant={'contained'}
                                >
                                    {t('app.analyseBrandVoiceButtonLabel')}
                                </LoadingButton>
                            </CardContent>
                        </Card>
                    </Grid>

                    {isAnalyzingBrandVoice && !brandVoice && (
                        <Grid item xs={12} sm={6} lg={8}>
                            <Skeleton/>
                            <Skeleton/>
                            <Skeleton/>
                            <Skeleton/>
                            <Skeleton/>
                            <Skeleton/>
                            <Skeleton/>

                        </Grid>
                    )}

                    {!isAnalyzingBrandVoice && brandVoice && (
                        <Grid item xs={12} sm={6} lg={8}>

                            <Card sx={{ minWidth: 275}}>

                                <CardContent>
                                    <Box mb={2}>
                                        <TextField
                                            label={t('app.brandVoiceNameLabel')}
                                            fullWidth
                                            value={brandVoiceName}
                                            onChange={(e) =>
                                            {handleFieldChange(setBrandVoiceName, e)}}
                                            sx={{ marginBottom: 2 }}
                                        />
                                    </Box>
                                    <Box mb={2}>
                                        <TextField
                                            label={t('app.brandVoiceSummaryLabel')}
                                            multiline
                                            minRows={1}
                                            fullWidth
                                            value={brandVoiceSummary}
                                            onChange={(e) =>
                                            {handleFieldChange(setBrandVoiceSummary, e)}}
                                            sx={{ marginBottom: 2 }}
                                        />
                                    </Box>

                                    <Box mb={2}>
                                        <TextField
                                            label={t('app.brandVoicePersonalityLabel')}
                                            multiline
                                            minRows={1}
                                            fullWidth
                                            value={brandVoicePersonality}
                                            onChange={(e) =>
                                            {handleFieldChange(setBrandVoicePersonality, e)}}
                                            sx={{ marginBottom: 2 }}
                                        />
                                    </Box>

                                    <Box mb={2}>
                                        <TextField
                                            label={t('app.businessToneOfVoiceLabel')}
                                            multiline
                                            minRows={5}
                                            fullWidth
                                            value={brandToneOfVoice}
                                            onChange={(e) =>
                                            {handleFieldChange(setBrandToneOfVoice, e)}}
                                            sx={{ marginBottom: 2 }}
                                        />
                                    </Box>

                                    <Box mb={2}>
                                        <TextField
                                            label={t('app.businessWritingStyleLabel')}
                                            multiline
                                            minRows={5}
                                            fullWidth
                                            value={brandWritingStyle}
                                            onChange={(e) =>
                                            {handleFieldChange(setBrandWritingStyle, e)}}
                                            sx={{ marginBottom: 2 }}
                                        />
                                    </Box>

                                    <Box mb={2}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isDefault}
                                                    onChange={handleIsDefaultChange}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            label={t('app.setAsDefaultBrandVoice')}
                                        />

                                    </Box>

                                    {!planFeatures?.canAccessBrandVoices && planFeatures?.numberOfBrandVoices === 0 && (
                                        <Button color={'error'} onClick={upgradePlan}>{t('app.upgradePlanToCreateBrandVoicesLabel')}</Button>

                                    )}

                                    {planFeatures?.canAccessBrandVoices && brandVoices?.length >= planFeatures?.numberOfBrandVoices && (
                                        <Button color={'error'} onClick={upgradePlan}>{t('app.upgradePlanToAddMoreBrandVoicesLabel')}</Button>

                                    )}
                                    {planFeatures?.canAccessBrandVoices && brandVoices?.length < planFeatures?.numberOfBrandVoices && (
                                        <LoadingButton
                                            loading={isSavingBrandVoice}
                                            size={'small'}
                                            onClick={saveBrandVoice}
                                            variant={'contained'}
                                        >
                                            {t('app.saveBrandVoiceButtonLabel')}
                                        </LoadingButton>
                                    )}



                                </CardContent>
                            </Card>
                        </Grid>

                    )}

                </Grid>

            </Box>

        </React.Fragment>
    );

}

export default NewBrandVoice;