import React, {useEffect, useState} from 'react';
import {
    Box,
    Card,
    Typography,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions, TextField, ListItem, ListItemAvatar, ListItemText, Divider
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {StorageService} from "../../services/StorageService";
import {useNavigate} from "react-router-dom";
import {Skeleton} from "@mui/lab";
import Stack from "@mui/material/Stack";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import {getAbbreviation} from "../../utils/abbreviateUserName";
import DialogTitle from "@mui/material/DialogTitle";

import {TeamService} from "../../services/TeamService";
import List from "@mui/material/List";
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from "sweetalert2";

const Team = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const user = StorageService.loadUser();
    const team = StorageService.loadTeam();
    const plan = StorageService.loadPlan();
    const planFeatures = StorageService.loadPlanFeatures();
    const business = StorageService.loadSelectedBusiness();

    const [teamMembers, setTeamMembers] = useState([]);
    const [invites, setInvites] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
    const [inviteEmail, setInviteEmail] = useState('');


    const Item = styled(Span)(({theme}) => ({
        padding: theme.spacing(0, 1),
    }))

    const TeamMember = ({teamMember}) => {
        return (
            <Card sx={{mb: 1}}>
            <Stack direction={"row"} alignItems={"center"} sx={{p: theme => theme.spacing(2, 1)}}>
                <Item
                    sx={{
                        flex: {xs: 1, md: '0 1 45%', lg: '0 1 35%'}
                    }}
                >
                    <Stack direction={'row'} alignItems={'center'}>
                        <Item>
                                <Avatar
                                    sx={{
                                        width: 56,
                                        height: 56
                                    }}
                                >
                                    {getAbbreviation(teamMember?.name)}
                                </Avatar>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{`${teamMember.name}`}</Typography>
                            <Typography variant={"body1"} color="text.secondary">{teamMember.email}</Typography>
                        </Item>
                    </Stack>
                </Item>
                <Item
                    sx={{
                        alignSelf: 'flex-start',
                        flexBasis: {md: '28%', lg: '18%'},
                        display: {xs: 'none', md: 'block'}
                    }}
                >
                    <Typography variant={"h6"} mt={1} lineHeight={1.25}>{teamMember.role === 1 ? t('app.teamMemberOwnerRole') : t('app.teamMemberCollaboratorRole') }</Typography>
                </Item>

                {/*<Item*/}
                {/*    sx={{*/}
                {/*        alignSelf: 'flex-start',*/}
                {/*        flexBasis: {md: '28%', lg: '18%'},*/}
                {/*        display: {xs: 'none', md: 'block'}*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Typography variant={"h6"} mt={1} lineHeight={1.25}>{user?.status || 'Active'}</Typography>*/}
                {/*</Item>*/}

                {(invites !== null && user?.uid !== teamMember.uid) && (
                    <Item sx={{ml: {xs: 'auto', sm: 0}}}>
                        <IconButton edge="end" aria-label="delete" color={'error'}
                                    onClick={() => promptToRemoveFromTeam(teamMember)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Item>
                )}


            </Stack>
        </Card>
        );

    };



    useEffect( async () => {

        if (!user) {
            navigate('/login', {replace: true});
        }
        if (user?.status === 'unverified') {
            navigate('/welcome', {replace: true});
        }

        await getTeamMembers();

    }, []);

    const getTeamMembers = async () => {

        setIsLoading(true);
        const response =  await TeamService.getTeamMembers(team?.uid, user.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setTeamMembers(data.teamMemberUsers);
                setInvites(data.invites);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }


    const handleInviteDialogClose = () => {
        setIsInviteDialogOpen(false);
    }

    const upgradePlan = () => {
        navigate('/plans', { state: plan })
    }

    const sendInvite = async () => {

        if (inviteEmail === '') {
            return;
        }
        handleInviteDialogClose();
        const response =  await TeamService.inviteTeamMember(
            inviteEmail,
            team?.uid,
            business?.uid,
            user.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoading(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setInviteEmail('');
                await getTeamMembers();

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const handleMemberInviteEmailChange = (e) => {
        setInviteEmail(e.target.value);
    }

    const deleteInvite = async (invite) => {
        const response =  await TeamService.deleteInvite(invite.uid, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;

        // @ts-ignore
        if (response.ok) {
            if (data && data.status === 'success') {
                await getTeamMembers();
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const removeTeamMember = async (teamMember) => {
        const response =  await TeamService.removeTeamMember(teamMember.uid, team?.uid, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;

        // @ts-ignore
        if (response.ok) {
            if (data && data.status === 'success') {
                await getTeamMembers();
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const promptToDeleteInvite = async (invite) => {
        Swal.fire({
            text: t('app.deleteInvitePromptTitle'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t('app.deleteLabel'),
            cancelButtonText: t('app.cancelLabel'),
            reverseButtons: true,
            confirmButtonColor: 'error',
        }).then(async (result) => {
            if (result.value) {
                await deleteInvite(invite);
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                // Do Nothing
            }
        });
    };

    const promptToRemoveFromTeam = async (teamMember) => {
        Swal.fire({
            text: t('app.removeTeamMemberPromptTitle'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t('app.removeLabel'),
            cancelButtonText: t('app.cancelLabel'),
            reverseButtons: true,
            confirmButtonColor: 'error',
        }).then(async (result) => {
            if (result.value) {
                await removeTeamMember(teamMember);
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                // Do Nothing
            }
        });
    };

    return (
        <React.Fragment>
            <Typography variant={'h1'} mb={2}>
                {t("app.yourTeamLabel")}
                {invites !== null && (
                    <Button
                        variant={"outlined"}
                        sx={{marginLeft: 1}}
                        onClick={() => setIsInviteDialogOpen(true)}
                        size={'small'}
                    >
                        {t('app.inviteLabel')}
                    </Button>
                )}
            </Typography>

            <Divider/>


            <Box mt={5}>
                {
                    isLoading && (
                        <>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </>
                    )
                }

                {(!isLoading && teamMembers) && (
                    <>
                        <Typography variant={'h5'} color={'text.secondary'}>
                            {t('app.membersLabel')} ({teamMembers.length}/{planFeatures.numberOfUserSeats})
                        </Typography>
                        {teamMembers.map((teamMember, i) => (
                            <Box onClick={() => {}}>
                                <TeamMember
                                    teamMember={teamMember}
                                />
                            </Box>
                        ))}
                        <br/>
                        <br/>

                        {invites !== null && (
                            <>
                                <Typography variant={'h5'} color={'text.secondary'}>
                                    {t('app.invitesLabel')}
                                </Typography>
                                {invites.length > 0 && (
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

                                    {invites.map((invite, i) => (
                                        <ListItem
                                            secondaryAction={
                                                <IconButton edge="end" aria-label="delete" color={'error'}
                                                    onClick={() => promptToDeleteInvite(invite)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <EmailIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={invite.inviteEmail} secondary={t('app.invitePending')} />
                                        </ListItem>
                                    ))}
                                    </List>

                                )}

                                {invites.length === 0 && (
                                    <Box>
                                        <Typography variant={'h6'}>
                                            {t('app.noPendingInvitesLabel')}
                                        </Typography>
                                    </Box>
                                )}

                            </>
                        )}

                    </>
                )}
            </Box>


            <Dialog fullWidth={true} maxWidth={'sm'} disableEscapeKeyDown open={isInviteDialogOpen} onClose={handleInviteDialogClose}>
                <DialogTitle>{t('app.inviteMembersLabel')}</DialogTitle>
                <DialogContent>
                    <Box>
                            <Typography variant={'h6'} color={'text.secondary'}>
                                {t('app.inviteMemberMessage')}
                            </Typography>
                            <TextField
                                label={t('app.emailLabel')}
                                type={'email'}
                                fullWidth
                                onChange={handleMemberInviteEmailChange}
                                sx={{ marginBottom: 2 }}
                            />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleInviteDialogClose}>{t('app.cancelButtonLabel')}</Button>

                    {teamMembers.length >= planFeatures.numberOfUserSeats && (
                        <Button color={'error'} onClick={upgradePlan}>{t('app.upgradePlanToInviteMoreUsersLabel')}</Button>

                    )}
                    {teamMembers.length < planFeatures.numberOfUserSeats && (
                        <Button onClick={sendInvite}>{t('app.sendInviteButtonLabel')}</Button>
                    )}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Team;