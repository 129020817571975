import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog, DialogActions,
    DialogContent,
    Divider,
    Grid,
    ListItem, TextField,
    Typography
} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import {useTranslation} from "react-i18next";
import Chip from "@mui/material/Chip";
import {useNavigate} from "react-router-dom";
import {SubscriptionService} from "../../services/SubscriptionService";
import {StorageService} from "../../services/StorageService";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogTitle from "@mui/material/DialogTitle";
import {CardElement, useStripe, useElements, PaymentElement, AddressElement} from '@stripe/react-stripe-js';



const Subscription = () => {

    const stripe = useStripe();
    const elements = useElements();

    const {t} = useTranslation();
    const Swal = useSwalWrapper();

    const navigate = useNavigate();
    const [currentPlan, setCurrentPlan] = useState(null);
    const [paymentCard, setPaymentCard] = useState(null);
    const [billingDetails, setBillingDetails] = useState(null);
    const [nextBillingDate, setNextBillingDate] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [subscriptionIsCancelled, setSubscriptionIsCancelled] = useState(false);
    const [isCancelSubscriptionLoading, setIsCancelSubscriptionLoading] = useState(false);

    const [isPaymentCardDialogOpen, setIsPaymentCardDialogOpen] = useState(false);
    const [isUpdatingPaymentMethod, setIsUpdatingPaymentMethod] = useState(false);

    const user = StorageService.loadUser();
    const isAccountOwner = StorageService.loadIsAccountOwner();



    useEffect(async () => {

        if (!isAccountOwner) {
            navigate('/', {replace: true});
            return;
        }

        await getUserSubscription();
    }, [])

    const getUserSubscription = async () => {
        const response = await SubscriptionService.getUserSubscription(
            user?.token
        );

        // @ts-ignore
        const resJson = await response.json();


        const {data} = resJson;

        // @ts-ignore
        if (response.ok) {
            if (data) {


                setCurrentPlan(data.plan);
                setBillingDetails(data.billingDetails);
                setPaymentCard(data.paymentCard);
                setNextBillingDate(data.nextBillingDate);
                setSubscription(data.subscription);

                if (data.subscription.status === 'canceled') {
                    setSubscriptionIsCancelled(true);
                }

            } else {
                alert(data.message);
            }
        } else {
            alert('An error occurred, Please try again');
        }
    }

    const getSubscriptionStatusColor = (subscriptionStatus) => {
        if (subscriptionStatus === 'active') {
            return 'success'
        }

        if (subscriptionStatus === 'trialing') {
            return 'warning'
        }

        return 'error';
    }

    const getSubscriptionStatusLabel = (subscriptionStatus) => {
        if (subscriptionStatus === 'active') {
            return 'app.activeLabel'
        }

        if (subscriptionStatus === 'trialing') {
            return 'app.freeTrialLabel'
        }

        if (subscriptionStatus === 'canceled') {
            return 'app.canceledLabel'
        }

        return 'app.expiredLabel';
    }


    const cancelSubscription =  async () => {
        setIsCancelSubscriptionLoading(true);
        const response = await SubscriptionService.cancelSubscription(
            user?.token
        );

        // @ts-ignore
        const resJson = await response.json();


        setIsCancelSubscriptionLoading(false);

        const {data} = resJson;
        console.log(data);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setSubscriptionIsCancelled(true);

            } else {
                alert(data.message);
            }
        } else {
            alert('An error occurred, Please try again');
        }
    }

    const promptToCancelSubscription = () => {
        Swal.fire({
            title: t('app.cancelSubscriptionPromptTitle'),
            text: `${t('app.cancelSubscriptionPromptMessage')} ${t(currentPlan?.nameKey)} ${t(currentPlan?.billingCycleKey)}? ${t('app.currentSubscriptionEndsOn')} ${nextBillingDate}`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t('app.cancelSubscriptionLabel'),
            cancelButtonText: t('app.cancelLabel'),
            reverseButtons: true,
            confirmButtonColor: 'error',
        }).then(async (result) => {
            if (result.value) {
                await cancelSubscription();
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                // Do Nothing
            }
        });
    }


    const handlePaymentCardDialogClose = () => {
        setIsPaymentCardDialogOpen(false)
    }

    const openPaymentCardDialog = () => {
        setIsPaymentCardDialogOpen(true)
    }

    const updatePaymentCard = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        setIsUpdatingPaymentMethod(true);

        // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit();
        if (submitError) {
            alert(submitError.message);
            return;
        }

        const response = await SubscriptionService.createSetupIntent(
            user?.token
        );

        // @ts-ignore
        const resJson = await response.json();
        // alert(JSON.stringify(resJson));


        const {data, message} = resJson;

        setIsUpdatingPaymentMethod(false);

        if (response.ok) {
            if (data) {

                const setup = await stripe.confirmSetup({
                    elements, clientSecret: data?.stripeClientSecret,
                    confirmParams: {
                        return_url: `${window.location}` ,
                        //window.location : localhost://3000/subscriptions , current page
                        //window.location.origin : localhost://3000 , root page
                    }
                });

                if (setup?.error) {
                    // This point is only reached if there's an immediate error when
                    // confirming the payment. Show the error to your customer (for example, payment details incomplete)
                    alert("An error occurred: " +setup?. error.message);
                } else {
                    // Your customer is redirected to your `return_url`. For some payment
                    // methods like iDEAL, your customer is redirected to an intermediate
                    // site first to authorize the payment, then redirected to the `return_url`.
                    alert(' successful');
                }

                // const {paymentIntent, error} = await stripe.confirmCardSetup(
                //     data.stripeClientSecret,
                //     {
                //         payment_method: {
                //             card: elements.getElement(CardElement),
                //         },
                //         setup_future_usage: true
                //     },
                // );

                console.log('setup', setup);
                console.log('setup?.error', setup?.error);
            }
            else {
                alert(data.message);
            }

        }
        else {
            alert(message);
        }



    }

    return (
        <React.Fragment>
            <Box>

                <Box mb={3}>

                    <Card>
                        <CardHeader title={t('app.currentPlanLabel')}/>
                        <Divider/>
                        <CardContent>
                            <Stack direction={"row"} spacing={10}>
                                <Grid item xs={7}>
                                    {currentPlan && (
                                        <>
                                            <Typography variant={'h3'}>
                                                {t(currentPlan?.nameKey)} {t(currentPlan?.billingCycleKey)} <Chip label={t(getSubscriptionStatusLabel(subscription?.status))} color={getSubscriptionStatusColor(subscription?.status)} />
                                            </Typography>

                                            {subscriptionIsCancelled && (

                                                <Typography
                                                    variant={"h6"}
                                                    color={"text.secondary"}
                                                    mb={1}
                                                >
                                                    {t('app.currentSubscriptionEndsOn')} {nextBillingDate}
                                                </Typography>
                                            )}

                                            <Typography
                                            component={"div"}
                                            variant={"body1"}
                                            mb={1}
                                            >
                                            <Typography component={"span"} variant={"h1"}>€{currentPlan?.billingPrice}</Typography>/{currentPlan?.billingCycleAbbreviation}
                                            </Typography>
                                        </>
                                    )}

                                </Grid>
                            </Stack>
                        </CardContent>
                        <CardActions>
                            {!subscriptionIsCancelled && (
                                <>
                                <Button variant={"outlined"} onClick={() => {
                                    navigate('/plans', { state: currentPlan })
                                }}>{t('app.changePlanLabel')}</Button>

                                <LoadingButton onClick={promptToCancelSubscription} loading={isCancelSubscriptionLoading} variant={"outlined"} color={'error'}>{t('app.cancelSubscriptionLabel')}</LoadingButton>
                                </>
                            )}

                            {subscriptionIsCancelled && (


                                <LoadingButton onClick={() => {}} loading={isCancelSubscriptionLoading} variant={"outlined"} color={'primary'}>{t('app.reactivateSubscriptionLabel')}</LoadingButton>

                            )}

                        </CardActions>
                    </Card>

                </Box>

                <Box>
                    <Card>
                        <CardHeader title={t('app.billingInfoLabel')}/>
                        <Divider/>
                        <CardContent>
                            {currentPlan && (
                                <Stack direction={"row"} spacing={10}>
                                    <Grid container>
                                        <Grid item lg={6} sm={12} xs={12}>
                                            <Typography variant={'h3'} mb={3}>
                                                {t('app.paymentMethodLabel')}
                                                <Button
                                                    variant={"outlined"}
                                                    size={'small'}
                                                    sx={{marginLeft: 1}}
                                                    onClick={openPaymentCardDialog}
                                                >
                                                    {paymentCard?.brand ? t('app.updateLabel') : t('app.addLabel') }
                                                </Button>

                                            </Typography>
                                            <Typography
                                                variant={"h6"}
                                                color={"text.secondary"}
                                                mb={1}
                                            >
                                                {t('app.cardInformationLabel')}
                                            </Typography>
                                            <Typography
                                                component={"div"}
                                                variant={"body1"}
                                                mb={1}
                                            >
                                                {paymentCard?.brand?.toUpperCase()} {t('app.cardEndingInLabel')} {paymentCard?.last4}
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={6} sm={12} xs={6}>
                                            <Typography variant={'h3'} mb={3}>
                                                {t('app.billingDetailsLabel')}
                                            </Typography>

                                            <Box>
                                                <Typography
                                                    variant={"h6"}
                                                    color={"text.secondary"}
                                                    mb={1}
                                                >
                                                    {t('app.nextBillingDateLabel')}
                                                </Typography>
                                                <Typography
                                                    component={"div"}
                                                    variant={"body1"}
                                                    mb={1}
                                                >
                                                    {!subscriptionIsCancelled ? nextBillingDate : '-'}
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography
                                                    variant={"h6"}
                                                    color={"text.secondary"}
                                                    mb={1}
                                                >
                                                    {t('app.billingAddressLabel')}
                                                </Typography>
                                                <Typography
                                                    component={"div"}
                                                    variant={"body1"}
                                                    mb={1}
                                                >
                                                    {billingDetails?.line1} {billingDetails?.line2} {billingDetails?.postal_code} {billingDetails?.city}
                                                </Typography>
                                            </Box>

                                        </Grid>


                                    </Grid>
                                </Stack>

                            )}
                        </CardContent>
                    </Card>

                </Box>

            </Box>

            <Dialog fullWidth={true} maxWidth={'sm'} disableEscapeKeyDown open={isPaymentCardDialogOpen} onClose={handlePaymentCardDialogClose}>
                <DialogTitle>{t('app.updatePaymentMethodLabel')}</DialogTitle>
                <form onSubmit={updatePaymentCard}>
                <DialogContent>
                    <Box>
                            <PaymentElement />
                        {/*<CardElement/>*/}
                            <AddressElement options={{mode: "billing"}} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePaymentCardDialogClose}>{t('app.cancelButtonLabel')}</Button>
                    <LoadingButton loading={isUpdatingPaymentMethod} type={'submit'}>{t('app.updateLabel')}</LoadingButton>
                </DialogActions>
                </form>

            </Dialog>

        </React.Fragment>
    );
};

export default Subscription;