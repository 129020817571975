import React, {useCallback, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Grid,
    Select,
    TextField,
    Typography
} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import {useTranslation} from "react-i18next";
import {StorageService} from "../../services/StorageService";
import useJumboTheme from "../../../@jumbo/hooks/useJumboTheme";
import MenuItem from "@mui/material/MenuItem";
import {SUPPORTED_LOCALES} from "../../utils/locales";
import Div from "@jumbo/shared/Div";
import {AuthService} from "../../services/AuthService";
import Swal from "sweetalert2";
import {LoadingButton} from "@mui/lab";



const Profile = () => {

    const _user = StorageService.loadUser();
    const [user, setUser] = useState(_user);
    const {t, i18n} = useTranslation();


    const [languages] = useState(SUPPORTED_LOCALES);
    const [selectedLanguage, setSelectedLanguage] = useState(user?.locale);
    const [selectedLanguageObject, setSelectedLanguageObject] = useState(null);
    const [currentPassword, setCurrentPassword] =  useState('');
    const [newPassword, setNewPassword] =  useState('');
    const [confirmNewPassword, setConfirmNewPassword] =  useState('');
    const [isPasswordChangeLoading, setIsPasswordChangeLoading] = useState(false);

    const {setMuiLocale} = useJumboTheme();


    const handleLanguageChange =  useCallback(
         (e) => {
            const value = e.target.value;
            setSelectedLanguage(value);

            const localeIndex = languages.findIndex(language => language.locale === value);
            if (localeIndex !== -1) {
                i18n.changeLanguage(languages[localeIndex].locale).then(async () => {
                    setMuiLocale(languages[localeIndex].muiLocale);

                    user.locale = value;
                    setUser(user);
                    StorageService.saveUser(user);
                    StorageService.saveAppLocale(languages[localeIndex]);

                    setSelectedLanguageObject(languages[localeIndex]);

                    await updateUserLanguage(languages[localeIndex])

                });
            }
        },
        [setSelectedLanguage],
    );

    const updateUserLanguage = async (languageObject) => {
        const response =  await AuthService.updateUserLanguage(languageObject.locale,
            languageObject.language, languageObject.localizedLanguage, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;

        // @ts-ignore
        if (response.ok) {
            if (data && data.status === 'success') {
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    const changePassword = async () => {

        if (newPassword !== confirmNewPassword) {
            alert(t('app.newPasswordMisMatchErrorMessage'));
            return;
        }

        setIsPasswordChangeLoading(true);

        const response =  await AuthService.changePassword(currentPassword, newPassword, user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;

        setIsPasswordChangeLoading(false);
        // @ts-ignore
        if (response.ok) {
            if (data && data.status === 'success') {
                Swal.fire({
                    text: t('app.passwordChangedMessage'),
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: t('app.dismissButtonLabel'),
                    confirmButtonColor: 'error',
                }).then(async (result) => {
                    if (result.value) {
                    } else if (
                        result.dismiss === Swal.DismissReason.cancel
                    ) {
                    }
                });
            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    }

    return (
        <React.Fragment>
            <Box>
                <Box mb={3}>


                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item lg={3} sm={12} xs={12}>
                                    <Box>
                                        <Typography
                                            variant={"h6"}
                                            color={"text.secondary"}
                                            mb={1}
                                        >
                                            {t('app.nameLabel')}
                                        </Typography>
                                        <Typography
                                            component={"div"}
                                            variant={"body1"}
                                            mb={1}
                                        >
                                            {user?.name}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            variant={"h6"}
                                            color={"text.secondary"}
                                            mb={1}
                                        >
                                            {t('app.emailLabel')}
                                        </Typography>
                                        <Typography
                                            component={"div"}
                                            variant={"body1"}
                                            mb={1}
                                        >
                                            {user?.email}
                                        </Typography>
                                    </Box>

                                    <Box>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {t('app.languageLabel')}
                                        </Typography>
                                        <Box>
                                            <Select
                                                labelId="tone-label"
                                                value={selectedLanguage}
                                                fullWidth
                                                onChange={handleLanguageChange}
                                                label={'app.selectLanguageLabel'}
                                                sx={{ marginBottom: 2 }}
                                            >
                                                {SUPPORTED_LOCALES.map( language => (
                                                    <MenuItem value={language.locale}>{language.localizedLanguage}</MenuItem>
                                                ))}
                                            </Select>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>

                        </CardContent>


                    </Card>

                </Box>

                <Box>
                    <Card>
                        <CardHeader title={t('app.changePasswordLabel')}/>
                        <Divider/>
                        <CardContent>
                            <Stack direction={"row"} spacing={10}>
                                <Grid container>
                                    <Grid item lg={3} sm={12} xs={12}>
                                        <Div sx={{mt: 1, mb: 2}}>
                                            <TextField
                                                fullWidth
                                                name="current_password"
                                                label={t('app.currentPasswordLabel')}
                                                type="password"
                                                onChange={(e) => setCurrentPassword(e.target.value)}
                                            />
                                        </Div>

                                        <Div sx={{mt: 1, mb: 2}}>
                                            <TextField
                                                fullWidth
                                                name="new_password"
                                                label={t('app.newPasswordLabel')}
                                                type="password"
                                                onChange={(e) => setNewPassword(e.target.value)}

                                            />
                                        </Div>

                                        <Div sx={{mt: 1, mb: 2}}>
                                            <TextField
                                                fullWidth
                                                name="retype_password"
                                                label={t('app.retypePasswordLabel')}
                                                type="password"
                                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                            />
                                        </Div>
                                    </Grid>

                                </Grid>
                            </Stack>
                        </CardContent>
                        <CardActions>

                            <LoadingButton loading={isPasswordChangeLoading} onClick={changePassword} variant={"outlined"}>{t('app.changePasswordLabel')}</LoadingButton>

                        </CardActions>
                    </Card>

                </Box>

            </Box>

        </React.Fragment>
    );
};

export default Profile;
