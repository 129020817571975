import {APIService} from './APIService';

export class CreatorService extends APIService {
  static async create(
    text,
    tone,
    feature,
    locale,
    projectUid,
    businessUid,
    keyInfo,
    writingStyle,
    targetAudience,
    optimizeFor,
    textSummary,
    textIntent,
    brandVoiceUid,
    accessToken,
  ) {
    return await this.makeRequest(
      'api/create',
      {
          text,
          tone,
          feature,
          locale,
          projectUid,
          businessUid,
          keyInfo,
          writingStyle,
          targetAudience,
          optimizeFor,
          textSummary,
          textIntent,
          brandVoiceUid
      },
      {
        'x-access-token': accessToken,
        'Content-Type': 'application/json',
      },
    );
  }

    static async save(
        params
    ) {
        return await this.makeRequest(
            'api/save-copy',
            {
                ...params
            },
            {
                'x-access-token': params.accessToken,
                'Content-Type': 'application/json',
            },
        );
    }

  static async fetchContentCategories(accessToken) {
    return await this.makeRequest(
      'api/content-categories',
      {},
      {
        'x-access-token': accessToken,
        'Content-Type': 'application/json',
      },
    );
  }

    static async fetchTeamCopies(teamUid, accessToken) {
        return await this.makeRequest(
            'api/copies',
            {teamUid},
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
        );
    }

    static async editCopyContent(uid, content, accessToken) {
        return await this.makeRequest(
            'api/edit-copy',
            {uid, content},
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
        );
    }

    static async updateCopyTranslations(uid, translatedContent, translationType, translatedLocales,
                                        accessToken) {
        return await this.makeRequest(
            'api/update-copy-translations',
            {uid, translatedContent, translationType, translatedLocales},
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
        );
    }

    static async deleteCopy(uid, accessToken) {
        return await this.makeRequest(
            'api/delete-copy',
            {uid},
            {
                'x-access-token': accessToken,
                'Content-Type': 'application/json',
            },
        );
    }
}
