import React, {useState} from 'react';
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import {
    Accordion, AccordionDetails,
    Box,
    Card,
    Snackbar,
    Typography
} from "@mui/material";

import {useTranslation} from "react-i18next";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveIcon from "@mui/icons-material/Remove";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";


const SavedContentItemCard = ({content, contentList, onEdit, onDelete, canDelete}) => {

    const {t} = useTranslation();
    const Swal = useSwalWrapper();
    const [expanded, setExpanded] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [selectedSavedContent, setSelectedSavedContent] = useState(content);

    const deleteCopy = async () => {
        setIsDeleting(true);
        await onDelete();
        showSnackBarAlert('app.copyDeleted', 'success');
        setIsDeleting(false)

    };

    const promptToDeleteCopy = async () => {
        Swal.fire({
            title: t('app.deleteCopyPromptTitle'),
            text: t('app.deleteCopyPromptMessage'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t('app.deleteLabel'),
            cancelButtonText: t('app.cancelLabel'),
            reverseButtons: true,
            confirmButtonColor: 'error',
        }).then(async (result) => {
            if (result.value) {
                await deleteCopy();
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                // Do Nothing
            }
        });
    };

    const copyContent = async () => {
        if (selectedSavedContent.translatedResult) {
            await navigator.clipboard.writeText(selectedSavedContent?.result + '\n\n' +
                selectedSavedContent?.translatedResult);

        }
        else {
            await navigator.clipboard.writeText(selectedSavedContent?.result);

        }
        showSnackBarAlert('app.copiedLabel', 'success');
    }

    const onContentClick = (content) => {
        if (contentList.find((item) => item.id === content.id))
            setExpanded(!expanded);
    }


    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsSnackBarOpen(false);
    }

    const showSnackBarAlert = (messageKey, alertSeverity) => {
        setSnackBarMessage(t(messageKey));
        setAlertSeverity(alertSeverity);
        setIsSnackBarOpen(true);
    }

    return (
        <>
        <Card sx={{mb: 1}}>
            <Accordion expanded={expanded} onChange={() => {onContentClick(selectedSavedContent)}} square sx={{borderRadius: 2}}>
                <AccordionSummary
                    expandIcon={expanded ? <RemoveIcon/> : <ExpandMoreIcon/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                        px: 3,
                        flexDirection: 'row-reverse',

                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',

                            '&.Mui-expanded': {
                                margin: '12px 0',
                            }
                        },
                        '.MuiAccordionSummary-expandIconWrapper': {
                            borderRadius: 1,
                            border: 1,
                            color: 'text.secondary',
                            borderColor: 'divider',
                            transform: 'none',
                            height: 28,
                            width: 28,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,

                            '&.Mui-expanded': {
                                transform: 'none',
                                color: 'primary.main',
                                borderColor: 'primary.main',
                            },

                            '& svg': {
                                fontSize: '1.25rem',
                            }
                        }
                    }}
                >
                    <Div
                        sx={{
                            width: {xs: 'auto', lg: '26%'},
                            flexShrink: 0,
                            px: 1,
                            flex: {xs: '1', lg: '0 1 auto'},
                        }}
                    >
                        <Typography variant={"h5"} mb={.5} fontSize={14}>{selectedSavedContent.text}</Typography>
                        <Typography fontSize={"12px"} variant={"body1"} color={"text.secondary"}>
                            <CalendarTodayOutlinedIcon
                                sx={{
                                    verticalAlign: 'middle',
                                    fontSize: '1rem',
                                    mt: -.25,
                                    mr: 1
                                }}
                            />
                            {selectedSavedContent.createdAt}
                        </Typography>
                    </Div>

                    {selectedSavedContent?.brandVoice && (
                        <>
                            <Div sx={{display: {xs: 'none', lg: 'block'}, width: '16%', flexShrink: 0, px: 1}}>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={.25}
                                >
                                    {t('app.brandVoiceLabel')}
                                </Typography>
                                <Typography variant={"body1"}>{selectedSavedContent?.brandVoice?.name}</Typography>
                            </Div>

                            <Div sx={{display: {xs: 'none', lg: 'block'}, width: '16%', flexShrink: 0, px: 1}}>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={.25}
                                >
                                    {t('app.brandVoicePersonalityLabel')}
                                </Typography>
                                <Typography variant={"body1"}>{selectedSavedContent?.brandVoice?.voicePersonality}</Typography>
                            </Div>
                        </>

                    )}

                    {!selectedSavedContent?.brandVoice && (

                        <>
                            <Div sx={{display: {xs: 'none', lg: 'block'}, width: '16%', flexShrink: 0, px: 1}}>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={.25}
                                >
                                    {t('app.businessToneOfVoiceLabel')}
                                </Typography>
                                <Typography variant={"body1"}>{selectedSavedContent.tone}</Typography>
                            </Div>
                            <Div sx={{display: {xs: 'none', lg: 'block'}, width: '16%', flexShrink: 0, px: 1}}>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={.25}
                                >
                                    {t('app.businessWritingStyleLabel')}
                                </Typography>
                                <Typography variant={"body1"}>{selectedSavedContent.writingStyle}</Typography>
                            </Div>
                        </>

                    )}

                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '16%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            {t('app.createdByLabel')}
                        </Typography>
                        <Typography variant={"body1"}>{selectedSavedContent.createdByUserName}</Typography>
                    </Div>
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '16%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            {t('app.lastModifiedLabel')}
                        </Typography>
                        <Typography variant={"body1"}>{selectedSavedContent?.updatedByUserName ? selectedSavedContent?.updatedAt : '-'}</Typography>
                    </Div>
                    <Div sx={{display: {xs: 'none', lg: 'block'}, width: '16%', flexShrink: 0, px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            {t('app.modifiedByLabel')}
                        </Typography>
                        <Typography variant={"body1"}>{selectedSavedContent?.updatedByUserName || '-'}</Typography>
                    </Div>
                </AccordionSummary>
                <AccordionDetails sx={{borderTop: 1, borderColor: 'divider', p: theme => theme.spacing(2, 2, 2, 8.25)}}>
                    <Div
                        sx={{
                            display: {xs: 'flex', lg: 'none'},
                            minWidth: 0,
                            flexDirection: 'column'
                        }}
                    >
                        <Div
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mt: 1,
                                mb: 2,
                            }}
                        >

                            {selectedSavedContent?.brandVoice && (
                                <>
                                    <Div>
                                        <Typography
                                            fontSize={"12px"}
                                            variant={"h6"}
                                            color={"text.secondary"}
                                            mb={.25}
                                        >
                                            {t('app.brandVoiceLabel')}
                                        </Typography>
                                        <Typography variant={"body1"}>{selectedSavedContent?.brandVoice?.name}</Typography>
                                    </Div>

                                    <Div sx={{mb: 3, maxWidth: 280}}>
                                        <Typography
                                            fontSize={"12px"}
                                            variant={"h6"}
                                            color={"text.secondary"}
                                            mb={.25}
                                        >
                                            {t('app.brandVoicePersonalityLabel')}
                                        </Typography>
                                        <Typography variant={"body1"}>{selectedSavedContent?.brandVoice?.voicePersonality}</Typography>
                                    </Div>
                                </>




                            )}

                            {!selectedSavedContent?.brandVoice && (
                                <>
                                    <Div>
                                        <Typography
                                            fontSize={"12px"}
                                            variant={"h6"}
                                            color={"text.secondary"}
                                            mb={.25}
                                        >
                                            {t('app.businessToneOfVoiceLabel')}
                                        </Typography>
                                        <Typography variant={"body1"}>{selectedSavedContent.tone}</Typography>
                                    </Div>

                                    <Div sx={{mb: 3, maxWidth: 280}}>
                                        <Typography
                                            fontSize={"12px"}
                                            variant={"h6"}
                                            color={"text.secondary"}
                                            mb={1}
                                        >
                                            {t('app.businessWritingStyleLabel')}
                                        </Typography>
                                        <Typography variant={"body1"}>{selectedSavedContent.writingStyle}</Typography>

                                    </Div>

                                </>

                            )}






                        </Div>

                    </Div>

                    <Box sx={{marginLeft: -0.5, marginBottom: 2}}>
                        <Button
                            onClick={copyContent}
                            color={'primary'}
                            size={"small"}
                            startIcon={<ContentCopyIcon />}
                        >
                            {t("app.copyContentButtonLabel")}
                        </Button>

                        <Button
                            onClick={onEdit}
                            color={'info'}
                            size={"small"}
                            startIcon={<EditIcon />}
                        >
                            {t("app.editContentButtonLabel")}
                        </Button>
                        {canDelete && (
                            <LoadingButton
                                loading={isDeleting}
                                onClick={promptToDeleteCopy}
                                color={'error'}
                                size={"small"}
                                startIcon={<DeleteIcon />}
                            >
                                {t("app.deleteContentButtonLabel")}
                            </LoadingButton>
                        )}
                    </Box>


                    {selectedSavedContent.keyInfo && (
                        <>
                            <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={1}
                            >
                                {t('app.keyInfoLabel')}
                            </Typography>
                            <Typography variant={"body1"} color={"text.secondary"}>
                                {selectedSavedContent.keyInfo}
                            </Typography>
                        </>
                    )}

                    <Typography sx={{ whiteSpace: "pre-wrap" }}  variant={"body1"} color={"text.secondary"}>
                        {selectedSavedContent.result}
                    </Typography>
                    <br/>
                    {selectedSavedContent.translatedResult && (
                        <Typography sx={{ whiteSpace: "pre-wrap" }} variant={"body1"} color={"text.secondary"}>
                            {selectedSavedContent.translatedResult}
                        </Typography>
                    )}
                </AccordionDetails>
            </Accordion>
        </Card>

        <Snackbar anchorOrigin={{vertical: 'top', horizontal:'center' }}
                  open={isSnackBarOpen} autoHideDuration={5000} onClose={handleSnackBarClose}
        >
            <Alert severity={alertSeverity} sx={{ width: '100%' }}>
                {snackBarMessage}
            </Alert>
        </Snackbar>
        </>
    );
};

export default SavedContentItemCard;
