import React, {useEffect, useState} from 'react';
import {Box, Divider, Grid, Typography} from "@mui/material";
import CopyItemCard from "./CopyItemCard";
import {useTranslation} from "react-i18next";
import {StorageService} from "../../services/StorageService";
import {useNavigate} from "react-router-dom";
import {CreatorService} from "../../services/CreatorService";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

const Home = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [contentCategories, setContentCategories] = useState(StorageService.loadContentCategories());
    const user = StorageService.loadUser();
    const [selectedCopyCategoryLabel, setSelectedCopyCategoryLabel] = useState(contentCategories[0]?.title);
    const [selectedCopyCategory, setSelectedCopyCategory] = useState(contentCategories[0]?.categories ||
        [contentCategories[0]]);

    const handleSelectCopyCategory = (categoryLabel) => {
        setSelectedCopyCategoryLabel(categoryLabel);

        if (categoryLabel === 'All'){
            setSelectedCopyCategory(contentCategories);
            return;
        }

        const contentCategory = contentCategories.find(category => category.title === categoryLabel);
        let selectedCategory = null;
        if (contentCategory) {
            if (contentCategory.hasCategories) {
                selectedCategory = contentCategory.categories;
            }
            else {
                selectedCategory = [contentCategory];
            }
        }

        setSelectedCopyCategory(selectedCategory);
        console.log('selectedCopyCategory', selectedCopyCategory);
    };

    useEffect( async () => {
        if (!user) {
            navigate('/login', {replace: true});
        }
        if (user.status === 'unverified') {
            navigate('/welcome', {replace: true});
        }

        const response =  await CreatorService.fetchContentCategories(user.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        // @ts-ignore
        if (response.ok) {
            if (data) {
                StorageService.saveContentCategories(data.contentCategories);
                setContentCategories(data.contentCategories);

            } else {
                // alert(data.message);
            }
        } else {
            // alert(message);
        }

    }, []);


    return (
        <React.Fragment>
            <Typography variant={'h1'} mb={2}>{t("app.contentTemplatesLabel")}</Typography>
            <Typography variant={'h6'} color={'text.secondary'}>
                {t("app.contentTemplatesDescriptionLabel")}
            </Typography>

            <Divider/>

            <Stack direction="row" spacing={1} mt={5}>
                {contentCategories.map( (content, index) =>
                    (
                        <Chip label={t(content?.titleKey)} color={selectedCopyCategoryLabel === content?.title ? "primary": "default"} onClick={() => handleSelectCopyCategory(content?.title)} />
                    )

                )}
                <Chip label={t('app.allLabel')} color={selectedCopyCategoryLabel === "All" ? "primary": "default"} onClick={() => handleSelectCopyCategory("All")} />
            </Stack>

            <Grid container spacing={3.75} mt={1} mb={3}>

            {selectedCopyCategory.map( (content, index) =>
                (
                    <>
                        {content.hasCategories && content.categories.map((category, i) => (
                            <Grid item key={i} xs={12} sm={6} lg={3}>
                                <CopyItemCard copyType={category}/>
                            </Grid>
                        ))
                        }
                        {!content.hasCategories && (
                            <Grid item key={index} xs={12} sm={6} lg={3}>
                                <CopyItemCard copyType={content}/>
                            </Grid>
                        )}
                    </>
                )

            )}
            </Grid>
        </React.Fragment>
    );
};

export default Home;