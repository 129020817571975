import React, {useCallback, useEffect, useState} from "react";
import {
    Box,
    Card,
    CardContent, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider, Grid, InputLabel, ListItem, ListItemAvatar, ListItemText, Select,
    TextField,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import CampaignTemplateItemCard from "./CampaignTemplateItemCard";
import EditNoteIcon from '@mui/icons-material/EditNote';
import Chip from "@mui/material/Chip";
import {CampaignService} from "../../services/CampaignService";
import {StorageService} from "../../services/StorageService";
import {Skeleton} from "@mui/lab";
import LoadingButton from "@mui/lab/LoadingButton";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";


const NewCampaign = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const user = StorageService.loadUser();
    const team = StorageService.loadTeam();
    const plan = StorageService.loadPlan();
    const planFeatures = StorageService.loadPlanFeatures();
    const business = StorageService.loadSelectedBusiness();
    const brandVoices = StorageService.loadBrandVoices();


    const showBrandVoicesDropDown = brandVoices?.length > 0;

    const [showToneOfVoiceInput, setShowToneOfVoiceInput] = useState(!showBrandVoicesDropDown);
    const [selectedBrandVoice, setSelectedBrandVoice] = useState(brandVoices ? brandVoices[0]: null);


    const [projects, setProjects] = useState(StorageService.loadProjects());
    const [selectedProject, setSelectedProject] = useState(StorageService.loadSelectedProject());


    const [campaignTemplates, setCampaignTemplates] = useState([]);
    const [campaignContentTypes, setCampaignContentTypes] = useState([]);

    const [selectedCampaignTemplate, setSelectedCampaignTemplate] = useState();
    const [selectedCampaignContentTypeFeatures, setSelectedCampaignContentTypeFeatures] = useState([]);


    const [isNewCampaignDialogOpen, setIsNewCampaignDialogOpen] = useState(false);
    const [createCampaignStep, setCreateCampaignStep] = useState('step-1');

    const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
    const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);

    const [campaignTitle, setCampaignTitle] = useState( '');
    const [tone, setTone] = useState(business?.tone || '');
    const [writingStyle, setWritingStyle] = useState(business?.writingStyle || '');
    const [campaignDetails, setCampaignDetails] = useState( '');

    const [createdCampaignContent, setCreatedCampaignContent] = useState( []);
    let createdCampaign;
    const updatedCreatedCampaignContent = [];

    useEffect( async () => {
        await getCampaignTemplates();
    }, []);


    const handleProjectChange = useCallback(
        (e) => {
            const projectUid = e.target.value;
            const project = projects.find(proj => proj.uid === projectUid);
            setSelectedProject(project);
        },
        [setSelectedProject],
    );

    const handleFieldChange = useCallback(
        (callback, e) => {
            const value = e.target.value;
            callback(value);

        },
        [ setCampaignTitle, setTone, setWritingStyle, setCampaignDetails],
    );

    const getCampaignTemplates = async () => {
        setIsLoadingTemplates(true);

        const response =  await CampaignService.getCampaignTemplates(user?.token);
        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        setIsLoadingTemplates(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                setCampaignTemplates(data.templates);
                setCampaignContentTypes(data.campaignContentTypes);

            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    };

    const getContentTypeByFeature = (feature) => {
        for (const contentType of campaignContentTypes) {
            if (contentType.pageConfig.feature === feature) {
                return contentType;
            }
        }
    }

    const handleNewCampaignDialogClose = () => {
        setIsNewCampaignDialogOpen(false);
        setCreateCampaignStep('step-1')
        setSelectedCampaignContentTypeFeatures([]);
    }

    const openNewCampaignDialog = (campaignTemplate) => {
        setSelectedCampaignTemplate(campaignTemplate);
        setIsNewCampaignDialogOpen(true);

        if (campaignTemplate) {
            setSelectedCampaignContentTypeFeatures(campaignTemplate.suggestedFeatures);
        }
    }

    const handleBrandVoiceChange = useCallback(
        (e) => {
            const value = e.target.value;

            if(value === 'use-tone') {
                setShowToneOfVoiceInput(true);
                setSelectedBrandVoice(null);
                return
            }
            setShowToneOfVoiceInput(false);

            const brandVoice = brandVoices.find(brandVoice => brandVoice.uid === value);
            setSelectedBrandVoice(brandVoice);
        },
        [setSelectedBrandVoice],
    );

    const createCampaign = async  () => {

        if (!campaignTitle) {
            alert('Enter the campaign title');
            return;
        }

        if (!campaignDetails) {
            alert('Enter the campaign details');
            return;
        }

        let response;

        if (selectedCampaignContentTypeFeatures.length < 1) {
            // Create a campaign with no content
            response  =
                await createCampaignContent([]);
        }
        else {
            for (let i = 0; i < selectedCampaignContentTypeFeatures.length; i++) {
                response  =
                    await createCampaignContent([selectedCampaignContentTypeFeatures[i]])
            }
        }

        const {data, message} = response;
        setIsCreatingCampaign(false);

        if (data) {
            navigate(`/campaigns/${createdCampaign?.uid}`);
        } else {
            alert(message);
        }
    };


    const updateCreatedCampaignContent = (createdContent) => {
        updatedCreatedCampaignContent.push(createdContent);
        setCreatedCampaignContent([...updatedCreatedCampaignContent]);
    }

    const createCampaignContent = async (contentFeatureArray) => {
        setIsCreatingCampaign(true);

        const templateId = selectedCampaignTemplate?.id;
        const locale = business ? business.preferredContentLanguage : user.locale;
        const brandVoiceUid = selectedBrandVoice ? selectedBrandVoice?.uid : null;

        const response = await CampaignService.createCampaign(
            createdCampaign?.uid, campaignTitle, tone, writingStyle,brandVoiceUid,
            campaignDetails,
            locale, templateId, contentFeatureArray,
            team.uid,business?.uid, selectedProject.uid,
            user?.token);

        const resJson = await response.json();

        const {data, message} = resJson;
        // setIsCreatingCampaign(false);

        // @ts-ignore
        if (response.ok) {
            if (data) {
                createdCampaign = data.campaign;
                updateCreatedCampaignContent(data?.campaignContent[0]);

            } else {
                // alert(data.message);
            }
        } else {
            // alert(message);
        }
        return {data, message};
    }


    const proceedToSelectCampaignContent = () => {
        setCreateCampaignStep('step-2')

    };

    const backToCampaignDetails = () => {
        setCreateCampaignStep('step-1')

    };

    const selectCampaignContent = (campaignContentTypeFeature) => {
        const selectedContentFeatures = [...selectedCampaignContentTypeFeatures];
        if (!selectedContentFeatures.includes(campaignContentTypeFeature)) {
            selectedContentFeatures.push(campaignContentTypeFeature)
            setSelectedCampaignContentTypeFeatures(selectedContentFeatures);
        }
        else {
            const index = selectedContentFeatures.indexOf(campaignContentTypeFeature);
            selectedContentFeatures.splice(index, 1);
            setSelectedCampaignContentTypeFeatures(selectedContentFeatures);
        }
    }

    const upgradePlan = () => {
        navigate('/plans', { state: plan })
    }

    return (
        <React.Fragment>
            <Typography variant={'h1'} >
                {t("app.newCampaignTitleLabel")}
                <Button
                    variant={"outlined"}
                    sx={{marginLeft: 1}}
                    onClick={() => {openNewCampaignDialog(null)}}
                    size={'small'}
                >
                    {t('app.createCampaignButtonLabel')}
                </Button>
            </Typography>
            <Typography variant={'h6'} color={'text.secondary'}>
                {t("app.newCampaignDescriptionLabel")}
            </Typography>

            <Divider/>

            <Box mt={5}>
                <Box>
                    <Typography variant={'h3'} >
                        {t("app.campaignTemplatesLabel")}
                    </Typography>

                    {isLoadingTemplates && (
                        <>
                            <Skeleton/>
                            <Skeleton/>
                            <Skeleton/>
                            <Skeleton/>
                            <Skeleton/>
                            <Skeleton/>
                            <Skeleton/>

                        </>
                    )}

                    {!isLoadingTemplates && (
                        <Grid container spacing={3.75} mt={1} mb={3}>

                            {campaignTemplates.map( (campaignTemplate, index) =>
                                (
                                    <>
                                        <Grid item key={index} xs={12} sm={6} lg={3}>
                                            <CampaignTemplateItemCard
                                                campaignTemplate={campaignTemplate}
                                                onTemplateClick={()=> {openNewCampaignDialog(campaignTemplate)}}
                                            />
                                        </Grid>
                                    </>
                                )

                            )}
                        </Grid>
                    )}

                </Box>

            </Box>


            <Dialog fullWidth={true} maxWidth={'md'} disableEscapeKeyDown open={isNewCampaignDialogOpen} onClose={handleNewCampaignDialogClose}>
                <DialogTitle>
                    {selectedCampaignTemplate ? t(selectedCampaignTemplate.titleKey) : t('app.newCampaignLabel')}
                    {createCampaignStep === 'step-2' && (

                    <Box>
                        <Typography mt={2} variant={'h6'} color={'text.secondary'}>
                            {t('app.selectCampaignContentButtonLabel')}
                        </Typography>


                        {selectedCampaignTemplate && (
                            <Typography variant={'subtitle1'} sx={{fontSize:12, fontWeight:'bold'}} color={'primary'}>
                                {t('app.suggestedLabel').toUpperCase()}
                            </Typography>
                        )}

                        {selectedCampaignContentTypeFeatures.map((feature, index) => (
                            <Chip
                                sx={{marginTop:1}}
                                label={t(getContentTypeByFeature(feature)?.titleKey)}
                                variant="outlined"
                                onDelete={() => {selectCampaignContent(feature)}}
                            />
                        ))}

                        <Divider sx={{marginTop:3}}/>
                    </Box>
                    )}
                </DialogTitle>
                <DialogContent>
                    <Box>
                        {createCampaignStep === 'step-1' && (
                            <Box>

                                <TextField
                                    label={t('app.campaignTitleLabel')}
                                    fullWidth
                                    value={campaignTitle}
                                    onChange={(e) =>
                                    {handleFieldChange(setCampaignTitle, e)}}
                                    sx={{ marginBottom: 2 }}
                                />
                                {showBrandVoicesDropDown && (
                                    <>
                                        <InputLabel id="tone-label">{t('app.selectBrandVoiceLabel')}</InputLabel>
                                        <Select
                                            labelId="tone-label"
                                            value={selectedBrandVoice?.uid || 'use-tone'}
                                            onChange={handleBrandVoiceChange}
                                            fullWidth
                                            label={t('app.selectBrandVoiceLabel')}
                                            sx={{ marginBottom: 2 }}
                                        >
                                            {brandVoices.map( brandVoice => (
                                                <MenuItem value={brandVoice.uid}>{brandVoice.name}</MenuItem>
                                            ))}
                                            <MenuItem value={'use-tone'}>{t('app.useToneInsteadLabel')}</MenuItem>

                                        </Select>
                                    </>
                                )}

                                {(!showBrandVoicesDropDown || showToneOfVoiceInput) && (
                                    <>
                                        <TextField
                                            label={t('app.businessToneOfVoiceLabel')}
                                            value={tone}
                                            fullWidth
                                            onChange={(e) =>
                                            {handleFieldChange(setTone, e)}}
                                            sx={{ marginBottom: 2 }}
                                        />

                                        <TextField
                                            label={t('app.businessWritingStyleLabel')}
                                            value={writingStyle}
                                            fullWidth
                                            onChange={(e) =>
                                            {handleFieldChange(setWritingStyle, e)}}
                                            sx={{ marginBottom: 2 }}
                                        />
                                    </>
                                )}

                                <InputLabel id="tone-label">{t('app.selectProjectLabel')}</InputLabel>
                                <Select
                                    labelId="tone-label"
                                    value={selectedProject.uid}
                                    onChange={handleProjectChange}
                                    fullWidth
                                    label={t('app.selectProjectLabel')}
                                    sx={{ marginBottom: 2 }}
                                >
                                    {projects.map( project => (
                                        <MenuItem value={project.uid}>{project.name}</MenuItem>
                                    ))}
                                </Select>



                                <TextField
                                    label={t('app.campaignDetailsLabel')}
                                    value={campaignDetails}
                                    multiline
                                    minRows={5}
                                    fullWidth
                                    onChange={(e) =>
                                    {handleFieldChange(setCampaignDetails, e)}}
                                />
                                <Typography color={'text.secondary'} variant={'body1'}>
                                    {t('app.campaignDetailsInfoLabel')}
                                </Typography>


                            </Box>

                        )}

                        {createCampaignStep === 'step-2' && (
                            <Box>

                                {isCreatingCampaign && (
                                    <>
                                        <List mb={1}>
                                            {createdCampaignContent.map( (content) => (
                                                    <ListItem
                                                        key={content?.feature}
                                                        secondaryAction={
                                                            <Chip label={t('app.createdLabel')} color={"success"} size={"small"}/>
                                                        }
                                                    >
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <EditNoteIcon/>
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={content?.text}
                                                        />
                                                    </ListItem>
                                            ))}
                                        </List>

                                        <Skeleton/>
                                        <Skeleton/>
                                        <Skeleton/>
                                        <Skeleton/>
                                        <Skeleton/>
                                        <Skeleton/>
                                        <Skeleton/>
                                    </>
                                )}

                                {!isCreatingCampaign && (
                                    <Grid container spacing={3.75} mt={1} mb={3}>

                                        {campaignContentTypes.map( (campaignContentType, index) =>
                                            (
                                                <>
                                                    <Grid item key={index} xs={4} sm={4} lg={4}>
                                                        <Card variant={'outlined'}>
                                                            <CardContent sx={{height: 170}}>
                                                                <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                                                    <Checkbox
                                                                        label={campaignContentType.title}
                                                                        checked={selectedCampaignContentTypeFeatures.includes(campaignContentType.pageConfig.feature)}
                                                                        onChange={() => {selectCampaignContent(campaignContentType.pageConfig.feature)}}
                                                                    />
                                                                </Box>

                                                                <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                                                    <EditNoteIcon sx={{fontSize: 32}} />
                                                                </Box>
                                                                <Box sx={{ justifyContent: 'center',  display: 'flex'  }}>
                                                                    {t(campaignContentType.titleKey)}
                                                                </Box>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                </>
                                            )

                                        )}
                                    </Grid>

                                )}

                            </Box>
                        )}

                    </Box>
                </DialogContent>
                <DialogActions>
                    {createCampaignStep === 'step-1' && (
                        <>
                            <Button onClick={handleNewCampaignDialogClose}>{t('app.cancelButtonLabel')}</Button>
                            <Button onClick={proceedToSelectCampaignContent}>{t('app.createCampaignButtonLabel')}</Button>
                        </>
                    )}

                    {createCampaignStep === 'step-2' && (
                        <>
                            <Button onClick={backToCampaignDetails}>{t('app.backButtonLabel')}</Button>
                            {!planFeatures?.canAccessCampaigns && (
                                <Button color={'error'} onClick={upgradePlan}>{t('app.upgradePlanToCreateCampaignsLabel')}</Button>

                            )}
                            {planFeatures?.canAccessCampaigns && (
                                <LoadingButton loading={isCreatingCampaign} onClick={createCampaign}>{t('app.createCampaignButtonLabel')}</LoadingButton>
                            )}
                        </>
                    )}


                </DialogActions>
            </Dialog>
        </React.Fragment>
    )

};

export default NewCampaign;