import React from "react";
import Home from "../pages/home";
import Page from "@jumbo/shared/Page";
import authRoutes from "./authRoutes";
import dashboardRoutes from "./dashboardRoutes";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
    // ...authRoutes

];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    ...dashboardRoutes
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    ...authRoutes
];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export {routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly};