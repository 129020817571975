import React from 'react';
import Button from "@mui/material/Button";
import { Card, CardActions, CardContent, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

const CampaignTemplateItemCard = ({campaignTemplate, onTemplateClick}) => {
    const {t} = useTranslation();

    return (
            <Card>
                <CardContent sx={{minHeight: 150}}>
                    <Typography variant="h5" component="div">
                        {t(campaignTemplate.titleKey)}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {t(campaignTemplate.descriptionKey)}
                    </Typography>
                    <Typography sx={{ fontSize: 12, marginTop:2 }} gutterBottom>
                        <Typography variant={'p'} color={'primary'}>{t('app.suggestedCampaignContentLabel')}:</Typography> {t(campaignTemplate.suggestedContentKey)}
                    </Typography>
                </CardContent>
                <CardActions sx={{marginTop:5}}>
                    <Button sx={{marginLeft: -1}} size={"small"} onClick={onTemplateClick}>{t('app.createButtonLabel')}</Button>
                </CardActions>
            </Card>
    );
};

export default CampaignTemplateItemCard;
