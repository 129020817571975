import React, {useCallback, useState, useEffect} from "react";
import Div from "@jumbo/shared/Div";
import {Box, Card, CardContent, Checkbox, FormControlLabel, Select, Typography} from "@mui/material";
import {SUPPORTED_LOCALES} from "../../utils/locales";
import MenuItem from "@mui/material/MenuItem";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {useNavigate, useParams} from "react-router-dom";
import useJumboTheme from "../../../@jumbo/hooks/useJumboTheme";
import {StorageService} from "../../services/StorageService";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {TeamService} from "../../services/TeamService";
import Swal from "sweetalert2";
import Link from "@mui/material/Link";

const AcceptInvite = () => {

    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const { inviteUid, inviteLocale } = useParams();
    const [agreeToTerms, setAgreeToTerms] = useState(false);

    const defaultLanguage = SUPPORTED_LOCALES[1]

    const validationSchema = yup.object({
        inviteCode: yup
            .string('Enter your invitation code')
            .required('Invitation code is required'),
        name: yup
            .string('Enter your name')
            .required('Name is required'),
        email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
        password: yup
            .string('Enter your password')
            .min(6, 'Password must be at least six characters')
            .required('Password is required'),
    });


    const [languages, setLanguages] = useState(SUPPORTED_LOCALES);
    const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage.locale);
    const [selectedLanguageObject, setSelectedLanguageObject] = useState(defaultLanguage);
    const {setMuiLocale} = useJumboTheme();
    const [activeLocale, setActiveLocale] = React.useState(languages[0]);


    useEffect(() => {

        if (!inviteUid) {
            alert('invalid invite link')
        }

        changeLanguage(inviteLocale || selectedLanguage);

    }, []);


    const handleLanguageChange = useCallback(
        (e) => {
            const value = e.target.value;
            changeLanguage(value);
        },
        [setSelectedLanguage],
    );

    const changeLanguage = (value) => {
        setSelectedLanguage(value);

        const localeIndex = languages.findIndex(language => language.locale === value);
        if (localeIndex !== -1) {
            i18n.changeLanguage(languages[localeIndex].locale).then(() => {
                setActiveLocale(languages[localeIndex]);
                setMuiLocale(languages[localeIndex].muiLocale);

                StorageService.saveAppLocale(languages[localeIndex]);

                setSelectedLanguageObject(languages[localeIndex]);
            });
        }
    }

    const onAcceptInvite = async (inviteCode, name, email, password) => {

        const response = await TeamService.acceptInvite(inviteUid, inviteCode, name, email, password, selectedLanguageObject.locale,
            selectedLanguageObject.language, selectedLanguageObject.localizedLanguage);

        // @ts-ignore
        const resJson = await response.json();

        const {data, message} = resJson;
        // @ts-ignore
        if (response.ok) {
            if (data) {

                Swal.fire({
                    text: t('app.inviteSuccessfullyAcceptedMessage'),
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: t('app.signInButtonLabel'),
                    confirmButtonColor: 'error',
                }).then(async (result) => {
                    if (result.value) {
                        navigate('/login', {replace: true});
                    } else if (
                        result.dismiss === Swal.DismissReason.cancel
                    ) {
                        navigate('/login', {replace: true});
                    }
                });


            } else {
                alert(data.message);
            }
        } else {
            alert(message);
        }
    };

    return (
        <>
            <Div sx={{
                flex: 1,
                flexWrap: 'wrap',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: theme => theme.spacing(4),
            }}>
                <Box sx={{
                    width: 720,
                    maxWidth: '100%',
                    margin: 'auto',
                    marginBottom: '20px',
                    p: 4}}>
                    <Card>
                        <CardContent>
                            <Typography variant="h3">
                                {t('app.welcomeMessage')} {t('app.name')}

                            </Typography>
                            <Typography variant={'body1'} gutterBottom>
                                {t('app.acceptInviteToCreateAccountMessage')}
                            </Typography>

                            <Box mt={5}>

                                <Box>
                                    <Formik
                                        validateOnChange={true}
                                        initialValues={{
                                            inviteCode: '',
                                            name: '',
                                            email: '',
                                            password: '',
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={(data, {setSubmitting}) => {
                                            setSubmitting(true);
                                            onAcceptInvite(data.inviteCode, data.name, data.email, data.password);
                                            setSubmitting(false);
                                        }}
                                    >
                                        {({isSubmitting}) => (
                                            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>

                                                <Div sx={{mt: 1, mb: 3}}>
                                                    <JumboTextField
                                                        fullWidth
                                                        name="inviteCode"
                                                        label={t('app.invitationCodeLabel')}
                                                        type="text"
                                                    />
                                                </Div>

                                                <Div sx={{mt: 1, mb: 3}}>
                                                    <JumboTextField
                                                        fullWidth
                                                        name="name"
                                                        label={t('app.nameLabel')}
                                                        type="text"
                                                    />
                                                </Div>

                                                <Div sx={{mt: 1, mb: 3}}>
                                                    <JumboTextField
                                                        fullWidth
                                                        name="email"
                                                        label={t('app.emailLabel')}
                                                        type="email"
                                                    />
                                                </Div>
                                                <Div sx={{mt: 1, mb: 2}}>
                                                    <JumboTextField
                                                        fullWidth
                                                        name="password"
                                                        label={t('app.passwordLabel')}
                                                        type="password"
                                                    />
                                                </Div>


                                                <Box sx={{minHeight: 300, height: 'auto'}}>
                                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                        {t('app.selectYourLanguageLabel')}
                                                    </Typography>
                                                    <Box>
                                                        <Select
                                                            labelId="tone-label"
                                                            name="locale"
                                                            value={selectedLanguage}
                                                            onChange={handleLanguageChange}
                                                            fullWidth
                                                            label={'app.selectLanguageLabel'}
                                                            sx={{ marginBottom: 2 }}
                                                        >
                                                            {SUPPORTED_LOCALES.map( language => (
                                                                <MenuItem value={language.locale}>{language.localizedLanguage}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </Box>
                                                </Box>

                                                <Box mb={2}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={agreeToTerms}
                                                                onChange={() => setAgreeToTerms(!agreeToTerms)}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label={<Link
                                                            href={"#"}
                                                            color={"inherit"}
                                                            target={'_blank'}
                                                            underline={'none'}
                                                        >
                                                            {t('app.byAcceptingInviteAgreeToTermsMessage')}
                                                        </Link>}
                                                    />
                                                </Box>


                                                <LoadingButton
                                                    fullWidth
                                                    type="submit"
                                                    variant="contained"
                                                    size="large"
                                                    sx={{mb: 3}}
                                                    loading={isSubmitting}
                                                    disabled={!agreeToTerms}
                                                >
                                                    {t('app.acceptInviteButtonLabel')}
                                                </LoadingButton>
                                            </Form>
                                        )}
                                    </Formik>
                                </Box>

                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Div>


        </>

    );

}

export default AcceptInvite;